import { useEffect, FC } from 'react'
import { Box, ChakraProvider, useMediaQuery } from '@chakra-ui/react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import withContext from './context'
import Header from 'components/header/Header'
import IndexPage from 'pages/IndexPage'
import DashboardPage from 'pages/DashboardPage'
import ProjectsListPage from 'pages/ProjectsListPage'
import DraftPage from 'pages/DraftPage'
import CollectionDraftPage from 'pages/CollectionDraftPage'
import ActiveProjectPage from 'pages/ActiveProjectPage'
import AddNewPage from 'pages/AddNewPage'
import NotfoundPage from 'pages/NotfoundPage'
import MaintainPage from 'pages/MaintainPage'
import FaucetPage from 'pages/FaucetPage'
import CollectionPage from 'pages/CollectionPage'
import NewCollectionPage from 'pages/NewCollectionPage'
import { theme } from './theme'
import Mobile from 'components/Mobile'

const App: FC<unknown> = () => {
  useEffect(() => {
    const listener = (e: MessageEvent) => {
      if (e.data.type === 'FCL::BLOCTO::INTERNAL') {
        localStorage.setItem('ACCESS_TOKEN', e.data.accessToken)
      }
    }

    window.addEventListener('message', listener)
    return () => window.removeEventListener('message', listener)
  }, [])
  const [isDesktop] = useMediaQuery(`(min-width: 1024px)`)

  return (
    <ChakraProvider theme={theme}>
      <Router>
        <Header />
        {isDesktop ? (
          <Box pt={Header.HEIGHT}>
            <Routes>
              <Route path="/" element={<IndexPage />} />
              <Route path="/maintain" element={<MaintainPage />} />
              <Route path="/dashboard" element={<DashboardPage />} />
              <Route path="/new" element={<AddNewPage />} />
              <Route path="/projects" element={<ProjectsListPage />} />
              <Route path="/faucet" element={<FaucetPage />} />
              <Route path="/draft/:id" element={<DraftPage />} />
              <Route path="/dapp/:id" element={<ActiveProjectPage />} />
              <Route path="/collection" element={<CollectionPage />} />
              <Route path="/collection/new" element={<NewCollectionPage />} />
              <Route
                path="/collection/draft/:id"
                element={<CollectionDraftPage />}
              />
              <Route path="*" element={<NotfoundPage />} />
            </Routes>
          </Box>
        ) : (
          <Mobile />
        )}
      </Router>
    </ChakraProvider>
  )
}
export default withContext(App)
