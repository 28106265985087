import { Menu, MenuButton, MenuItem, MenuList, Box } from '@chakra-ui/react'
import DropdownButton from 'components/common/DropdownButton'

const MenuSelector = ({
  list,
  name,
  value,
  onChange,
  isReadOnly,
  minWidth = '142px',
}: {
  list: string[]
  name: string
  value: string
  onChange: (name: string, value: string) => void
  isReadOnly?: boolean
  minWidth?: string
}) => {
  return isReadOnly ? (
    <Box
      minWidth={minWidth}
      backgroundColor="gray05"
      borderRadius="8px"
      display="inline-block"
      p="14px 20px"
    >
      {value || '-'}
    </Box>
  ) : (
    <Menu>
      <MenuButton
        as={DropdownButton}
        variant="input"
        minWidth={minWidth}
        display="inline-flex"
      >
        {value || '-'}
      </MenuButton>
      <MenuList minWidth={minWidth} zIndex="banner">
        {list.map((item) => (
          <MenuItem key={item} onClick={() => onChange(name, item)}>
            {item}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  )
}

export default MenuSelector
