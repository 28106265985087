import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  FormHelperText,
  FormControlProps,
  FormErrorMessage,
} from '@chakra-ui/react'
import { ReactComponent as TextErrorIcon } from 'assets/icons/text_error.svg'
import { ReactComponent as CollapseIcon } from 'assets/icons/Collapse.svg'

export const FormSecTitle = ({
  isOpen,
  children,
  onClick,
}: {
  isOpen: boolean
  children: React.ReactNode
  onClick: () => void
}) => (
  <Flex
    backgroundColor="white"
    fontWeight="600"
    fontSize="20px"
    mt="20px"
    p="20px 30px"
    borderRadius={isOpen ? '12px 12px 0px 0px' : '12px'}
    cursor="pointer"
    justifyContent="space-between"
    alignItems="center"
    onClick={onClick}
  >
    {children}
    <CollapseIcon style={{ transform: isOpen ? '' : 'rotate(-90deg)' }} />
  </Flex>
)

interface FormFieldProps extends FormControlProps {
  title?: string
  titleHint?: React.ReactNode
  helperText?: string
  isTouched?: boolean
  errorMessage?: string
  isRequired?: boolean
  children: React.ReactNode
}
export const FormField = ({
  title,
  helperText,
  isTouched,
  errorMessage,
  isRequired,
  titleHint,
  children,
  ...props
}: FormFieldProps) => (
  <FormControl isInvalid={!!errorMessage && !!isTouched} mb="30px" {...props}>
    <FormLabel display="flex" alignItems="center">
      {title}
      {isRequired && (
        <Box as="span" color="#FF5555" mx="3px">
          *
        </Box>
      )}
      {titleHint}
    </FormLabel>
    <FormHelperText>{helperText}</FormHelperText>
    {children}
    <FormErrorMessage color="#FF5555" fontWeight="500">
      <Box as="span" mx="9px">
        <TextErrorIcon />
      </Box>
      {errorMessage}
    </FormErrorMessage>
  </FormControl>
)
