import { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Collapse,
  FormControlProps,
  useToast,
} from '@chakra-ui/react'
import { useFormik } from 'formik'
import dayjs from 'plugins/dayjs'
import * as yup from 'yup'
import { ReactComponent as TextErrorIcon } from 'assets/icons/text_error.svg'
import { ReactComponent as TextSuccessIcon } from 'assets/icons/Check.svg'
import { ReactComponent as AlertIcon } from 'assets/icons/Alert.svg'
import { getFaucet } from 'api/endpoints/faucet'

enum ERROR_CODE {
  EMAIL_NOT_EXIST = 'email_not_exist',
  UNEXPECTED_ERROR = 'unexpected_error',
  INVALID_REQUEST_BODY = 'invalid_request_body',
}

const COUNT_FORMAT_MAP: { [key: number]: string } = {
  1: 'onece',
  2: 'twice',
  3: 'three',
}
const ERROR_CODE_MAP: { [key: string]: string } = {
  [ERROR_CODE.EMAIL_NOT_EXIST]: "The account doesn't exist.",
  [ERROR_CODE.UNEXPECTED_ERROR]: 'unexpected_error',
  [ERROR_CODE.INVALID_REQUEST_BODY]: 'invalid_request_body',
}

const validationSchema = yup.object({
  email: yup
    .string()
    .email('Invalid email')
    .required('This field is required.'),
})

const FormSecTitle = ({
  isOpen,
  children,
  onClick,
}: {
  isOpen: boolean
  children: React.ReactNode
  onClick?: () => void
}) => (
  <Flex
    backgroundColor="white"
    fontWeight="600"
    fontSize="20px"
    mt="20px"
    p="20px 30px"
    borderRadius={isOpen ? '12px 12px 0px 0px' : '12px'}
    justifyContent="space-between"
    alignItems="center"
    onClick={onClick}
  >
    {children}
  </Flex>
)

interface FormFieldProps extends FormControlProps {
  title?: string
  titleHint?: React.ReactNode
  helperText?: string
  isTouched?: boolean
  errorMessage?: string
  isRequired?: boolean
  children: React.ReactNode
}
const FormField = ({
  title,
  helperText,
  isTouched,
  errorMessage,
  isRequired,
  titleHint,
  children,
  ...props
}: FormFieldProps) => (
  <FormControl isInvalid={!!errorMessage && !!isTouched} {...props}>
    <FormLabel display="flex" alignItems="center">
      {title}
      {isRequired && (
        <Box as="span" color="#FF5555" mx="3px">
          *
        </Box>
      )}
      {titleHint}
    </FormLabel>
    <FormHelperText>{helperText}</FormHelperText>
    {children}
    <FormErrorMessage color="#FF5555" fontWeight="500">
      <Box as="span" mx="9px">
        <TextErrorIcon />
      </Box>
      {errorMessage}
    </FormErrorMessage>
  </FormControl>
)

export const FaucetForm = ({ envTitle }: { envTitle: string }) => {
  const [successSubmit, setSuccessSubmit] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [count, setCount] = useState<string>('')
  const [retryAfterMsg, setRetryAfterMsg] = useState('')
  const toast = useToast()

  const submitForm = async (form: faucetParams, setErrors: any) => {
    try {
      setIsLoading(true)
      const result: faucetResponse = await getFaucet(form)
      if (result.retryAfter) {
        const midnightMinute = dayjs(result.retryAfter).diff(dayjs(), 'minute')
        const midnightHour = midnightMinute / 60
        const midnightHourToFixed =
          Math.floor(midnightHour) === midnightHour
            ? midnightHour
            : Math.floor(midnightHour * 10) / 10
        setRetryAfterMsg(
          `You have reached the limit of receiving today. Next available time: In ${midnightHourToFixed} hours`
        )
        setSuccessSubmit(false)
        return
      }
      // has error message from backend api
      if (result.error_code) {
        setErrors({ email: ERROR_CODE_MAP[result.error_code] })
        setRetryAfterMsg('')
        setSuccessSubmit(false)
        return
      }
      if (result.faucet_count) {
        setCount(COUNT_FORMAT_MAP[result.faucet_count])
        setRetryAfterMsg('')
        setSuccessSubmit(true)
        return
      }
      toast({
        title: `Error: ${result}`,
        status: 'error',
        duration: 2000,
      })
    } catch (error) {
      console.error('error: ', error)
      setRetryAfterMsg('')
      setSuccessSubmit(false)
      toast({
        title: `Error: ${error}`,
        status: 'error',
        duration: 2000,
      })
    } finally {
      setIsLoading(false)
    }
  }

  const formik = useFormik({
    initialValues: {
      email: '',
      amount: 10000,
    },
    validationSchema,
    onSubmit: async (form, { setErrors }) => {
      submitForm(form, setErrors)
    },
  })

  useEffect(() => {
    const el = document.querySelector('[aria-invalid="true"]')
    ;(el?.parentElement ?? el)?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    })
  }, [formik.isSubmitting])

  useEffect(() => {
    window.onbeforeunload = (e) => {
      const dialogText = 'Sure you want to leave?'
      e.returnValue = dialogText
      return dialogText
    }
    return () => {
      window.onbeforeunload = null
    }
  }, [])

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormSecTitle isOpen={true}>
        <Flex alignItems="center">Blocto points application</Flex>
      </FormSecTitle>
      <Collapse in={true}>
        <Box
          backgroundColor="white"
          p="30px 40px"
          borderRadius="0px 0px 12px 12px"
          borderTop="1px solid"
          borderColor="border.secondary"
        >
          <FormField
            title={`${envTitle} email`}
            helperText={`Please fill out your ${envTitle.toLowerCase()} email for obtaining Blocto points. You can claim up to 3 times of 10,000 Blocto points per day.`}
            isTouched={formik.touched.email}
            errorMessage={formik.errors.email}
          >
            <Input
              name="email"
              type="text"
              placeholder={`${envTitle} email`}
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </FormField>
          {retryAfterMsg && (
            <Flex alignItems="center" justify="flex-start" mt="10px">
              <AlertIcon />
              <Box ml="10px" color="#0075FF" fontSize="14px" fontWeight="400">
                {retryAfterMsg}
              </Box>
            </Flex>
          )}
          {successSubmit && (
            <Flex alignItems="center" justify="flex-start" mt="10px">
              <TextSuccessIcon />
              <Box ml="10px" color="#0075FF" fontSize="14px" fontWeight="400">
                You have received {count} times today.
              </Box>
            </Flex>
          )}
          <Flex justify="left" mt="10px" mb="32px">
            <Button type="submit" minWidth="89px" isLoading={isLoading}>
              Submit
            </Button>
          </Flex>
          <FormField title="Blocto points">
            <Input
              name="points"
              type="text"
              isReadOnly={true}
              value={successSubmit ? formik.values.amount : ''}
            />
          </FormField>
        </Box>
      </Collapse>
    </form>
  )
}

export default FaucetForm
