import { useState } from 'react'
import { Flex, Button } from '@chakra-ui/react'
import Modal from 'components/Modal'
import { ProjectForm, ProjectFormType } from 'components/ProjectForm'
import { uploadProject } from 'api/endpoints/project'
import { deleteDraft } from 'utils'
import { ReactComponent as WarnIcon } from 'assets/icons/Modal_warn.svg'
import { ReactComponent as SuccessIcon } from 'assets/icons/Modal_success.svg'

enum SubmitModalType {
  preview,
  submitting,
  success,
  error,
}

const SubmitModal = ({
  isOpen,
  formik,
  onClose,
}: {
  isOpen: boolean
  onClose: () => void
  formik: any
}) => {
  const [viewMode, setViewMode] = useState<SubmitModalType>(
    SubmitModalType.preview
  )
  const [id, setId] = useState<string>('')

  const icon = {
    [SubmitModalType.preview]: null,
    [SubmitModalType.submitting]: null,
    [SubmitModalType.success]: <SuccessIcon />,
    [SubmitModalType.error]: <WarnIcon />,
  }

  const heading = {
    [SubmitModalType.preview]: null,
    [SubmitModalType.submitting]: 'Loading...',
    [SubmitModalType.success]: 'Successful',
    [SubmitModalType.error]: 'Oops',
  }

  const detail = {
    [SubmitModalType.preview]: (
      <>
        <ProjectForm formik={formik} type={ProjectFormType.readonly} />
        <Flex justify="center" mt="30px">
          <Button onClick={onClose} mr="30px" variant="secondary">
            Cancel
          </Button>
          <Button
            minWidth="132px"
            onClick={() => {
              setViewMode(SubmitModalType.submitting)
              uploadProject(formik.values)
                .then((res: any) => {
                  formik.setSubmitting(false)
                  if (res?.id) {
                    deleteDraft(formik.values.uuid)
                    setViewMode(SubmitModalType.success)
                    formik.setSubmitting(false)
                    setId(res.id)
                  }
                })
                .catch(() => {
                  setViewMode(SubmitModalType.error)
                  formik.setSubmitting(false)
                })
            }}
          >
            Submit
          </Button>
        </Flex>
      </>
    ),
    [SubmitModalType.submitting]: 'It may take some time.',
    [SubmitModalType.success]: 'Check your project & app id now!',
    [SubmitModalType.error]: 'An error occurred. Please try again.',
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        formik.setSubmitting(false)
        onClose()
      }}
      closingButton={viewMode !== SubmitModalType.preview}
      isLoading={viewMode === SubmitModalType.submitting}
      heading={heading[viewMode]}
      icon={icon[viewMode]}
      detail={detail[viewMode]}
      isForm={viewMode === SubmitModalType.preview}
      button={
        viewMode === SubmitModalType.success && (
          <Button
            width="100%"
            backgroundColor="#365BEA"
            borderRadius="12px"
            onClick={() => {
              window.location.href = `/dapp/${id}`
            }}
          >
            OK
          </Button>
        )
      }
      closeOnOverlayClick={viewMode !== SubmitModalType.preview}
    />
  )
}

export default SubmitModal
