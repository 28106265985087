import { FC, useState } from 'react'
import { useFormik } from 'formik'
import { Box, IconButton } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { Container } from 'components/Sidebar'
import {
  CollectionForm,
  CollectionFormType,
  initialFormValue,
  validationSchema,
  validateGroup,
} from 'components/CollectionForm'
import PageTitle from 'components/PageTitle'
import { useParams } from 'react-router-dom'
import DeleteDraftModal from 'components/DeleteDraftModal'
import StepButtonGroup from 'components/StepButtonGroup'
import useStatusModal from 'hooks/useStatusModal'
import { useDebounceEffect } from 'hooks/useDebounceEffect'
import { getCollectionById, submitCollection } from 'api/endpoints/collection'
import { deleteCollection } from 'api/endpoints/collection'
import { convertImageToBase64 } from 'utils'
import { ReactComponent as EditIcon } from 'assets/icons/Edit_NoBg.svg'
import { ReactComponent as DeleteIcon } from 'assets/icons/Delete_NoBg.svg'
import { ReactComponent as FlowIcon } from 'assets/icons/Flow.svg'

const DraftPage: FC<unknown> = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const [editMode, setEditMode] = useState(urlParams.get('type') === 'edit')
  const [step, setStep] = useState<number>(0)
  const [modalData, setModalData] = useState({
    isOpen: false,
    deleteName: '',
    deleteId: '',
  })
  const { id } = useParams()
  const navigate = useNavigate()
  const [openModal, ModalComponent, closeModal] = useStatusModal()
  const [hideDelete, setHideDelete] = useState<boolean>(false)
  const submitFnc = async () => {
    try {
      openModal('loading')
      const payload = {
        app_listing_date: formik.values.app_listing_date,
        banner_image: formik.values.banner_image,
        blocto_bay_listing_date: formik.values.blocto_bay_listing_date,
        blocto_bay_royalty_ratio: formik.values.blocto_bay_royalty_ratio,
        blocto_bay_royalty_receiver: formik.values.blocto_bay_royalty_receiver,
        description: formik.values.description,
        name: formik.values.name,
        social_links: formik.values.social_links,
        squared_logo: formik.values.squared_logo,
        store_banner: formik.values.store_banner,
        store_description: formik.values.store_description,
      }
      const result = await submitCollection(id as string, payload)
      closeModal()
      if (result) {
        openModal('success', 'You can check your NFT collection state.', () =>
          navigate('/collection')
        )
      }
    } catch (e) {
      openModal('error', String(e))
    }
  }

  const formik = useFormik({
    initialValues: initialFormValue as any,
    validationSchema: validationSchema,
    onSubmit: () => {
      submitFnc()
    },
  })

  const convertImagesToBase64 = async (result: any) => {
    const imageFields = [
      { field: 'squared_logo', base64Field: 'base64squared_logo' },
      { field: 'banner_image', base64Field: 'base64banner_image' },
      { field: 'store_banner', base64Field: 'base64store_banner' },
    ]

    const imagePromises = imageFields.map(async ({ field, base64Field }) => {
      if (result[field]) {
        const base64Image = await convertImageToBase64(result[field])
        result[base64Field] = base64Image
      }
    })

    await Promise.all(imagePromises)
  }
  const getCollectionList = async () => {
    if (!id) return
    try {
      openModal('loading')
      const result = await getCollectionById(id)
      if (result) {
        if (result.status === 'listing') {
          setHideDelete(true)
        }
        const storageDrafts = JSON.parse(
          localStorage.getItem('collection_contract_drafts') || '{}'
        )
        const draft = storageDrafts[id]
        if (draft) {
          result.contracts = [...result.contracts, ...draft]
        }
        await convertImagesToBase64(result)
        formik.setValues(result)
      }
    } catch (error) {
      console.error('error', error)
    } finally {
      closeModal()
    }
  }
  useDebounceEffect(
    async () => {
      await getCollectionList()
    },
    100,
    [id]
  )

  const deleteDraft = async () => {
    try {
      openModal('loading')
      const { success } = await deleteCollection(modalData.deleteId)
      if (success) {
        openModal('success', 'You can check your NFT collection state.', () =>
          navigate('/collection')
        )
      }
    } catch (error) {
      openModal('error', String(error))
    }
  }
  const checkFormikError = (): number => {
    let currentIndex = 1
    for (let i = 0; i < 3; i++) {
      const currentStep = i + 1
      const fields = validateGroup[currentStep]
      const hasEmptyField = fields.some((field) => !formik.values[field])
      if (hasEmptyField) {
        break
      }
      currentIndex = currentStep + 1
    }
    return currentIndex
  }

  return (
    <Container>
      <PageTitle
        title={formik.values.name}
        subtitle="Your NFT project / collection will be listed on both Blocto app and BloctoBay (NFT marketplace)."
        icon={<FlowIcon />}
        buttons={
          <Box display="flex">
            {!editMode && (
              <IconButton
                variant="icon"
                size="sm"
                icon={<EditIcon />}
                aria-label="editMode"
                onClick={() => {
                  setEditMode(true)
                }}
              />
            )}
            {!hideDelete && (
              <IconButton
                aria-label="delete"
                variant="icon"
                size="sm"
                ml="10px"
                icon={<DeleteIcon />}
                onClick={() => {
                  setModalData({
                    isOpen: true,
                    deleteId: formik.values.id,
                    deleteName: formik.values.name,
                  })
                }}
              />
            )}
          </Box>
        }
      />
      <StepButtonGroup
        step={step}
        setStep={setStep}
        isDisabledIndex={checkFormikError()}
      />

      <CollectionForm
        formik={formik}
        type={editMode ? CollectionFormType.draft : CollectionFormType.readonly}
        step={step}
        setStep={setStep}
      />

      <DeleteDraftModal
        modalData={modalData}
        onClose={() => {
          setModalData((prev) => ({ ...prev, isOpen: false }))
        }}
        deleteDraft={deleteDraft}
      />
      <ModalComponent />
    </Container>
  )
}

export default DraftPage
