import { useState, useEffect, useRef } from 'react'
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  InputGroup,
  InputLeftElement,
  Switch,
  Collapse,
  FormControlProps,
  Tooltip,
} from '@chakra-ui/react'
import ShortUniqueId from 'short-unique-id'
import * as yup from 'yup'
import { useNavigate } from 'react-router-dom'
import FileUploadAndCrop from 'components/common/FileUploadAndCrop'
import { convertFormToProject } from 'utils'
import { uploadImage } from 'api/endpoints/google'
import { saveDraft } from 'utils'
import MenuSelector from 'components/common/MenuSelector'
import { ReactComponent as CollapseIcon } from 'assets/icons/Collapse.svg'
import { ReactComponent as WebsiteIcon } from 'assets/icons/Website.svg'
import { ReactComponent as TwitterIcon } from 'assets/icons/Twitter.svg'
import { ReactComponent as DiscordIcon } from 'assets/icons/Discord.svg'
import { ReactComponent as TelegramIcon } from 'assets/icons/Tg.svg'
import { ReactComponent as TextErrorIcon } from 'assets/icons/text_error.svg'
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg'
import { ReactComponent as PassedIcon } from 'assets/icons/passedBlue.svg'
import { ReactComponent as NotPassedIcon } from 'assets/icons/notpassed.svg'

export enum ProjectFormType {
  draft,
  published,
  readonly,
}

export const initialFormValue = {
  id: '',
  name: '',
  blockchains: [],
  logoURL: '',
  base64logo: '',
  app_universal_links: '',
  app_package_name: '',
  web_domain: '',
  type: '',
  website: '',
  twitter: '',
  discord: '',
  telegram: '',
  contact_name: '',
  email: '',
  job_title: '',
}

const customImageTest = (value: string | [string]) => {
  if (Array.isArray(value)) {
    return value.length > 0
  }
  return typeof value === 'string' && value !== ''
}

export const validationSchema = yup.object({
  name: yup.string().required('This field is required.'),
  blockchains: yup.array().min(1, 'This field is required.'),
  type: yup.string().required('This field is required.'),
  base64logo: yup
    .mixed()
    .test('is-valid-input', 'Invalid input', customImageTest)
    .required('This field is required.'),
  contact_name: yup.string().required('This field is required.'),
  email: yup
    .string()
    .email('Invalid email')
    .required('This field is required.'),
  job_title: yup.string().required('This field is required.'),
  app_universal_links: yup
    .string()
    .test(
      'PlatformRequired',
      'One of Platform must be entered',
      function (item) {
        return (
          this.parent.app_universal_links ||
          this.parent.app_package_name ||
          this.parent.web_domain
        )
      }
    )
    .url('Incorrect format.'),
  app_package_name: yup
    .string()
    .test(
      'platformRequired',
      'One of Platform must be entered',
      function (item) {
        return (
          this.parent.app_universal_links ||
          this.parent.app_package_name ||
          this.parent.web_domain
        )
      }
    ),
  web_domain: yup
    .string()
    .test(
      'platformRequired',
      'One of Platform must be entered',
      function (item) {
        return (
          this.parent.app_universal_links ||
          this.parent.app_package_name ||
          this.parent.web_domain
        )
      }
    )
    .url('Incorrect format.'),
})

const validateGroup = {
  1: ['name', 'type', 'blockchains'],
  2: ['base64logo'],
  3: ['contact_name', 'email', 'job_title'],
}

const projectInfoSectionRequiredInfo = [
  'name',
  'type',
  'web_domain',
  'app_package_name',
  'app_universal_links',
  'blockchains',
]
const sourcesSectionRequiredInfo = ['base64logo']
const contactSectionRequiredInfo = ['contact_name', 'email', 'job_title']

const onSaveDraft = async (formData: FormDataProps, uuid?: string) => {
  if (!formData.name) return
  let payload = formData
  if (!formData.logoURL) {
    payload.logoURL = await uploadImage(formData.base64logo[1])
  }
  return saveDraft(convertFormToProject(payload), uuid)
}

const SwitchInput = ({
  name,
  label,
  placeholder,
  value,
  isInvalid,
  onChange,
  isReadOnly,
}: any) => {
  const [editable, setEditable] = useState<boolean>(true)
  useEffect(() => {
    setEditable(!!value)
  }, [value])
  return (
    <Box>
      <Flex alignItems="center" mb="8px">
        <Box fontWeight="500" mr="8px">
          {label}
        </Box>
        {!isReadOnly && (
          <Switch
            id={name}
            isChecked={editable}
            onChange={(e) => {
              setEditable(e.target.checked)
              if (!e.target.checked) onChange(name, '')
            }}
          />
        )}
      </Flex>

      <Input
        width="100%"
        name={name}
        type="text"
        placeholder={placeholder}
        value={value || ''}
        onChange={(e) => onChange(name, e.target.value)}
        isInvalid={isInvalid}
        isDisabled={!editable}
        isReadOnly={isReadOnly}
      />
    </Box>
  )
}

const FormSecTitle = ({
  isOpen,
  children,
  onClick,
}: {
  isOpen: boolean
  children: React.ReactNode
  onClick: () => void
}) => (
  <Flex
    backgroundColor="white"
    fontWeight="600"
    fontSize="20px"
    mt="20px"
    p="20px 30px"
    borderRadius={isOpen ? '12px 12px 0px 0px' : '12px'}
    cursor="pointer"
    justifyContent="space-between"
    alignItems="center"
    onClick={onClick}
  >
    {children}
    <CollapseIcon style={{ transform: isOpen ? '' : 'rotate(-90deg)' }} />
  </Flex>
)

interface FormFieldProps extends FormControlProps {
  title?: string
  titleHint?: React.ReactNode
  helperText?: string
  isTouched?: boolean
  errorMessage?: string
  isRequired?: boolean
  children: React.ReactNode
}
const FormField = ({
  title,
  helperText,
  isTouched,
  errorMessage,
  isRequired,
  titleHint,
  children,
  ...props
}: FormFieldProps) => (
  <FormControl isInvalid={!!errorMessage && !!isTouched} mb="30px" {...props}>
    <FormLabel display="flex" alignItems="center">
      {title}
      {isRequired && (
        <Box as="span" color="#FF5555" mx="3px">
          *
        </Box>
      )}
      {titleHint}
    </FormLabel>
    <FormHelperText>{helperText}</FormHelperText>
    {children}
    <FormErrorMessage color="#FF5555" fontWeight="500">
      <Box as="span" mx="9px">
        <TextErrorIcon />
      </Box>
      {errorMessage}
    </FormErrorMessage>
  </FormControl>
)

export const ProjectForm = ({
  formik,
  type,
  uuid,
}: {
  formik: any
  type: ProjectFormType
  uuid?: string
}) => {
  const navigate = useNavigate()
  const [openSec1, setOpenSec1] = useState<boolean>(true)
  const [openSec2, setOpenSec2] = useState<boolean>(false)
  const [openSec3, setOpenSec3] = useState<boolean>(false)
  const draftID = useRef(uuid || new ShortUniqueId().randomUUID(6))

  useEffect(() => {
    const el = document.querySelector('[aria-invalid="true"]')
    ;(el?.parentElement ?? el)?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    })
  }, [formik.isSubmitting])

  useEffect(() => {
    window.onbeforeunload = (e) => {
      var dialogText = 'Sure you want to leave?'
      e.returnValue = dialogText
      return dialogText
    }
    return () => {
      window.onbeforeunload = null
    }
  }, [])

  useEffect(() => {
    if (type !== ProjectFormType.readonly && !formik.values.id) {
      onSaveDraft(formik.values, draftID.current)
    }
  }, [formik.values, type, draftID])

  useEffect(() => {
    const updateOpenSec = () => {
      if (!formik.errors) return
      if (
        Object.keys(formik.errors).some((value) =>
          projectInfoSectionRequiredInfo.includes(value)
        )
      ) {
        setOpenSec1(true)
      }
      if (
        Object.keys(formik.errors).some((value) =>
          sourcesSectionRequiredInfo.includes(value)
        )
      ) {
        setOpenSec2(true)
      }
      if (
        Object.keys(formik.errors).some((value) =>
          contactSectionRequiredInfo.includes(value)
        )
      ) {
        setOpenSec3(true)
      }
    }
    updateOpenSec()
  }, [formik.errors, formik.isSubmitting])

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormSecTitle
        onClick={() => {
          setOpenSec1((prev) => !prev)
        }}
        isOpen={openSec1}
      >
        <Flex alignItems="center">
          1. Project info
          {type !== ProjectFormType.readonly && (
            <Box ml="11px">
              {validateGroup[1].every((value) => !!formik.values?.[value]) ? (
                <PassedIcon />
              ) : (
                <NotPassedIcon />
              )}
            </Box>
          )}
        </Flex>
      </FormSecTitle>
      <Collapse in={openSec1}>
        <Box
          backgroundColor="white"
          p="30px 40px"
          borderRadius="0px 0px 12px 12px"
          borderTop="1px solid"
          borderColor="border.secondary"
        >
          <FormField
            title="Project Name"
            helperText="Provide your project name. e.g. BloctoBay"
            isRequired={true}
            isTouched={formik.touched.name}
            errorMessage={formik.errors.name}
          >
            <Input
              name="name"
              type="text"
              placeholder="Project Name"
              isReadOnly={type === ProjectFormType.readonly}
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </FormField>

          <FormField
            title="Project type"
            helperText="Choose a type for your project."
            isRequired={true}
            isTouched={formik.touched.type}
            errorMessage={formik.errors.type}
          >
            <MenuSelector
              list={['DeFi', 'NFT', 'GameFi', 'Others']}
              name="type"
              value={formik.values.type}
              onChange={formik.setFieldValue}
              isReadOnly={type === ProjectFormType.readonly}
            />
          </FormField>

          <Box mb="30px">
            <FormLabel>{'Project website & social media'}</FormLabel>
            <FormField mb="0">
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<WebsiteIcon />}
                />
                <Input
                  name="website"
                  type="text"
                  placeholder="Website"
                  value={formik.values.website}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isReadOnly={type === ProjectFormType.readonly}
                />
              </InputGroup>
            </FormField>
            <FormField mb="0">
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<TwitterIcon />}
                />
                <Input
                  name="twitter"
                  type="text"
                  placeholder="Twitter"
                  value={formik.values.twitter}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isReadOnly={type === ProjectFormType.readonly}
                />
              </InputGroup>
            </FormField>
            <FormField mb="0">
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<DiscordIcon />}
                />
                <Input
                  name="discord"
                  type="text"
                  placeholder="Discord"
                  value={formik.values.discord}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isReadOnly={type === ProjectFormType.readonly}
                />
              </InputGroup>
            </FormField>
            <FormField mb="0">
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<TelegramIcon />}
                />
                <Input
                  name="telegram"
                  type="text"
                  placeholder="Telegram"
                  value={formik.values.telegram}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isReadOnly={type === ProjectFormType.readonly}
                />
              </InputGroup>
            </FormField>
          </Box>

          <Box mb="30px">
            <FormField
              title="Platform"
              helperText="Choose at least a platform your project building on and provide the following information."
              isRequired={true}
              mb="0"
              isTouched={formik.touched.web_domain}
              errorMessage={formik.errors.web_domain}
            >
              <SwitchInput
                value={formik.values.web_domain}
                onChange={formik.setFieldValue}
                name="web_domain"
                label="Web - based"
                placeholder="Domain (https://)"
                isInvalid={
                  !!formik.errors.web_domain && !!formik.touched.web_domain
                }
                isReadOnly={type === ProjectFormType.readonly}
              />
            </FormField>
            <FormField
              mb="0"
              isTouched={formik.touched.app_universal_links}
              errorMessage={formik.errors.app_universal_links}
            >
              <SwitchInput
                value={formik.values.app_universal_links}
                onChange={formik.setFieldValue}
                name="app_universal_links"
                label="App iOS"
                placeholder="Universal link (https://)"
                isInvalid={
                  !!formik.errors.app_universal_links &&
                  !!formik.touched.app_universal_links
                }
                isReadOnly={type === ProjectFormType.readonly}
              />
            </FormField>
            <FormField
              mb="0"
              isTouched={formik.touched.app_package_name}
              errorMessage={formik.errors.app_package_name}
            >
              <SwitchInput
                value={formik.values.app_package_name}
                onChange={formik.setFieldValue}
                name="app_package_name"
                label="App - Android"
                placeholder="Package name"
                isReadOnly={type === ProjectFormType.readonly}
              />
            </FormField>
          </Box>

          <FormField
            title="Blockchains"
            helperText="Choose the blockchain(s) your project building on."
            isRequired={true}
            isTouched={formik.touched.blockchains}
            errorMessage={formik.errors.blockchains}
            mb="0"
            isReadOnly={type === ProjectFormType.readonly}
          >
            <Flex direction="row" gap="18px" wrap="wrap">
              {[
                'Flow',
                'Aptos',
                'Solana',
                'Ethereum',
                'Polygon',
                'BSC',
                'Avalanche',
                'Optimism',
                'Arbitrum',
                'Base',
                'Zora',
                'Scroll',
                'Linea',
                'Astar zkEVM',
                'Blast',
              ].map((name: string) => (
                <Checkbox
                  key={name}
                  name="blockchains"
                  isChecked={formik.values?.blockchains?.includes(
                    name.toLowerCase()
                  )}
                  onChange={formik.handleChange}
                  value={name.toLocaleLowerCase()}
                >
                  {name}
                </Checkbox>
              ))}
            </Flex>
          </FormField>
        </Box>
      </Collapse>

      <FormSecTitle
        onClick={() => {
          setOpenSec2((prev) => !prev)
        }}
        isOpen={openSec2}
      >
        <Flex alignItems="center">
          2. Sources
          {type !== ProjectFormType.readonly && (
            <Box ml="11px">
              {formik.values?.base64logo ? <PassedIcon /> : <NotPassedIcon />}
            </Box>
          )}
        </Flex>
      </FormSecTitle>
      <Collapse in={openSec2}>
        <Box
          backgroundColor="white"
          p="30px 40px"
          borderRadius="0px 0px 12px 12px"
          borderTop="1px solid"
          borderColor="border.secondary"
        >
          <FormField
            title="Project Logo"
            isRequired={true}
            isTouched={formik.touched.base64logo}
            errorMessage={formik.errors.base64logo}
            titleHint={
              <Tooltip
                hasArrow
                label={`Recommend image size (square): 1024*1024\nSupported image format: jpg/jpeg/png/svg\nMax file size: 1MB`}
                placement="top"
                backgroundColor="white"
                color="black"
                textAlign="center"
                p="7px 10px"
                width="280px"
              >
                <Box ml="5px">
                  <InfoIcon />
                </Box>
              </Tooltip>
            }
            mb="0"
          >
            <Box maxW="485px" maxH="205px">
              <FileUploadAndCrop
                logo={formik.values.base64logo}
                onFileAccepted={async (acceptedFile) => {
                  formik.setFieldValue('base64logo', acceptedFile)
                }}
                isReadOnly={type === ProjectFormType.readonly}
                clearFile={() => {
                  formik.setFieldValue('base64logo', '')
                }}
                size="1024*1024"
              />
            </Box>
          </FormField>
        </Box>
      </Collapse>

      <FormSecTitle
        onClick={() => {
          setOpenSec3((prev) => !prev)
        }}
        isOpen={openSec3}
      >
        <Flex alignItems="center">
          3. Contact
          {type !== ProjectFormType.readonly && (
            <Box ml="11px">
              {validateGroup[3].every((value) => !!formik.values?.[value]) ? (
                <PassedIcon />
              ) : (
                <NotPassedIcon />
              )}
            </Box>
          )}
        </Flex>
      </FormSecTitle>
      <Collapse in={openSec3}>
        <Box
          backgroundColor="white"
          p="30px 40px"
          borderRadius="0px 0px 12px 12px"
          borderTop="1px solid"
          borderColor="border.secondary"
        >
          <FormField
            title="Name"
            helperText="Provide a contact person."
            isRequired={true}
            isTouched={formik.touched.contact_name}
            errorMessage={formik.errors.contact_name}
          >
            <Input
              name="contact_name"
              type="text"
              placeholder="Name"
              value={formik.values.contact_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isReadOnly={type === ProjectFormType.readonly}
            />
          </FormField>

          <FormField
            title="Email"
            helperText="Provide a contact email."
            isRequired={true}
            isTouched={formik.touched.email}
            errorMessage={formik.errors.email}
          >
            <Input
              name="email"
              type="text"
              placeholder="Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isReadOnly={type === ProjectFormType.readonly}
            />
          </FormField>

          <FormField
            title="Job title"
            helperText="Provide the contact person's job title."
            isRequired={true}
            isTouched={formik.touched.job_title}
            errorMessage={formik.errors.job_title}
          >
            <Input
              name="job_title"
              type="text"
              placeholder="Job title"
              value={formik.values.job_title}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isReadOnly={type === ProjectFormType.readonly}
            />
          </FormField>
        </Box>
      </Collapse>

      {type !== ProjectFormType.readonly && (
        <Flex justify="flex-end" mt="80px">
          <Button
            onClick={() => {
              let payload = formik.values
              if (!payload.name) {
                payload.name = 'untitled'
              }
              onSaveDraft(payload, draftID.current).then((uuid) => {
                if (uuid) {
                  navigate('/projects')
                }
              })
            }}
            mr="30px"
            w="200px"
            variant="outline"
          >
            Local drafts
          </Button>
          <Button type="submit" w="200px">
            Next
          </Button>
        </Flex>
      )}
    </form>
  )
}

export default ProjectForm
