import React, { Fragment, useRef, useState, ReactNode } from 'react'
import { isEmpty, isNil, map } from 'plugins/lodash'
import {
  Center,
  Box,
  Button,
  Divider,
  HStack,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  SimpleGrid,
  Text,
  useOutsideClick,
  VStack,
} from '@chakra-ui/react'
import {
  InputGroup,
  Input as InputComponent,
  InputRightElement,
} from '@chakra-ui/react'
import { CalendarIcon } from '@chakra-ui/icons'
import {
  DateObj,
  useDayzed,
  RenderProps,
  GetBackForwardPropsOptions,
  Calendar,
} from 'dayzed'
import dayjs from 'plugins/dayjs'
import { ReactComponent as ArrowBackDoubleIcon } from 'assets/icons/Arror-double-left.svg'
import { ReactComponent as ArrowBackIcon } from 'assets/icons/Arror-Back.svg'
import { ReactComponent as ArrowNextDoubleIcon } from 'assets/icons/Arror-double-right.svg'
import { ReactComponent as ArrowNextIcon } from 'assets/icons/Arror-Next.svg'

const MONTH_NAMES_DEFAULT = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]
const DAY_NAMES_DEFAULT = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
const DATE_FORMAT_DEFAULT = 'YYYY-MM-DD'

interface SingleDatepickerBackButtonsProps {
  calendars: Calendar[]
  getBackProps: (data: GetBackForwardPropsOptions) => Record<string, any>
}

interface SingleDatepickerForwardButtonsProps {
  calendars: Calendar[]
  getForwardProps: (data: GetBackForwardPropsOptions) => Record<string, any>
}

export interface SingleDatepickerProps {
  disabled?: boolean
  onDateChange: (date: Date) => void
  id?: string
  name?: string
  date: Date
  configs?: SingleDatepickerConfigs
}

export interface SingleDatepickerConfigs {
  dateFormat: string
  monthNames: string[]
  dayNames: string[]
  isDisabledBeforeDay: boolean
  onlyShowWednesday: boolean
}

const SingleDatepickerBackButtons = (
  props: SingleDatepickerBackButtonsProps
) => {
  const { calendars, getBackProps } = props
  return (
    <Fragment>
      <Button
        {...getBackProps({
          calendars,
          offset: 12,
        })}
        variant="ghost"
        size="sm"
      >
        <ArrowBackDoubleIcon />
      </Button>
      <Button {...getBackProps({ calendars })} variant="ghost" size="sm">
        <ArrowBackIcon />
      </Button>
    </Fragment>
  )
}

const SingleDatepickerForwardButtons = (
  props: SingleDatepickerForwardButtonsProps
) => {
  const { calendars, getForwardProps } = props
  return (
    <Fragment>
      <Button {...getForwardProps({ calendars })} variant="ghost" size="sm">
        <ArrowNextIcon />
      </Button>
      <Button
        {...getForwardProps({
          calendars,
          offset: 12,
        })}
        variant="ghost"
        size="sm"
      >
        <ArrowNextDoubleIcon />
      </Button>
    </Fragment>
  )
}

const SingleDatepickerCalendar = (
  props: RenderProps & { configs: SingleDatepickerConfigs } & {
    inputDate: Date
  }
) => {
  const {
    calendars,
    getDateProps,
    getBackProps,
    getForwardProps,
    configs,
    inputDate,
  } = props

  if (isEmpty(calendars)) {
    return null
  }

  return (
    <HStack className="datepicker-calendar">
      {map(calendars, (calendar) => {
        return (
          <VStack key={`${calendar.month}${calendar.year}`}>
            <HStack>
              <SingleDatepickerBackButtons
                calendars={calendars}
                getBackProps={getBackProps}
              />
              <Text fontSize="14px" fontWeight={500} color="font.primary">
                {configs.monthNames[calendar.month]} {calendar.year}
              </Text>
              <SingleDatepickerForwardButtons
                calendars={calendars}
                getForwardProps={getForwardProps}
              />
            </HStack>
            <Divider />
            <SimpleGrid columns={7} spacing={2} textAlign="center">
              {map(configs.dayNames, (day) => (
                <Box key={`${calendar.month}${calendar.year}${day}`}>
                  <Text fontSize="12px" fontWeight={400} color="font.secondary">
                    {day}
                  </Text>
                </Box>
              ))}
              {map(calendar.weeks, (week, weekIndex) => {
                return map(week, (dateObj: DateObj, index) => {
                  const { date } = dateObj
                  const key = `${calendar.month}${calendar.year}${weekIndex}${index}`
                  const isWednesday = dayjs(date).day() === 3
                  const isDisabled = configs.onlyShowWednesday
                    ? dayjs().isAfter(dayjs(date)) || !isWednesday
                    : false
                  const isSelect = dayjs(date).isSame(
                    dayjs.unix(Number(inputDate)),
                    'day'
                  )
                  return (
                    <Button
                      {...getDateProps({
                        dateObj,
                        disabled: true,
                      })}
                      key={key}
                      size="sm"
                      variant="outline"
                      border="none"
                      isDisabled={isDisabled}
                    >
                      <Center
                        p="10px"
                        minW="37px"
                        borderRadius="50%"
                        bg={isSelect ? 'icon.highlight' : 'transparent'}
                        color={isSelect ? 'font.inverse' : 'font.primary'}
                        _hover={{
                          bg: 'icon.highlight',
                          color: 'font.inverse',
                        }}
                      >
                        {date.getDate()}
                      </Center>
                    </Button>
                  )
                })
              })}
            </SimpleGrid>
          </VStack>
        )
      })}
    </HStack>
  )
}

const SingleDatepicker: React.FC<SingleDatepickerProps> = ({
  configs = {
    dateFormat: DATE_FORMAT_DEFAULT,
    monthNames: MONTH_NAMES_DEFAULT,
    dayNames: DAY_NAMES_DEFAULT,
    isDisabledBeforeDay: true,
    onlyShowWednesday: true,
  },
  ...props
}) => {
  const { date, name, disabled, onDateChange, id } = props

  const ref = useRef<HTMLElement>(null)
  const initialFocusRef = useRef<HTMLInputElement>(null)
  const [popoverOpen, setPopoverOpen] = useState(false)

  const icon: ReactNode = <CalendarIcon fontSize="sm" />

  useOutsideClick({
    ref: ref,
    handler: () => setPopoverOpen(false),
  })

  const onDateSelected = (options: { selectable: boolean; date: Date }) => {
    const { selectable, date } = options
    if (!selectable) return
    if (!isNil(date)) {
      onDateChange(dayjs(date).format(configs.dateFormat) as any)
      setPopoverOpen(false)
      return
    }
  }

  const dayzedData = useDayzed({
    showOutsideDays: true,
    onDateSelected,
    selected: date,
  })

  return (
    <Popover
      placement="bottom-start"
      variant="responsive"
      isOpen={popoverOpen}
      onClose={() => setPopoverOpen(false)}
      initialFocusRef={initialFocusRef}
      isLazy
    >
      <PopoverTrigger>
        <InputGroup>
          <InputComponent
            id={id}
            autoComplete="off"
            background="white"
            isDisabled={disabled}
            ref={initialFocusRef}
            onClick={() => setPopoverOpen(!popoverOpen)}
            name={name}
            value={
              !date
                ? ''
                : (dayjs.unix(Number(date)).format(configs.dateFormat) as any)
            }
            onChange={(e) => e.target.value}
          />
          <InputRightElement color="gray.500" children={icon} />
        </InputGroup>
      </PopoverTrigger>
      <PopoverContent ref={ref}>
        <PopoverBody
          padding={'10px 5px'}
          boxShadow="0px 0px 10px rgba(35, 37, 40, 0.05)"
        >
          <SingleDatepickerCalendar
            {...dayzedData}
            inputDate={date}
            configs={configs}
          />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

export default SingleDatepicker
