import { Box, Flex, useToast } from '@chakra-ui/react'
import { ReactComponent as CopyIcon } from 'assets/icons/Copy.svg'

const AppIdBox = ({ id }: { id?: string }) => {
  const toast = useToast()
  return !!id ? (
    <Box borderRadius="12px" backgroundColor="white">
      <Flex
        fontWeight="600"
        fontSize="20px"
        p="20px 30px"
        borderColor="border.secondary"
      >
        App ID
      </Flex>
      <Box
        p="30px 40px"
        cursor="pointer"
        onClick={() => {
          window.navigator.clipboard.writeText(id || '').then(() =>
            toast({
              title: 'App ID copied!',
              status: 'success',
              duration: 2000,
              variant: 'BloctoBlue',
            })
          )
        }}
      >
        <Flex
          justifyContent="space-between"
          backgroundColor="gray05"
          p="14px 20px"
          borderRadius="8px"
        >
          {id}
          <CopyIcon />
        </Flex>
      </Box>
    </Box>
  ) : null
}

export default AppIdBox
