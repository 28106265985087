import { FC } from 'react'
import { Tooltip, Box } from '@chakra-ui/react'
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg'

interface CustomToolTipProps {
  size?: string
}

const CustomToolTip: FC<CustomToolTipProps> = ({ size = '512*512' }) => (
  <Tooltip
    hasArrow
    label={`Recommend image size (square): ${size} Supported image format: jpg/jpeg/png/svg Max file size: 1MB`}
    placement="top"
    backgroundColor="white"
    color="black"
    textAlign="center"
    p="7px 10px"
    width="280px"
  >
    <Box ml="5px">
      <InfoIcon />
    </Box>
  </Tooltip>
)

export default CustomToolTip
