import { useState, useContext, useMemo } from 'react'
import {
  Box,
  useToast,
  Flex,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'
import CollectionItem from 'components/CollectionItem'
import { Container } from 'components/Sidebar'
import AuthContext from '../context/auth'
import DropdownButton from 'components/common/DropdownButton'
import PageTitle from 'components/PageTitle'
import Empty from 'components/common/Empty'
import { CollectionItemSkeletonList } from 'components/CollectionSkeleton'
import DeleteDraftModal from 'components/DeleteDraftModal'
import { useDebounceEffect } from 'hooks/useDebounceEffect'
import useStatusModal from 'hooks/useStatusModal'
import { getCollection, deleteCollection } from 'api/endpoints/collection'

enum FilterStates {
  all = 'All',
  listing = 'Listing',
  processing = 'Processing',
  draft = 'Local drafts',
}

enum filterChainStates {
  flow = 'Flow',
}

const FILTER_OPTIONS = [
  { label: 'All', value: FilterStates.all },
  { label: 'Listing', value: FilterStates.listing },
  { label: 'Processing', value: FilterStates.processing },
  { label: 'Local drafts', value: FilterStates.draft },
]
const FILTER_CHAIN_OPTIONS = [{ label: 'Flow', value: filterChainStates.flow }]

const CollectionPage = () => {
  const { user } = useContext(AuthContext)
  const toast = useToast()
  const [collection, setCollection] = useState<ActiveCollection[]>([])
  const [loading, setLoading] = useState(true) // TODO loading dialog
  const [openModal, ModalComponent] = useStatusModal()

  const [filterState, setFilterState] = useState<FilterStates>(FilterStates.all)
  const [filterChainState, setFilterChainState] = useState<filterChainStates>(
    filterChainStates.flow
  )

  const [modalData, setModalData] = useState({
    isOpen: false,
    deleteName: '',
    deleteId: '',
  })

  const getCollectionList = async () => {
    try {
      setLoading(true)
      const res = await getCollection()
      if (res?.collections) {
        setCollection(res.collections)
      }
    } catch (error: any) {
      toast({
        title: `Error: ${error}`,
        status: 'error',
        duration: 2000,
      })
    } finally {
      setLoading(false)
    }
  }

  useDebounceEffect(
    async () => {
      await getCollectionList()
    },
    300,
    [user, toast, setCollection]
  )

  const deleteDraft = async () => {
    try {
      const { success } = await deleteCollection(modalData.deleteId)
      if (success) {
        openModal(
          'success',
          'You can check your NFT collection state.',
          async () => await getCollectionList()
        )
      }
    } catch (error) {
      console.error(error)
    }
  }

  const showEmpty = collection.length === 0

  const displayStatus = useMemo(() => {
    const displayStatusMap = {
      [FilterStates.all]: {
        showList: collection,
      },
      [FilterStates.listing]: {
        showList: collection.filter(
          (item) => item.status !== 'draft' && item.status !== 'processing'
        ),
      },
      [FilterStates.processing]: {
        showList: collection.filter((item) => item.status === 'processing'),
      },
      [FilterStates.draft]: {
        showList: collection.filter((item) => item.status === 'draft'),
      },
    }

    const status = displayStatusMap[filterState]
    return { ...status }
  }, [filterState, collection])

  return (
    <Container>
      <Box>
        <Flex justify="space-between" align="center" mb="9px">
          <PageTitle
            title="NFT Collection"
            subtitle="Your collection.Add and manage your projects here."
          />
          <Button as={RouterLink} variant="outline" to="/collection/new">
            Create
          </Button>
        </Flex>
        <Flex>
          <Menu offset={[0, 15]} autoSelect={false}>
            <MenuButton
              as={DropdownButton}
              mr="12px"
              variant="filter"
              minWidth="155px"
            >
              {filterChainState}
            </MenuButton>
            <MenuList minWidth="155px">
              {FILTER_CHAIN_OPTIONS.map((option) => (
                <MenuItem
                  key={option.value}
                  onClick={() => setFilterChainState(option.value)}
                >
                  {option.label}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
          <Menu offset={[0, 15]} autoSelect={false}>
            <MenuButton as={DropdownButton} variant="filter" minWidth="155px">
              {filterState}
            </MenuButton>
            <MenuList minWidth="155px">
              {FILTER_OPTIONS.map((option) => (
                <MenuItem
                  key={option.value}
                  onClick={() => setFilterState(option.value)}
                >
                  {option.label}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </Flex>
      </Box>
      <Box mt="30px">
        {loading && <CollectionItemSkeletonList numbers={8} />}

        {!showEmpty && (
          <Flex flexDirection="row" gap="20px" flexWrap="wrap">
            {displayStatus.showList.map((item) => (
              <CollectionItem
                key={item.id}
                appId={item.id}
                logo={item.squared_logo}
                name={item.name}
                type={item.status}
                isDraft={item.status === 'draft'}
                onDeleteDraft={() =>
                  item.status === 'draft' &&
                  setModalData({
                    isOpen: true,
                    deleteId: item.id,
                    deleteName: item.name,
                  })
                }
              />
            ))}
          </Flex>
        )}

        {showEmpty && <Empty text="collection" />}
      </Box>

      <DeleteDraftModal
        modalData={modalData}
        onClose={() => {
          setModalData((prev) => ({ ...prev, isOpen: false }))
        }}
        deleteDraft={deleteDraft}
      />
      <ModalComponent />
    </Container>
  )
}
export default CollectionPage
