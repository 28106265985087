import { FC, useState } from 'react'
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  InputGroup,
  InputLeftElement,
  Collapse,
  Textarea,
  useDisclosure,
} from '@chakra-ui/react'
import { FormSecTitle, FormField } from 'components/form'
import FileUpload from 'components/common/FileUploadAndCrop'
import ImageTooltip from 'components/common/ImageTooltip'
import PreviewModal from './PreviewModal'
import { CollectionFormType, validateGroup } from './index'
import { ReactComponent as WebsiteIcon } from 'assets/icons/Website.svg'
import { ReactComponent as TwitterIcon } from 'assets/icons/Twitter.svg'
import { ReactComponent as DiscordIcon } from 'assets/icons/Discord.svg'
import { ReactComponent as TelegramIcon } from 'assets/icons/Tg.svg'
import { ReactComponent as PassedIcon } from 'assets/icons/passedBlue.svg'
import { ReactComponent as NotPassedIcon } from 'assets/icons/notpassed.svg'

export enum PreviewModalType {
  APP = 'Blocto App',
  WEBSITE = 'BloctoBay Website',
}

interface ProjectInfoProps {
  formik: any
  type: CollectionFormType
  openSec1: boolean
  setOpenSec1: () => void
}

const ProjectInfo: FC<ProjectInfoProps> = ({
  formik,
  type,
  openSec1,
  setOpenSec1,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [previewModalType, setPreviewModalType] = useState<PreviewModalType>(
    PreviewModalType.APP
  )

  const openPreviewModal = (type: PreviewModalType) => {
    setPreviewModalType(type)
    onOpen()
  }

  return (
    <>
      <FormSecTitle onClick={setOpenSec1} isOpen={openSec1}>
        <Flex alignItems="center">
          1. Collection info
          {type !== CollectionFormType.readonly && (
            <Box ml="11px">
              {validateGroup[1].every((value) => !!formik.values?.[value]) ? (
                <PassedIcon />
              ) : (
                <NotPassedIcon />
              )}
            </Box>
          )}
        </Flex>
      </FormSecTitle>
      <Collapse in={openSec1}>
        <Box
          backgroundColor="white"
          p="30px 40px"
          borderRadius="0px 0px 12px 12px"
          borderTop="1px solid"
          borderColor="border.secondary"
        >
          <FormField
            title="Collection name"
            helperText="Provide your collection name, e.g., BloctoBay, within 30 characters. The display on app may vary depend upon the screen size."
            isRequired={true}
            isTouched={formik.touched.name}
            errorMessage={formik.errors.name}
          >
            <Input
              name="name"
              type="text"
              placeholder="Collection Name"
              isReadOnly={type === CollectionFormType.readonly}
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </FormField>

          <FormField
            title="Collection Description"
            isTouched={formik.touched.description}
            errorMessage={formik.errors.description}
          >
            <Textarea
              name="description"
              placeholder="Collection Description"
              isReadOnly={type === CollectionFormType.readonly}
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={
                type !== CollectionFormType.readonly ? formik.handleBlur : null
              }
            />
          </FormField>

          <Box mb="30px">
            <FormLabel>Project website & social media</FormLabel>
            <FormField mb="0">
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<WebsiteIcon />}
                />
                <Input
                  name="social_links.website"
                  type="text"
                  placeholder="Website"
                  value={formik.values.social_links.website}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isReadOnly={type === CollectionFormType.readonly}
                />
              </InputGroup>
            </FormField>
            <FormField mb="0">
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<TwitterIcon />}
                />
                <Input
                  name="social_links.twitter"
                  type="text"
                  placeholder="Twitter"
                  value={formik.values.social_links.twitter}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isReadOnly={type === CollectionFormType.readonly}
                />
              </InputGroup>
            </FormField>
            <FormField mb="0">
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<DiscordIcon />}
                />
                <Input
                  name="social_links.discord"
                  type="text"
                  placeholder="Discord"
                  value={formik.values.social_links.discord}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isReadOnly={type === CollectionFormType.readonly}
                />
              </InputGroup>
            </FormField>
            <FormField mb="0">
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<TelegramIcon />}
                />
                <Input
                  name="social_links.telegram"
                  type="text"
                  placeholder="Telegram"
                  value={formik.values.social_links.telegram}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isReadOnly={type === CollectionFormType.readonly}
                />
              </InputGroup>
            </FormField>
          </Box>
          <Box mb="30px">
            <FormControl>
              <FormLabel>Blocto App</FormLabel>
              <FormHelperText>Your can preview your collection.</FormHelperText>
            </FormControl>
            <Flex mt="14px" gap="16px">
              <Box minW="49%">
                <FormField
                  title="Logo"
                  isRequired={true}
                  isTouched={formik.touched.base64squared_logo}
                  errorMessage={formik.errors.base64squared_logo}
                  titleHint={<ImageTooltip />}
                  mb="0"
                >
                  <FileUpload
                    logo={formik.values.base64squared_logo}
                    onFileAccepted={(acceptedFile) => {
                      formik.setFieldValue('base64squared_logo', acceptedFile)
                    }}
                    clearFile={() => {
                      formik.setFieldValue('squared_logo', '')
                      formik.setFieldValue('base64squared_logo', '')
                    }}
                    isReadOnly={type === CollectionFormType.readonly}
                    circularCrop={true}
                    size="512*512"
                  />
                </FormField>
              </Box>
              <Box minW="49%">
                <FormField
                  title="Collection card"
                  isRequired={true}
                  isTouched={formik.touched.base64banner_image}
                  errorMessage={formik.errors.base64banner_image}
                  titleHint={<ImageTooltip size="1308*560" />}
                  mb="0"
                >
                  <FileUpload
                    logo={formik.values.base64banner_image}
                    onFileAccepted={(acceptedFile) => {
                      formik.setFieldValue('base64banner_image', acceptedFile)
                    }}
                    clearFile={() => {
                      formik.setFieldValue('banner_image', '')
                      formik.setFieldValue('base64banner_image', '')
                    }}
                    isReadOnly={type === CollectionFormType.readonly}
                    size="1308*560"
                  />
                </FormField>
              </Box>
            </Flex>
            <Flex mt="22px" justifyContent="flex-start">
              <Button onClick={() => openPreviewModal(PreviewModalType.APP)}>
                Preview App
              </Button>
            </Flex>
          </Box>
          <Box mb="30px">
            <FormControl>
              <FormLabel>BloctoBay Website</FormLabel>
              <FormHelperText>
                NFT collection display view on BloctoBay.
              </FormHelperText>
            </FormControl>
            <Flex mt="14px" w="100%">
              <Box minW="100%">
                <FormField
                  title="Store banner"
                  isRequired={true}
                  isTouched={formik.touched.base64store_banner}
                  errorMessage={formik.errors.base64store_banner}
                  titleHint={<ImageTooltip size="1308*560" />}
                  mb="0"
                >
                  <FileUpload
                    logo={formik.values.base64store_banner}
                    onFileAccepted={(acceptedFile) => {
                      formik.setFieldValue('base64store_banner', acceptedFile)
                    }}
                    clearFile={() => {
                      formik.setFieldValue('store_banner', '')
                      formik.setFieldValue('base64store_banner', '')
                    }}
                    isReadOnly={type === CollectionFormType.readonly}
                    size="1308*560"
                  />
                </FormField>
              </Box>
            </Flex>
          </Box>
          <FormField
            title="Description"
            isTouched={formik.touched.store_description}
            errorMessage={formik.errors.store_description}
          >
            <Textarea
              name="store_description"
              placeholder="Description"
              isReadOnly={type === CollectionFormType.readonly}
              value={formik.values.store_description}
              onChange={formik.handleChange}
              onBlur={
                type !== CollectionFormType.readonly ? formik.handleBlur : null
              }
            />
          </FormField>
          <Flex mt="22px" justifyContent="flex-start">
            <Button
              w="160px"
              onClick={() => openPreviewModal(PreviewModalType.WEBSITE)}
            >
              Preview Website
            </Button>
          </Flex>
        </Box>
      </Collapse>
      <PreviewModal
        isOpen={isOpen}
        onClose={onClose}
        previewModalType={previewModalType}
        formik={formik}
      />
    </>
  )
}

export default ProjectInfo
