import { Flex, Skeleton, SkeletonProps } from '@chakra-ui/react'

const StyledSkeleton = (props: SkeletonProps) => (
  <Skeleton
    height="22px"
    borderRadius="100px"
    startColor="gray03"
    endColor="gray04"
    {...props}
  />
)

export const CollectionItemSkeleton = () => (
  <Flex
    mb="20px"
    w="240px"
    borderRadius="12px"
    backgroundColor="white"
    height="326px"
    justify="space-between"
  >
    <Flex
      wrap="wrap"
      w="100%"
      justifyContent="center"
      alignItems="flex-end"
      mb="16px"
    >
      <Flex flexDirection="column" justifyContent="center">
        <StyledSkeleton width="208px" />
        <StyledSkeleton width="208px" mt="10px" />
      </Flex>
    </Flex>
  </Flex>
)

export const CollectionItemSkeletonList = ({
  numbers,
}: {
  numbers: number
}) => (
  <Flex wrap="wrap" gap="20px">
    {[...Array(numbers)].map((_, idx) => (
      <CollectionItemSkeleton key={idx} />
    ))}
  </Flex>
)
