const CLOUD_FUNCTION_API_BASE = process.env.REACT_APP_CLOUD_FUNCTION_API_BASE

export const uploadImage = async (acceptedFile: string) => {
  if (!acceptedFile) return ''
  try {
    let logoUrl = await fetch(`${CLOUD_FUNCTION_API_BASE}/uploadFile`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ acceptedFile }),
    })
      .then((res) => res.json())
      .then((data) => data.logo)
    return logoUrl
  } catch (e: any) {
    throw new Error(e)
  }
}
