import { forwardRef } from 'react'
import { Center, Icon } from '@chakra-ui/react'
import { CSSObject } from '@emotion/react'

interface DropdownButtonProps {
  children: React.ReactNode
  variant?: 'filter' | 'header' | 'input'
  arrowSize?: string
}

const ArrorIcon = ({ ...props }) => (
  <Icon ml="8px" viewBox="0 0 12 7" transition="all 0.2s" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.1329 0.921763C11.2579 1.04679 11.3281 1.21634 11.3281 1.39312C11.3281 1.5699 11.2579 1.73945 11.1329 1.86448L6.46603 6.53138C6.341 6.65636 6.17146 6.72658 5.99467 6.72658C5.81789 6.72658 5.64834 6.65636 5.52331 6.53138L0.856418 1.86448C0.734973 1.73874 0.667773 1.57033 0.669292 1.39552C0.670812 1.22071 0.740928 1.0535 0.864539 0.929884C0.988151 0.806273 1.15537 0.736157 1.33017 0.734638C1.50498 0.733119 1.67339 0.800318 1.79913 0.921763L5.99467 5.11731L10.1902 0.921763C10.3152 0.796776 10.4848 0.726562 10.6616 0.726562C10.8384 0.726562 11.0079 0.796776 11.1329 0.921763Z"
      fill="currentColor"
    />
  </Icon>
)

const DropdownButtonStyles = (variant?: string): CSSObject => {
  switch (variant) {
    case 'filter':
      return {
        px: '20px',
        py: '11px',
        borderRadius: 100,
        backgroundColor: 'gray05',
      }
    case 'header':
      return { fontSize: '12px', px: '10px', py: '5px', borderRadius: 100 }
    default:
    case 'input':
      return { px: '20px', py: '12px', borderRadius: 'md' }
  }
}

const DropdownButton = (
  { children, variant, arrowSize = '9px', ...props }: DropdownButtonProps,
  ref: React.LegacyRef<HTMLDivElement>
) => {
  return (
    <Center
      ref={ref}
      boxShadow="0 0 0 1px #DCDCDC"
      _hover={{
        boxShadow: '0 0 0 1.5px #DCDCDC',
      }}
      _active={{
        boxShadow: '0 0 0 1.5px #DCDCDC',
      }}
      cursor="pointer"
      __css={DropdownButtonStyles(variant)}
      {...props}
    >
      {children}
      <ArrorIcon
        boxSize={arrowSize}
        sx={{
          '[data-active] &': {
            transform: 'rotate(180deg)',
          },
        }}
      />
    </Center>
  )
}

export default forwardRef(DropdownButton)
