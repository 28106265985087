import {
  Box,
  Text,
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  keyframes,
} from '@chakra-ui/react'
import { ReactComponent as ModalLoadingIcon } from 'assets/modal_loading.svg'

const spin = keyframes`
  from {transform: rotate(0deg);}
  to {transform: rotate(360deg)}`
const spinAnimation = `${spin} infinite 2s linear`

const StyledModal = ({
  isOpen,
  closingButton,
  isLoading,
  onClose,
  icon,
  heading,
  detail,
  button,
  isForm,
  closeOnOverlayClick,
}: {
  isOpen: boolean
  closingButton?: boolean
  isLoading?: boolean
  onClose: any
  icon?: React.ReactNode
  heading: React.ReactNode
  detail: React.ReactNode
  button?: React.ReactNode
  isForm?: boolean
  closeOnOverlayClick?: boolean
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered={!isForm}
      size="sm"
      autoFocus={false}
      closeOnOverlayClick={closeOnOverlayClick}
    >
      <ModalOverlay />
      <ModalContent
        maxWidth={isForm ? '75vw' : '400px'}
        bg={isForm ? 'gray05' : 'white'}
      >
        {!!closingButton && (
          <Box height="52px" boxShadow="inset 0px -1px 0px rgba(0, 0, 0, 0.05)">
            <ModalCloseButton top="10px" />
          </Box>
        )}
        <Box p="22px 20px">
          <ModalBody p="0">
            {isLoading ? (
              <Center mb="20px">
                <Box animation={spinAnimation}>
                  <ModalLoadingIcon />
                </Box>
              </Center>
            ) : (
              !!icon && (
                <Center mb="20px">
                  <Box width="60px" height="60px">
                    {icon}
                  </Box>
                </Center>
              )
            )}

            <Text
              textAlign={isForm ? 'left' : 'center'}
              fontSize="20px"
              fontWeight={isForm ? '600' : '500'}
              mb="20px"
            >
              {heading}
            </Text>
            <Box textAlign={isForm ? 'left' : 'center'} fontSize="14px">
              {detail}
            </Box>
          </ModalBody>
          {isLoading || !button ? null : (
            <ModalFooter mt="20px" p="0">
              {button}
            </ModalFooter>
          )}
        </Box>
      </ModalContent>
    </Modal>
  )
}

export default StyledModal
