import { FC, useState, useCallback, useEffect } from 'react'
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
  Tab,
  Link,
  Text,
  Divider,
  Image,
  Center,
  Button,
} from '@chakra-ui/react'
import * as fcl from '@blocto/fcl'
import * as t from '@onflow/types'
import { isEmpty } from 'plugins/lodash'
import { FormField } from 'components/form'
import { CollectionFormType } from './index'
import ScriptResult from 'components/ContractForm/ScriptResult'
import { getContentType } from 'utils'
import { getScript, getGraffleData } from 'api/endpoints/collection'
import { handleSendTransactions } from 'scripts'
import useStatusModal from 'hooks/useStatusModal'
import ImageIcon from 'assets/icons/Image_icon.svg'
import { ReactComponent as PassedIcon } from 'assets/icons/passedBlue.svg'
import { ReactComponent as NotPassedIcon } from 'assets/icons/notpassed.svg'
interface ContractFormProps {
  formik: any
  type: CollectionFormType
  collectionId?: string
  showAllForm?: boolean // show all form only for create mode
  setSubmitIsDisabled?: (value: boolean) => void
}
enum MEDIA_TYPE {
  IMAGE = 'image',
  VIDEO = 'video',
}

enum SCRIPT_TYPE {
  ENABLE = 'enable',
  CHECK = 'check',
  GET = 'get',
  COUNT = 'count',
}

const ContractForm: FC<ContractFormProps> = ({
  formik,
  type,
  collectionId,
  showAllForm = true,
  setSubmitIsDisabled = () => {}, // validate all script is pass change submit button status
}) => {
  const [openModal, ModalComponent, closeModal] = useStatusModal()
  const [graffleData, setGraffleData] = useState('')
  const [mediaContentType, setMediaContentType] = useState<MEDIA_TYPE>(
    MEDIA_TYPE.IMAGE
  )
  // validation flow script from backend
  const [scriptList, setScriptList] = useState<{
    enable?: string
    check?: string
    get?: string
    count?: string
  }>({})
  const [scriptResult, setScriptResult] = useState<{
    enable?: string
    check?: string
    get?: string
    count?: string
  }>({})

  const checkChainHandler = async (type: SCRIPT_TYPE) => {
    try {
      const script = scriptList[type]
      const args: any = []

      const handleScript = async (field: string, formik: any) => {
        await formik.validateField(field)
        formik.setTouched({ [field]: isEmpty(formik.values[field]) })
        if (!isEmpty(formik.values[field])) {
          args.push(fcl.arg(formik.values[field], t.String))
          openModal('loading')
          const result = await handleSendTransactions(script as string, args)
          setScriptResult((prevResult) => ({
            ...prevResult,
            [type]: JSON.stringify(result),
          }))
          closeModal()
        }
      }

      switch (type) {
        case SCRIPT_TYPE.GET:
          args.push(fcl.arg('offset', t.Int, 0))
          args.push(fcl.arg('limit', t.Int, 10))
          await handleScript('contract_script_get_address', formik)
          break
        case SCRIPT_TYPE.CHECK:
          await handleScript('contract_script_check_address', formik)
          break
        case SCRIPT_TYPE.COUNT:
          await handleScript('contract_script_count_address', formik)
          break
        case SCRIPT_TYPE.ENABLE:
          const enableResult = await handleSendTransactions(
            script as string,
            args
          )
          setScriptResult((prevResult) => ({
            ...prevResult,
            [type]: JSON.stringify(enableResult),
          }))
          closeModal()
          break
      }
    } catch (error) {
      openModal('error', String(error))
    }
  }

  function extractValueFromURL(url: string): string | null {
    const pattern =
      /^https:\/\/(nft-catalog\.vercel\.app|www\.flow-nft-catalog\.com)\/catalog\/mainnet\/(.+)/
    const match = url.match(pattern)
    if (match && match[2]) {
      return match[2]
    } else {
      return null
    }
  }

  function extractNFTData(data: GraffleResponse, collectionName: string) {
    const nft = data.nfts.filter(
      (nft) => nft.metadata.nftCollectionDisplay.name === collectionName
    )[0]
    const { metadata, serial, media } = nft
    const nftCollectionDisplay = metadata?.nftCollectionDisplay
    const title = metadata?.display.name
    const description = metadata?.display.description
    const thumbnail = metadata?.display.thumbnail
    const rarity = metadata?.attributes[0]?.Value?.card?.rarity
    const maxiMum = metadata?.attributes[0]?.Value?.card?.editions
    const externalURL = nftCollectionDisplay?.externalURL

    return {
      title,
      description,
      thumbnail,
      media,
      serial: String(serial),
      rarity,
      maxiMum,
      externalURL,
    }
  }
  // tab validation
  const checkMetaDataInputIsInsert = useCallback((): boolean | undefined => {
    if (!showAllForm) return false
    const { contract_address, contract_name } = formik.values
    if (isEmpty(contract_address) || isEmpty(contract_name)) {
      return true
    } else {
      return false
    }
  }, [formik, showAllForm])

  // tab validation
  const checkScriptInputIsInsert = useCallback((): boolean | undefined => {
    if (!showAllForm) return false
    const {
      contract_address,
      contract_name,
      graffle_collection_name,
      storage_path,
      public_path,
      collection_public,
    } = formik.values
    if (
      isEmpty(contract_address) ||
      isEmpty(contract_name) ||
      isEmpty(graffle_collection_name) ||
      isEmpty(storage_path) ||
      isEmpty(public_path) ||
      isEmpty(collection_public)
    ) {
      return true
    } else {
      return false
    }
  }, [formik, showAllForm])

  const getFclScript = async () => {
    if (!showAllForm || !isEmpty(scriptList)) return
    try {
      openModal('loading')
      const scriptListResult = await getScript(collectionId || '', {
        contract_address: formik.values.contract_address,
        contract_name: formik.values.contract_name,
        graffle_collection_name: formik.values.graffle_collection_name,
        storage_path: formik.values.storage_path,
        public_path: formik.values.public_path,
        collection_public: formik.values.collection_public,
      })
      closeModal()
      setScriptList(scriptListResult)
    } catch (error) {
      openModal('error', String(error))
    }
  }

  const checkGraffleData = async () => {
    try {
      await formik.validateField('contract_owner_address')
      await formik.validateField('graffle_url')
      const { graffle_url, contract_owner_address } = formik.values
      const noError = !isEmpty(graffle_url) && !isEmpty(contract_owner_address)
      const invalidUrl = extractValueFromURL(graffle_url)

      if (noError) {
        if (!invalidUrl) {
          return openModal('error', 'Invalid URL')
        }

        openModal('loading')
        const res: GraffleResponse = await getGraffleData({
          owner: contract_owner_address,
          collectionName: invalidUrl,
        })

        if (res) {
          closeModal()
          setGraffleData(JSON.stringify(res))
          formik.setFieldValue('graffle_collection_name', invalidUrl)
          const nftData = extractNFTData(res, invalidUrl)
          // insert formik value
          formik.setFieldValue('contract_metadata_title', nftData.title)
          formik.setFieldValue(
            'contract_metadata_description',
            nftData.description
          )
          formik.setFieldValue('contract_metadata_media', nftData.media)
          formik.setFieldValue('contract_metadata_rarity', nftData.rarity)
          formik.setFieldValue('contract_metadata_thumbnail', nftData.thumbnail)
          formik.setFieldValue(
            'contract_metadata_serial_number',
            nftData.serial
          )
          formik.setFieldValue('contract_metadata_maximum', nftData.maxiMum)
          formik.setFieldValue(
            'contract_metadata_external_URL',
            nftData.externalURL
          )
          const contentType = await getContentType(nftData.media)
          if (contentType) {
            switch (contentType.split(';')[0].trim()) {
              case 'video/':
                setMediaContentType(MEDIA_TYPE.VIDEO)
                break
              case 'image/':
              default:
                setMediaContentType(MEDIA_TYPE.IMAGE)
                break
            }
          } else {
            console.warn('Unable to determine content type for the URL.')
          }
        }
      }
    } catch (error) {
      console.error('error: ', error)
      openModal(
        'error',
        'Cannot obtain metadata. Please verify the NFT Catalog information.'
      )
    }
  }

  useEffect(() => {
    const { enable, check, count, get } = scriptResult
    const isScriptValid =
      !isEmpty(enable) && !isEmpty(check) && !isEmpty(count) && !isEmpty(get)
    setSubmitIsDisabled(!isScriptValid)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scriptResult])

  return (
    <>
      <Tabs>
        <TabList borderBottom="1px solid" borderColor="border.secondary">
          <Tab
            color="font.tertiary"
            fontWeight={600}
            fontSize="16px"
            _selected={{
              color: 'font.highlight',
              borderColor: 'font.highlight',
              borderBottom: '3px solid',
            }}
          >
            Contract
          </Tab>
          <Tab
            color="font.tertiary"
            fontWeight={600}
            fontSize="16px"
            _selected={{
              color: 'font.highlight',
              borderColor: 'font.highlight',
              borderBottom: '3px solid',
            }}
            isDisabled={checkMetaDataInputIsInsert()}
          >
            Metadata
          </Tab>
          <Tab
            color="font.tertiary"
            fontWeight={600}
            fontSize="16px"
            _selected={{
              color: 'font.highlight',
              borderColor: 'font.highlight',
              borderBottom: '3px solid',
            }}
            isDisabled={checkScriptInputIsInsert()}
            onClick={() => getFclScript()}
          >
            Script
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel px="0" pt="0">
            <FormField
              title="Mainnet contract address"
              helperText="Enter Address containing your NFT contract."
              isRequired={true}
              isTouched={formik.touched.contract_address}
              errorMessage={formik.errors.contract_address}
              pt="32px"
              mb="0"
            >
              <Input
                name="contract_address"
                type="text"
                placeholder="Contract Address"
                isReadOnly={type === CollectionFormType.readonly}
                value={formik.values.contract_address}
                onChange={formik.handleChange}
                onBlur={
                  type !== CollectionFormType.readonly
                    ? formik.handleBlur
                    : null
                }
              />
            </FormField>
            <FormField
              title="Mainnet contract name"
              helperText="Enter your NFT contract name."
              isRequired={true}
              isTouched={formik.touched.contract_name}
              errorMessage={formik.errors.contract_name}
              pt="32px"
              mb="0"
            >
              <Input
                name="contract_name"
                type="text"
                placeholder="Contract Name"
                isReadOnly={type === CollectionFormType.readonly}
                value={formik.values.contract_name}
                onChange={formik.handleChange}
                onBlur={
                  type !== CollectionFormType.readonly
                    ? formik.handleBlur
                    : null
                }
              />
            </FormField>
          </TabPanel>
          <TabPanel px="0" pt="0">
            {showAllForm && (
              <>
                <FormField
                  title="1.Graffle"
                  isRequired={true}
                  isTouched={formik.touched.graffle_url}
                  errorMessage={formik.errors.graffle_url}
                  pt="32px"
                  mb="0"
                >
                  <FormLabel>
                    Please provide an approved link of the NFT Catalog for
                    getting the NFT metadata.
                  </FormLabel>
                  <FormHelperText>
                    e.g.
                    https://nft-catalog.vercel.app/catalog/mainnet/RaidersRumble
                  </FormHelperText>
                  <Link
                    href="https://nft-catalog.vercel.app/"
                    isExternal
                    color="font.highlight"
                    fontSize={14}
                  >
                    Flow NFT Catalog
                  </Link>
                  <br />
                  <Link
                    href="https://nft-catalog.vercel.app/v"
                    isExternal
                    color="font.highlight"
                    fontSize={14}
                  >
                    Submitting collection to the NFT Catalog
                  </Link>
                  <Input
                    name="graffle_url"
                    type="text"
                    isReadOnly={type === CollectionFormType.readonly}
                    value={formik.values.graffle_url}
                    onChange={formik.handleChange}
                    onBlur={
                      type !== CollectionFormType.readonly
                        ? formik.handleBlur
                        : null
                    }
                  />
                </FormField>

                <FormField
                  title="Address w/ a minted NFT"
                  helperText="Enter an account address that holds this NFT."
                  isRequired={true}
                  isTouched={formik.touched.contract_owner_address}
                  errorMessage={formik.errors.contract_owner_address}
                  pt="32px"
                  mb="0"
                >
                  <Input
                    name="contract_owner_address"
                    type="text"
                    isReadOnly={type === CollectionFormType.readonly}
                    value={formik.values.contract_owner_address}
                    onChange={formik.handleChange}
                    onBlur={
                      type !== CollectionFormType.readonly
                        ? formik.handleBlur
                        : null
                    }
                  />
                </FormField>
                <Flex
                  bg="background.secondary"
                  p="24px"
                  m="32px 0"
                  borderRadius="12px"
                  flexWrap="wrap"
                >
                  <FormControl>
                    <FormLabel>Check API response </FormLabel>
                    <FormHelperText>
                      It will show whether your NFT collection has implemented
                      the required metadata view.
                    </FormHelperText>
                    {type !== CollectionFormType.readonly && (
                      <FormHelperText>
                        <Button onClick={() => checkGraffleData()}>
                          Check
                        </Button>
                      </FormHelperText>
                    )}
                  </FormControl>
                  <Box w="100%">
                    <ScriptResult
                      result={graffleData}
                      fontLabel="Result"
                      customBg="#fff"
                      noTitle
                    />
                  </Box>
                </Flex>
                <Divider />
              </>
            )}
            {showAllForm && (
              <FormLabel pt="32px">2.Additional Contract Information</FormLabel>
            )}
            <FormField
              title="Collection storage path"
              isRequired={true}
              isTouched={formik.touched.storage_path}
              errorMessage={formik.errors.storage_path}
              pt="32px"
              mb="0"
            >
              <Input
                name="storage_path"
                type="text"
                placeholder="Collection storage path"
                isReadOnly={type === CollectionFormType.readonly}
                value={formik.values.storage_path}
                onChange={formik.handleChange}
                onBlur={
                  type !== CollectionFormType.readonly
                    ? formik.handleBlur
                    : null
                }
              />
            </FormField>
            <FormField
              title="Collection name"
              isRequired={true}
              isTouched={formik.touched.graffle_collection_name}
              errorMessage={formik.errors.graffle_collection_name}
              pt="32px"
              mb="0"
            >
              <Input
                name="graffle_collection_name"
                type="text"
                placeholder="Collection name"
                isReadOnly={type === CollectionFormType.readonly}
                value={formik.values.graffle_collection_name}
                onChange={formik.handleChange}
                onBlur={
                  type !== CollectionFormType.readonly
                    ? formik.handleBlur
                    : null
                }
              />
            </FormField>
            <FormField
              title="Collection public interface"
              isRequired={true}
              isTouched={formik.touched.collection_public}
              errorMessage={formik.errors.collection_public}
              pt="32px"
              mb="0"
            >
              <Input
                name="collection_public"
                type="text"
                placeholder="Collection Public"
                isReadOnly={type === CollectionFormType.readonly}
                value={formik.values.collection_public}
                onChange={formik.handleChange}
                onBlur={
                  type !== CollectionFormType.readonly
                    ? formik.handleBlur
                    : null
                }
              />
            </FormField>
            <FormField
              title="Collection public path"
              isRequired={true}
              isTouched={formik.touched.public_path}
              errorMessage={formik.errors.public_path}
              pt="32px"
              mb="0"
            >
              <Input
                name="public_path"
                type="text"
                placeholder="Collection Public Path"
                isReadOnly={type === CollectionFormType.readonly}
                value={formik.values.public_path}
                onChange={formik.handleChange}
                onBlur={
                  type !== CollectionFormType.readonly
                    ? formik.handleBlur
                    : null
                }
              />
            </FormField>
            {showAllForm && (
              <>
                <Divider m="32px 0" />
                <FormControl>
                  <FormLabel mt="32px">3.Review Metadata</FormLabel>
                  <FormHelperText>
                    View the detailed metadata information based on the Check
                    API response.
                  </FormHelperText>
                </FormControl>
                <FormField
                  title="Title"
                  isTouched={formik.touched.contract_metadata_title}
                  errorMessage={formik.errors.contract_metadata_title}
                  mb="0"
                >
                  <Input
                    name="contract_metadata_title"
                    type="text"
                    isReadOnly={type === CollectionFormType.readonly}
                    value={formik.values.contract_metadata_title}
                    onChange={formik.handleChange}
                    onBlur={
                      type !== CollectionFormType.readonly
                        ? formik.handleBlur
                        : null
                    }
                    disabled
                  />
                </FormField>
                <FormField
                  title="Description"
                  isTouched={formik.touched.contract_metadata_description}
                  errorMessage={formik.errors.contract_metadata_description}
                  pt="32px"
                  mb="0"
                >
                  <Input
                    name="contract_metadata_description"
                    type="text"
                    isReadOnly={type === CollectionFormType.readonly}
                    value={formik.values.contract_metadata_description}
                    onChange={formik.handleChange}
                    onBlur={
                      type !== CollectionFormType.readonly
                        ? formik.handleBlur
                        : null
                    }
                    disabled
                  />
                </FormField>
                <FormField
                  mb="10px"
                  title="Media"
                  isTouched={formik.touched.contract_metadata_media}
                  errorMessage={formik.errors.contract_metadata_media}
                  pt="32px"
                >
                  <Input
                    name="contract_metadata_media"
                    type="text"
                    isReadOnly={type === CollectionFormType.readonly}
                    value={formik.values.contract_metadata_media}
                    onChange={formik.handleChange}
                    onBlur={
                      type !== CollectionFormType.readonly
                        ? formik.handleBlur
                        : null
                    }
                    disabled
                  />
                </FormField>
                <Center
                  bg="background.tertiary"
                  h="200px"
                  flexDirection="column"
                  borderRadius="12px"
                >
                  <Box
                    p="16px"
                    w={
                      !isEmpty(formik?.values?.contract_metadata_media)
                        ? 'auto'
                        : '30px'
                    }
                    h={
                      !isEmpty(formik?.values?.contract_metadata_media)
                        ? '100%'
                        : 'auto'
                    }
                  >
                    {!isEmpty(formik?.values?.contract_metadata_media) &&
                      mediaContentType === MEDIA_TYPE.VIDEO && (
                        <video
                          style={{
                            height: !isEmpty(
                              formik?.values?.contract_metadata_media
                            )
                              ? '100%'
                              : 'auto',
                          }}
                          src={formik?.values?.contract_metadata_media}
                        />
                      )}
                    {mediaContentType === MEDIA_TYPE.IMAGE && (
                      <Image
                        h={
                          !isEmpty(formik?.values?.contract_metadata_media)
                            ? '100%'
                            : 'auto'
                        }
                        src={
                          isEmpty(formik?.values?.contract_metadata_media)
                            ? ImageIcon
                            : formik?.values?.contract_metadata_media
                        }
                      />
                    )}
                  </Box>
                  {isEmpty(formik?.values?.contract_metadata_media) && (
                    <Text
                      mt="17px"
                      fontSize="12"
                      fontWeight={400}
                      color="font.tertiary"
                    >
                      Preview Image
                    </Text>
                  )}
                </Center>
                <FormField
                  title="Rarity (Traits)"
                  isTouched={formik.touched.contract_metadata_rarity}
                  errorMessage={formik.errors.contract_metadata_rarity}
                  pt="32px"
                  mb="0"
                >
                  <Input
                    name="contract_metadata_rarity"
                    type="text"
                    isReadOnly={type === CollectionFormType.readonly}
                    value={formik.values.contract_metadata_rarity}
                    onChange={formik.handleChange}
                    onBlur={
                      type !== CollectionFormType.readonly
                        ? formik.handleBlur
                        : null
                    }
                    disabled
                  />
                </FormField>
                <FormField
                  title="Thumbnail"
                  isTouched={formik.touched.contract_metadata_thumbnail}
                  errorMessage={formik.errors.contract_metadata_thumbnail}
                  pt="32px"
                  mb="10px"
                >
                  <Input
                    name="contract_metadata_thumbnail"
                    type="text"
                    isReadOnly={true}
                    value={formik.values.contract_metadata_thumbnail}
                    onChange={formik.handleChange}
                    onBlur={
                      type !== CollectionFormType.readonly
                        ? formik.handleBlur
                        : null
                    }
                    disabled
                  />
                </FormField>
                <Center
                  bg="background.tertiary"
                  h="200px"
                  flexDirection="column"
                  borderRadius="12px"
                >
                  <Box
                    p="16px"
                    w={
                      !isEmpty(formik?.values?.contract_metadata_thumbnail)
                        ? 'auto'
                        : '30px'
                    }
                    h={
                      !isEmpty(formik?.values?.contract_metadata_thumbnail)
                        ? '100%'
                        : 'auto'
                    }
                  >
                    {!isEmpty(formik?.values?.contract_metadata_thumbnail) &&
                      mediaContentType === MEDIA_TYPE.VIDEO && (
                        <video
                          style={{
                            height: !isEmpty(
                              formik?.values?.contract_metadata_thumbnail
                            )
                              ? '100%'
                              : 'auto',
                          }}
                          src={formik?.values?.contract_metadata_thumbnail}
                        />
                      )}
                    {mediaContentType === MEDIA_TYPE.IMAGE && (
                      <Image
                        h={
                          !isEmpty(formik?.values?.contract_metadata_thumbnail)
                            ? '100%'
                            : '32px'
                        }
                        src={
                          isEmpty(formik?.values?.contract_metadata_thumbnail)
                            ? ImageIcon
                            : formik?.values?.contract_metadata_thumbnail
                        }
                      />
                    )}
                  </Box>
                  {isEmpty(formik?.values?.contract_metadata_thumbnail) && (
                    <Text
                      mt="17px"
                      fontSize="12"
                      fontWeight={400}
                      color="font.tertiary"
                    >
                      Preview Image
                    </Text>
                  )}
                </Center>
                <FormField
                  mb="10px"
                  title="Serial number"
                  isTouched={formik.touched.contract_metadata_serial_number}
                  errorMessage={formik.errors.contract_metadata_serial_number}
                  pt="32px"
                >
                  <Input
                    name="contract_metadata_serial_number"
                    type="text"
                    isReadOnly={type === CollectionFormType.readonly}
                    value={formik.values.contract_metadata_serial_number}
                    onChange={formik.handleChange}
                    onBlur={
                      type !== CollectionFormType.readonly
                        ? formik.handleBlur
                        : null
                    }
                    disabled
                  />
                </FormField>
                <FormField
                  mb="10px"
                  title="Maximum"
                  isTouched={formik.touched.contract_metadata_maximum}
                  errorMessage={formik.errors.contract_metadata_maximum}
                  pt="32px"
                >
                  <Input
                    name="contract_metadata_maximum"
                    type="text"
                    isReadOnly={type === CollectionFormType.readonly}
                    value={formik.values.contract_metadata_maximum}
                    onChange={formik.handleChange}
                    onBlur={
                      type !== CollectionFormType.readonly
                        ? formik.handleBlur
                        : null
                    }
                    disabled
                  />
                </FormField>
                <FormField
                  mb="10px"
                  title="External URL"
                  isTouched={formik.touched.contract_metadata_external_URL}
                  errorMessage={formik.errors.contract_metadata_external_URL}
                  pt="32px"
                >
                  <Input
                    name="contract_metadata_external_URL"
                    type="text"
                    isReadOnly={type === CollectionFormType.readonly}
                    value={formik.values.contract_metadata_external_URL}
                    onChange={formik.handleChange}
                    onBlur={
                      type !== CollectionFormType.readonly
                        ? formik.handleBlur
                        : null
                    }
                    disabled
                  />
                </FormField>
              </>
            )}
          </TabPanel>
          <TabPanel p="0">
            <FormControl pt="32px">
              <FormLabel display="flex" flexWrap="wrap">
                1.Enable
                <Box ml="11px" mb="20px">
                  {isEmpty(scriptResult.enable) ? (
                    <NotPassedIcon />
                  ) : (
                    <PassedIcon />
                  )}
                </Box>
                <FormLabel w="100%">
                  Click "Enable" first to verify the metadata information for
                  the following steps.
                </FormLabel>
              </FormLabel>
              {type !== CollectionFormType.readonly && (
                <FormHelperText mb="32px">
                  <Button onClick={() => checkChainHandler(SCRIPT_TYPE.ENABLE)}>
                    Enable
                  </Button>
                </FormHelperText>
              )}
            </FormControl>
            <ScriptResult
              fontLabel="Result"
              result={scriptResult.enable}
              type={isEmpty(scriptResult.enable) ? 'string' : 'json'}
            />
            <FormControl>
              <FormLabel display="flex" mt="32px" mb="2px">
                2.Address
                <Box ml="11px">
                  {isEmpty(scriptResult.check) ? (
                    <NotPassedIcon />
                  ) : (
                    <PassedIcon />
                  )}
                </Box>
              </FormLabel>
            </FormControl>
            <FormField
              mb="16px"
              isTouched={formik.touched.contract_script_check_address}
              errorMessage={formik.errors.contract_script_check_address}
            >
              <Input
                name="contract_script_check_address"
                type="text"
                isReadOnly={type === CollectionFormType.readonly}
                value={formik.values.contract_script_check_address}
                onChange={formik.handleChange}
                onBlur={
                  type !== CollectionFormType.readonly
                    ? formik.handleBlur
                    : null
                }
              />
              {type !== CollectionFormType.readonly && (
                <FormHelperText mb="32px">
                  <Button onClick={() => checkChainHandler(SCRIPT_TYPE.CHECK)}>
                    Check
                  </Button>
                </FormHelperText>
              )}
            </FormField>
            <ScriptResult
              fontLabel="Script"
              result={scriptList.check}
              type="string"
            />
            <Box mt="10px">
              <ScriptResult
                fontLabel="Result"
                result={scriptResult.check}
                type={isEmpty(scriptResult.check) ? 'string' : 'json'}
              />
            </Box>
            <Divider mt="32px" mb="32px" />
            <FormControl mb="2px">
              <FormLabel display="flex">
                3.Count
                <Box ml="11px">
                  {isEmpty(scriptResult.count) ? (
                    <NotPassedIcon />
                  ) : (
                    <PassedIcon />
                  )}
                </Box>
              </FormLabel>
            </FormControl>
            <FormField
              mb="10px"
              isTouched={formik.touched.contract_script_count_address}
              errorMessage={formik.errors.contract_script_count_address}
            >
              <Input
                name="contract_script_count_address"
                type="text"
                isReadOnly={type === CollectionFormType.readonly}
                value={formik.values.contract_script_count_address}
                onChange={formik.handleChange}
                onBlur={
                  type !== CollectionFormType.readonly
                    ? formik.handleBlur
                    : null
                }
              />
              {type !== CollectionFormType.readonly && (
                <FormHelperText mb="32px">
                  <Button onClick={() => checkChainHandler(SCRIPT_TYPE.COUNT)}>
                    Check
                  </Button>
                </FormHelperText>
              )}
            </FormField>
            <ScriptResult
              fontLabel="Script"
              result={scriptList.count}
              type="string"
            />
            <Box mt="10px">
              <ScriptResult
                fontLabel="Result"
                result={scriptResult.count}
                type={isEmpty(scriptResult.count) ? 'string' : 'json'}
              />
            </Box>
            <Divider mt="32px" mb="32px" />
            <FormControl mb="2px">
              <FormLabel display="flex">
                4.Get
                <Box ml="11px">
                  {isEmpty(scriptResult.get) ? (
                    <NotPassedIcon />
                  ) : (
                    <PassedIcon />
                  )}
                </Box>
              </FormLabel>
            </FormControl>
            <FormField
              mb="10px"
              isTouched={formik.touched.contract_script_get_address}
              errorMessage={formik.errors.contract_script_get_address}
            >
              <Input
                name="contract_script_get_address"
                type="text"
                isReadOnly={type === CollectionFormType.readonly}
                value={formik.values.contract_script_get_address}
                onChange={formik.handleChange}
                onBlur={
                  type !== CollectionFormType.readonly
                    ? formik.handleBlur
                    : null
                }
              />
              {type !== CollectionFormType.readonly && (
                <FormHelperText mb="32px">
                  <Button onClick={() => checkChainHandler(SCRIPT_TYPE.GET)}>
                    Check
                  </Button>
                </FormHelperText>
              )}
            </FormField>
            <ScriptResult
              fontLabel="Script"
              result={scriptList.get}
              type="string"
            />
            <Box mt="10px">
              <ScriptResult
                fontLabel="Result"
                result={scriptResult.get}
                type={isEmpty(scriptResult.get) ? 'string' : 'json'}
              />
            </Box>
          </TabPanel>
        </TabPanels>
      </Tabs>
      <ModalComponent />
    </>
  )
}

export default ContractForm
