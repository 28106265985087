import { FC } from 'react'
import {
  Flex,
  FormControl,
  FormLabel,
  Divider,
  Text,
  Box,
} from '@chakra-ui/react'
import ReactJson from 'react-json-view'
import { isEmpty } from 'plugins/lodash'

interface ScriptResultProps {
  result?: string
  fontLabel: string
  customBg?: string
  noTitle?: boolean
  type?: string
}

const customTheme = {
  base00: 'transparent',
  base01: '#232528',
  base02: 'transparent',
  base03: '#232528',
  base04: '#232528',
  base05: '#232528',
  base06: '#232528',
  base07: '#232528',
  base08: '#232528',
  base09: '#232528',
  base0A: '#232528',
  base0B: '#232528',
  base0C: '#232528',
  base0D: '#232528',
  base0E: '#232528',
  base0F: '#232528',
}
const ScriptResult: FC<ScriptResultProps> = ({
  result = '',
  fontLabel,
  customBg = 'background.tertiary',
  noTitle = false,
  type = 'json',
}) => {
  return (
    <Flex bg={customBg} p="16px 20px" borderRadius="12px" flexWrap="wrap">
      {!noTitle && (
        <>
          <FormControl>
            <FormLabel>{fontLabel}</FormLabel>
          </FormControl>
          <Divider />
        </>
      )}
      <Flex h="246px" w="100%" p={!noTitle ? '15px 0px' : '0'}>
        <Box overflow="auto">
          {isEmpty(result) ? (
            <Text color="font.tertiary">No information.</Text>
          ) : type === 'string' ? (
            <pre
              style={{
                color: '#232528',
                overflow: 'scroll',
                fontSize: '14px',
                fontWeight: 400,
              }}
            >
              <code>{result}</code>
            </pre>
          ) : (
            <ReactJson
              src={JSON.parse(result)}
              enableClipboard={false}
              displayObjectSize={false}
              displayDataTypes={false}
              theme={customTheme}
              style={{
                overflow: 'scroll',
                width: '100%',
                color: '#232528',
              }}
            />
          )}
        </Box>
      </Flex>
    </Flex>
  )
}

export default ScriptResult
