import {
  Box,
  Button,
  Center,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Text,
  useMediaQuery,
  useToast,
} from '@chakra-ui/react'
import { breakpoints } from '../../theme'
import { useCallback, useContext } from 'react'
import AuthContext from '../../context/auth'
import Wallet from '../icons/Wallet'
import { ReactComponent as CopyIcon } from 'assets/icons/Copy.svg'

interface WalletConnectProps {
  address: string
  login: () => void
  logout: () => void
  copyAddress: () => void
}

const WalletConnectDesktop = ({
  address,
  login,
  logout,
  copyAddress,
}: WalletConnectProps) =>
  address ? (
    <Menu offset={[0, 16]} autoSelect={false}>
      <MenuButton as={Button} px="16px">
        <Center>
          <Wallet />
          <Text ml={2} color="white">
            {address
              ? `${address.slice(0, 4)}...${address.slice(-4)}`
              : 'Connect Wallet'}
          </Text>
        </Center>
      </MenuButton>
      <MenuList border="none" boxShadow="rgb(0 0 0 / 5%) 0px 0px 20px">
        <MenuGroup title="Your Address">
          <MenuItem color="#7f7f7f" minHeight="20px" onClick={copyAddress}>
            {address}
            <Box ml="12px">
              <CopyIcon />
            </Box>
          </MenuItem>
        </MenuGroup>
        <MenuDivider color="#efefef" />
        <MenuItem onClick={logout}>Sign Out</MenuItem>
      </MenuList>
    </Menu>
  ) : (
    <Button onClick={login} px="22px">
      <Wallet />
      <Text ml={2}>Connect Wallet</Text>
    </Button>
  )

const WalletConnect = () => {
  const { login, logout, user } = useContext(AuthContext)
  const address = user?.addr

  const toast = useToast()

  const copyAddress = useCallback(() => {
    window.navigator.clipboard.writeText(address || '').then(() =>
      toast({
        title: 'Address copied!',
        status: 'success',
        duration: 2000,
        variant: 'BloctoBlue',
      })
    )
  }, [address, toast])

  const [isDesktop] = useMediaQuery(`(min-width: ${breakpoints.desktop})`)

  const walletConnectProps = { address, login, logout, copyAddress }

  return isDesktop ? <WalletConnectDesktop {...walletConnectProps} /> : null
}

export default WalletConnect
