import { useState } from 'react'
import { Box, Flex, Button, Input } from '@chakra-ui/react'
import Modal from 'components/Modal'
import { ReactComponent as WarnIcon } from 'assets/icons/Modal_warn.svg'
import { ReactComponent as TextErrorIcon } from 'assets/icons/text_error.svg'

const DeleteDraftModal = ({
  modalData,
  deleteDraft,
  onClose,
}: {
  modalData: {
    isOpen: boolean
    deleteName: string
    deleteId: string
  }
  deleteDraft: (id: string) => void
  onClose: () => void
}) => {
  const [value, setValue] = useState('')
  const [isTouched, setIsTouched] = useState(false)

  return (
    <Modal
      isOpen={modalData.isOpen}
      closingButton={true}
      onClose={onClose}
      icon={<WarnIcon />}
      heading="Delete a draft"
      detail={
        <Box textAlign="left">
          <Box mb="20px">
            Are you sure to delete "{modalData.deleteName}"?
            <br />
            This action cannot be reversed.
          </Box>

          <Box mb="8px">
            Please enter "{modalData.deleteName}" to delete this draft.
          </Box>
          <Input
            placeholder="Project Name"
            value={value}
            onBlur={() => setIsTouched(true)}
            onChange={(e) => setValue(e.target.value)}
            isInvalid={isTouched && value !== modalData.deleteName}
          />
          {isTouched ? (
            !value ? (
              <Flex mt="9px" align="center" color="#FF5555" fontWeight="500">
                <Box as="span" mx="9px">
                  <TextErrorIcon />
                </Box>
                This field is required.
              </Flex>
            ) : value !== modalData.deleteName ? (
              <Flex mt="9px" align="center" color="#FF5555" fontWeight="500">
                <Box as="span" mx="9px">
                  <TextErrorIcon />
                </Box>
                Project name does not match.
              </Flex>
            ) : null
          ) : null}
          <Button
            mt="20px"
            width="100%"
            backgroundColor="#365BEA"
            borderRadius="12px"
            isDisabled={value !== modalData.deleteName}
            onClick={() => {
              setValue('')
              setIsTouched(false)
              deleteDraft(modalData.deleteId)
              onClose()
            }}
          >
            OK
          </Button>
        </Box>
      }
    />
  )
}

export default DeleteDraftModal
