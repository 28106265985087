import { FC, useEffect, useCallback } from 'react'
import {
  Box,
  Flex,
  Text,
  FormControl,
  FormLabel,
  FormHelperText,
  Collapse,
  Switch,
  Button,
  useDisclosure,
} from '@chakra-ui/react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { FormSecTitle } from 'components/form'
import { CollectionFormType, validateGroup } from './index'
import ContractModal from './ContractModal'
import ContractForm from './ContractForm'
import useStatusModal from 'hooks/useStatusModal'
import {
  createContract,
  disableContractBySequence,
  enableContractBySequence,
} from 'api/endpoints/collection'
import { deleteDraft } from 'utils'
import { ReactComponent as PassedIcon } from 'assets/icons/passedBlue.svg'
import { ReactComponent as NotPassedIcon } from 'assets/icons/notpassed.svg'

const initialFormValue: CollectonContractFormProps = {
  contract_address: '',
  contract_name: '',
  graffle_collection_name: '',
  storage_path: '',
  public_path: '',
  collection_public: '',
  contract_owner_address: '', // graffle params
  graffle_url: '',
  contract_state: false,
  // only display in get graffle collection
  contract_metadata_title: '',
  contract_metadata_description: '',
  contract_metadata_media: '',
  contract_metadata_rarity: '',
  contract_metadata_thumbnail: '',
  contract_metadata_serial_number: '',
  contract_metadata_maximum: '',
  contract_metadata_external_URL: '',
  // only display in script check
  contract_script_check_address: '',
  contract_script_count_address: '',
  contract_script_get_address: '',
}

const validationSchema = yup.object({
  // backend params
  contract_address: yup.string().required('This field is required.'),
  contract_name: yup.string().required('This field is required.'),
  graffle_collection_name: yup.string().required('This field is required.'),
  storage_path: yup.string().required('This field is required.'),
  public_path: yup.string().required('This field is required.'),
  collection_public: yup.string().required('This field is required.'),
  // graffle params
  contract_owner_address: yup.string().required('This field is required.'),
  graffle_url: yup
    .string()
    .required('This field is required.')
    .url('Invalid URL.'),
  contract_script_check_address: yup
    .string()
    .required('This field is required.'),
  contract_script_count_address: yup
    .string()
    .required('This field is required.'),
  contract_script_get_address: yup.string().required('This field is required.'),
})

interface ContractProps {
  formik: any
  type: CollectionFormType
  openSec2: boolean[]
  setOpenSec2: () => void
  tabTitle?: string
  collectionId?: string
  index: number
}

const Contract: FC<ContractProps> = ({
  formik,
  type,
  openSec2,
  setOpenSec2,
  tabTitle = 'Contract',
  collectionId,
  index,
}) => {
  const { contracts } = formik.values
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [openModal, ModalComponent, closeModal] = useStatusModal()

  // for contract modal form
  const newContractForm = useFormik({
    initialValues: initialFormValue,
    validationSchema: validationSchema,
    onSubmit: async ({
      contract_address,
      contract_name,
      graffle_collection_name,
      storage_path,
      public_path,
      collection_public,
    }) => {
      const payload = {
        contract_address,
        contract_name,
        graffle_collection_name,
        storage_path,
        public_path,
        collection_public,
      }
      openModal('loading')
      try {
        const res = await createContract(collectionId as string, payload)
        if (res) {
          const { contracts } = formik.values
          const newContracts = [...contracts]
          const idx = contracts?.length === 0 ? index : contracts.length
          newContracts[idx] = res
          // check if is draft delete storage
          const storageDrafts = JSON.parse(
            localStorage.getItem('collection_contract_drafts') || '{}'
          )
          const draft = storageDrafts[collectionId as string]
          if (draft) {
            deleteDraft(collectionId as string, 'collection_contract_drafts')
            const newContract = [...formik.values.contracts].filter(
              ({ contract_address }) =>
                contract_address === draft.contract_address
            )
            formik.setFieldValue('contracts', newContract)
          } else {
            formik.setFieldValue('contracts', newContracts)
          }
          closeModal()
          onClose()
        }
      } catch (error) {
        openModal('error', String(error))
      }
    },
  })

  // for contract display
  const onlyDisplayContractForm = useFormik({
    initialValues: initialFormValue,
    validationSchema: validationSchema,
    onSubmit: () => {},
  })

  // insert contract data to formik
  useEffect(() => {
    if (contracts?.length > 0) {
      const fields = [
        'contract_address',
        'contract_name',
        'graffle_collection_name',
        'storage_path',
        'public_path',
        'collection_public',
      ]

      fields.forEach((field) => {
        if (contracts[index][field]) {
          onlyDisplayContractForm.setFieldValue(
            field,
            contracts[index][field] || ''
          )
        }
      })

      onlyDisplayContractForm.setFieldValue(
        'contract_state',
        !contracts[index]?.is_hidden || false
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contracts])

  const isValidateContract = useCallback((): boolean => {
    const requiredFields = validateGroup[2]
    const values: any = { ...onlyDisplayContractForm.values }
    for (const field of requiredFields) {
      if (!values[field] || values[field] === '') {
        return false
      }
    }
    return true
  }, [onlyDisplayContractForm.values])

  const contractStateChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!isValidateContract()) return
    const sequence = contracts[index].sequence
    try {
      openModal('loading')
      if (e.target.checked) {
        await enableContractBySequence(collectionId as string, sequence)
      } else {
        await disableContractBySequence(collectionId as string, sequence)
      }
      onlyDisplayContractForm.setFieldValue('contract_state', e.target.checked)
    } catch (error) {
      openModal('error', String(error))
    }
  }

  const onOpenHandler = () => {
    const fields = [
      'contract_address',
      'contract_name',
      'storage_path',
      'graffle_collection_name',
      'public_path',
      'collection_public',
    ]

    fields.forEach((field) => {
      if (contracts?.[index]?.[field]) {
        newContractForm.setFieldValue(field, contracts[index][field])
      }
    })
    onOpen()
  }

  const syncContractState = () => {
    const fields: (keyof CollectonContractFormProps)[] = [
      'contract_address',
      'contract_name',
      'storage_path',
      'graffle_collection_name',
      'public_path',
      'collection_public',
    ]
    fields.forEach((field) => {
      onlyDisplayContractForm.setFieldValue(
        field,
        newContractForm.values[field]
      )
    })
  }

  return (
    <>
      {index === 0 && (
        <Box>
          <Flex justify="space-between" align="center" m="48px 0 28px 0">
            <Flex alignItems="center" w="80%" wrap="wrap">
              <Text
                w="100%"
                fontSize={16}
                fontWeight={600}
                color="font.primary"
                mb="6px"
              >
                Contract list
              </Text>
              <Text
                fontSize={14}
                fontWeight={400}
                color="font.primary"
                wordBreak="keep-all"
              >
                After filling in Project info completely, you can add the NFT
                contract which contains the collection.
              </Text>
            </Flex>
            <Button
              variant="outline"
              onClick={onOpen}
              isDisabled={
                type === CollectionFormType.readonly || contracts?.length === 0
              }
            >
              Create
            </Button>
          </Flex>
        </Box>
      )}
      <FormSecTitle onClick={setOpenSec2} isOpen={openSec2[index]}>
        <Flex alignItems="center">
          {tabTitle}
          <Box ml="11px">
            {isValidateContract() ? <PassedIcon /> : <NotPassedIcon />}
          </Box>
        </Flex>
      </FormSecTitle>
      <Collapse in={openSec2[index]}>
        <Box
          bg="white"
          p="30px 40px"
          borderRadius="0px 0px 12px 12px"
          borderTop="1px solid"
          borderColor="border.secondary"
        >
          <FormControl bg="background.secondary" p="24px" borderRadius="12px">
            <Flex alignItems="center">
              <FormLabel>Contract state</FormLabel>
              <Switch
                name="contract_state"
                isChecked={onlyDisplayContractForm.values.contract_state}
                onChange={contractStateChange}
                isReadOnly={type === CollectionFormType.readonly}
                mb="8px"
              />
            </Flex>
            <FormHelperText>
              After adding a contract, it cannot be deleted. You can switch the
              contract to a visible or hidden state.
            </FormHelperText>
          </FormControl>
          <Flex w="100%" mt="32px" justifyContent="space-between">
            {!isValidateContract() && (
              <>
                <FormControl>
                  <FormLabel>Fill in the contract information</FormLabel>
                  <FormHelperText>
                    Click "Fill In" to complete all the content of the contract.
                  </FormHelperText>
                </FormControl>
                <Flex justifyContent="flex-end">
                  <Button
                    variant="outline"
                    onClick={() => onOpenHandler()}
                    isDisabled={type === CollectionFormType.readonly}
                  >
                    Fill in
                  </Button>
                </Flex>
              </>
            )}
          </Flex>
        </Box>
        <Box bg="white" p="30px 40px" borderRadius="0px 0px 12px 12px">
          <ContractForm
            collectionId={collectionId}
            formik={onlyDisplayContractForm}
            type={CollectionFormType.readonly}
            showAllForm={false}
          />
        </Box>
      </Collapse>
      <ContractModal
        isOpen={isOpen}
        type={CollectionFormType.draft}
        onClose={onClose}
        formik={newContractForm}
        collectionId={collectionId}
        syncContractState={syncContractState}
      />
      <ModalComponent />
    </>
  )
}

export default Contract
