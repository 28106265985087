import { FC, useEffect } from 'react'
import {
  Box,
  Flex,
  Input,
  Collapse,
  Checkbox,
  Text,
  Link,
} from '@chakra-ui/react'
import DatePicker from 'components/common/DatePicker'
import { FormSecTitle, FormField } from 'components/form'
import { CollectionFormType, validateGroup } from './index'
import dayjs from 'plugins/dayjs'
import { ReactComponent as PassedIcon } from 'assets/icons/passedBlue.svg'
import { ReactComponent as NotPassedIcon } from 'assets/icons/notpassed.svg'

interface ListingInfoProps {
  formik: any
  type: CollectionFormType
  openSec3: boolean
  setOpenSec3: () => void
}

const ListingInfo: FC<ListingInfoProps> = ({
  formik,
  type,
  openSec3,
  setOpenSec3,
}) => {
  const findWendnesday = () => {
    const today = dayjs()
    let nextWednesday
    if (today.day() >= 3) {
      nextWednesday = today.add(1, 'week').weekday(3)
    } else {
      nextWednesday = today.weekday(3)
    }
    return nextWednesday.unix()
  }

  useEffect(() => {
    const nextWednesday = findWendnesday()
    if (
      type !== CollectionFormType.readonly &&
      formik.values.app_listing_date === 0
    ) {
      formik.setFieldValue('app_listing_date', nextWednesday)
    }
    if (
      type !== CollectionFormType.readonly &&
      formik.values.blocto_bay_listing_date === 0
    ) {
      formik.setFieldValue('blocto_bay_listing_date', findWendnesday())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    type,
    formik.values.app_listing_date,
    formik.values.blocto_bay_listing_date,
  ])

  return (
    <>
      <FormSecTitle onClick={setOpenSec3} isOpen={openSec3}>
        <Flex alignItems="center">
          3. Listing info
          {type !== CollectionFormType.readonly && (
            <Box ml="11px">
              {validateGroup[3].every((value) => !!formik.values?.[value]) ? (
                <PassedIcon />
              ) : (
                <NotPassedIcon />
              )}
            </Box>
          )}
        </Flex>
      </FormSecTitle>
      <Collapse in={openSec3}>
        <Box
          backgroundColor="white"
          p="30px 40px"
          borderRadius="0px 0px 12px 12px"
          borderTop="1px solid"
          borderColor="border.secondary"
        >
          <FormField
            title="Embargo date for listing on Blocto App"
            helperText="Please select the earliest date that NFT could be listed in Blocto App."
            isRequired={true}
            isTouched={formik.touched.app_listing_date}
            errorMessage={formik.errors.app_listing_date}
          >
            <Box w="200px">
              <DatePicker
                date={formik.values.app_listing_date}
                onDateChange={(value: Date) =>
                  formik.setFieldValue(
                    'app_listing_date',
                    dayjs(value).unix() // backend needs timestamp
                  )
                }
              />
            </Box>
          </FormField>
          <FormField
            title="Listing on BloctoBay date"
            helperText="Please select the ideal date for NFT to be listed on BloctoBay, which can be the same day as the Blocto App."
            isRequired={true}
            isTouched={formik.touched.blocto_bay_listing_date}
            errorMessage={formik.errors.blocto_bay_listing_date}
          >
            <Box w="200px">
              <DatePicker
                date={formik.values.blocto_bay_listing_date}
                onDateChange={(value: Date) =>
                  formik.setFieldValue(
                    'blocto_bay_listing_date',
                    dayjs(value).unix() // backend needs timestamp
                  )
                }
              />
            </Box>
          </FormField>

          <FormField
            title="BloctoBay Royalty"
            helperText="BloctoBay provides a royalty system for its creators. The amount of royalty percentage can be from 0-10 %. While BloctoBay takes the standard 2.5% fee."
            isRequired={true}
            isTouched={formik.touched.blocto_bay_royalty_ratio}
            errorMessage={formik.errors.blocto_bay_royalty_ratio}
          >
            <Input
              type="number"
              inputMode="decimal"
              placeholder="0~10%"
              name="blocto_bay_royalty_ratio"
              value={formik.values.blocto_bay_royalty_ratio}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isReadOnly={type === CollectionFormType.readonly}
            />
          </FormField>

          <FormField
            title="BloctoBay royalty receiving address"
            helperText="Please provide a Flow address to receive the aforementioned royalty."
            isRequired={true}
            isTouched={formik.touched.blocto_bay_royalty_receiver}
            errorMessage={formik.errors.blocto_bay_royalty_receiver}
          >
            <Input
              name="blocto_bay_royalty_receiver"
              type="text"
              placeholder="0x..."
              value={formik.values.blocto_bay_royalty_receiver}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isReadOnly={type === CollectionFormType.readonly}
            />
          </FormField>
          <FormField
            title="Join Blocto X Partners Discord"
            isRequired={true}
            isTouched={formik.touched.join_discord}
            errorMessage={formik.errors.join_discord}
            mb="0"
            isReadOnly={type === CollectionFormType.readonly}
          >
            <Text color="font.highlight" mb="10px" fontSize={14}>
              Registration form:
              <Link
                pl="5px"
                href="https://forms.gle/Y23ifPDqyTUfSzpG8"
                isExternal
              >
                https://forms.gle/Y23ifPDqyTUfSzpG8
              </Link>
            </Text>
            <Flex direction="row" gap="18px" wrap="wrap">
              <Checkbox
                name="join_discord"
                isChecked={formik.values?.join_discord}
                onChange={(event) =>
                  formik.setFieldValue('join_discord', event.target.checked)
                }
                value={formik.values?.join_discord}
              >
                I have filled out the form to get access to Blocto-partners
                channel in Blocto discord for better communication.
              </Checkbox>
            </Flex>
          </FormField>
        </Box>
      </Collapse>
    </>
  )
}

export default ListingInfo
