import { FC, useState } from 'react'
import { useFormik } from 'formik'
import { useNavigate } from 'react-router-dom'
import { Container } from 'components/Sidebar'
import {
  CollectionForm,
  CollectionFormType,
  initialFormValue,
  validationSchema,
  validateGroup,
} from 'components/CollectionForm/index'
import PageTitle from 'components/PageTitle'
import StepButtonGroup from 'components/StepButtonGroup'
import { submitCollection } from 'api/endpoints/collection'
import useStatusModal from 'hooks/useStatusModal'
import { ReactComponent as FlowIcon } from 'assets/icons/Flow.svg'

const NewCollectionPage: FC<unknown> = () => {
  const [step, setStep] = useState<number>(0)
  const [openModal, ModalComponent, closeModal] = useStatusModal()
  const navigate = useNavigate()

  const submitFnc = async () => {
    try {
      openModal('loading', 'It may take some time.')
      const collectionId = formik.values.id
      const payload = {
        app_listing_date: formik.values.app_listing_date,
        banner_image: formik.values.banner_image,
        blocto_bay_listing_date: formik.values.blocto_bay_listing_date,
        blocto_bay_royalty_ratio: formik.values.blocto_bay_royalty_ratio,
        blocto_bay_royalty_receiver: formik.values.blocto_bay_royalty_receiver,
        description: formik.values.description,
        name: formik.values.name,
        social_links: formik.values.social_links,
        squared_logo: formik.values.squared_logo,
        store_banner: formik.values.store_banner,
        store_description: formik.values.store_description,
      }
      const result = await submitCollection(collectionId, payload)
      closeModal()
      if (result) {
        openModal('success', 'You can check your NFT collection state.', () =>
          navigate('/collection')
        )
      }
    } catch (e) {
      openModal('error', String(e))
    }
  }
  const formik = useFormik({
    initialValues: initialFormValue as any,
    validationSchema: validationSchema,
    onSubmit: () => submitFnc(),
  })

  const checkFormikError = (): number => {
    let currentIndex = 1
    for (let i = 0; i < 3; i++) {
      const currentStep = i + 1
      const fields = validateGroup[currentStep]
      const hasEmptyField = fields.some((field) => !formik.values[field])
      if (hasEmptyField) {
        break
      }
      currentIndex = currentStep + 1
    }
    return currentIndex
  }

  return (
    <Container>
      <PageTitle
        icon={<FlowIcon />}
        title="New NFT Collection"
        subtitle="Your NFT project / collection will be listed on both Blocto app and BloctoBay (NFT marketplace)."
      />
      <StepButtonGroup
        step={step}
        setStep={setStep}
        isDisabledIndex={checkFormikError()}
      />
      <CollectionForm
        formik={formik}
        step={step}
        setStep={setStep}
        type={CollectionFormType.draft}
      />
      <ModalComponent />
    </Container>
  )
}

export default NewCollectionPage
