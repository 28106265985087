import boTheme from '@blocto/web-chakra-theme'
import { extendTheme } from '@chakra-ui/react'
import { merge } from 'plugins/lodash'
export const breakpoints = {
  desktop: '425px',
}

export const theme = extendTheme(merge(boTheme, {
  fonts: {
    body: 'Work Sans, sans-serif',
  },
  colors: {
    black: '#141414',
    gray01: '#7F7F7F',
    gray02: '#BCBCBC',
    gray03: '#DCDCDC',
    gray04: '#EFEFEF',
    gray05: '#F9F9F9',
    BloctoBlue: {
      Light01: '#365BEA',
      Light02: '#0A94FF',
      Light03: '#A6D9FF',
      Light04: '#E0F0FF',
      Light05: '#F0F8FF',
      Light06: '#F0F3F8',
    }
  },
  radii: {
    md: '8px',
  },
  components: {
    Heading: {
      baseStyle: {
        fontFamily: 'inherit',
      },
      variants: {
        h1: {
          fontSize: '36px',
          lineHeight: '44px',
          fontWeight: '700',
        },
        h3: {
          fontSize: '24px',
          lineHeight: '30px',
          fontWeight: '600',
        },
      },
    },
    Form: {
      baseStyle: {
        helperText: {
          fontSize: '14px',
          lineHeight: '20px',
          color: 'gray01',
          my: '8px',
        },
      },
    },
    FormLabel: {
      baseStyle: {
        mb: '8px',
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '22px',
        color: '#141414',
      },
    },
    Input: {
      baseStyle: {
        field: {
          _disabled: {
            opacity: 1,
            _hover: {
              borderWidth: '0',
              borderColor: 'transparent',
            }
          }

        }
      },
      sizes: {
        md: {
          field: {
            fontSize: '14px',
            px: 4,
            h: '48px',
            borderRadius: '8px',
          },
        },
      },
      variants: {
        outline: {
          field: {
            border: '1px solid',
            borderColor: 'inherit',
            bg: 'inherit',
            _hover: {
              borderColor: 'gray03',
            },
            _readOnly: {
              backgroundColor: 'background.tertiary',
              borderColor: 'background.tertiary',
              userSelect: 'none',
              borderWidth: '0px !important',
            },
            _disabled: {
              backgroundColor: 'background.tertiary',
              cursor: 'not-allowed',
              borderColor: 'background.tertiary',
            },
            _invalid: {
              borderColor: '#FF5555',
              boxShadow: 'none',
            },
            _focusVisible: {
              zIndex: 1,
              borderColor: 'gray03',
              boxShadow: `0 0 0 1px gray03`,
              borderWidth: '1.5px',
            },
            _placeholder: {
              color: 'gray02',
            },
          },
        },
      },
    },
    Textarea: {
      variants: {
        outline: {
          border: '1px solid',
          borderColor: 'inherit',
          bg: 'inherit',
          _hover: {
            borderColor: 'gray03',
          },
          _readOnly: {
            backgroundColor: 'background.tertiary',
            borderColor: 'background.tertiary',
            userSelect: 'none',
            borderWidth: '0px !important',
          },
          _disabled: {
            backgroundColor: 'background.tertiary',
            cursor: 'not-allowed',
            borderColor: 'background.tertiary',
          },
          _invalid: {
            borderColor: '#FF5555',
            boxShadow: 'none',
          },
          _focusVisible: {
            zIndex: 1,
            borderColor: 'gray03',
            boxShadow: `0 0 0 1px gray03`,
            borderWidth: '1.5px',
          },
          _placeholder: {
            color: 'gray02',
          },
        },
      },
      defaultProps: {
        variant: 'outline',
      },
    },
    Modal: {
      baseStyle: {
        dialog: { borderRadius: '20px' },
      },
      sizes: {
        'md': {
          dialog: {
            maxW: '400px',
            maxH: '277px',
          }
        },
        '6xl': {
          dialog: {
            maxW: '1110px',
          }
        },
        '4xl': {
          dialog: {
            maxW: '840px',
            maxH: '740px',
            // overflow: 'scroll'
          }
        }
      }
    },
    Checkbox: {
      baseStyle: {
        control: {
          border: '1px solid',
          borderRadius: '5px',
          _checked: {
            bg: 'BloctoBlue.Light02',
            borderColor: 'BloctoBlue.Light02',
          },
          _readOnly: {
            bg: 'gray04',
            borderColor: 'gray04',
            userSelect: 'none',
            _checked: {
              bg: 'BloctoBlue.Light02',
              borderColor: 'BloctoBlue.Light02',
              '& ~ span': {
                color: 'inherit',
              },
            },
            '& ~ span': {
              color: 'gray01',
            },
          },
        },
      },
      sizes: {
        md: {
          control: { w: '20px', h: '20px' },
          label: { fontSize: '14px', fontWeight: '500', ml: '10px' },
        },
      },
    },
    Button: {
      variants: {
        primary: {
          fontSize: "size.heading.5",
          fontWeight: "weight.l",
          lineHeight: "line.height.heading.4",
          bg: "interaction.primary",
          color: "font.inverse",
          transition: 'all 0.2s ease-in-out',
          borderRadius: 100,
          _hover: {
            bg: { md: "interaction.primary.hovered" },
            transform: "scale(0.98)",
            _disabled: { bg: "interaction.primary.disabled" },
          },
          _active: {
            bg: "interaction.primary.pressed",
            transform: "scale(0.96)",
          },
          _focus: {
            borderColor: 'transparent',
            boxShadow: 'none',
          },
          _disabled: {
            bg: "interaction.primary.disabled",
            cursor: "not-allowed",
          }
        },
        secondary: {
          background: 'interaction.secondary',
          backgroundColor: 'interaction.secondary',
          transition: 'all 0.2s ease-in-out',
          py: "space.m",
          fontSize: "size.heading.5",
          fontWeight: "weight.m",
          lineHeight: "line.height.heading.4",
          color: "font.primary",
          borderRadius: 100,
          _hover: {
            bg: { md: "interaction.secondary.hovered" },
            transform: "scale(0.98)",
          },
          _active: {
            bg: "interaction.secondary.pressed",
            transform: "scale(0.96)",
          },
          _disabled: {
            bg: "interaction.secondary.disabled",
            cursor: "not-allowed",
          },
          _focus: {
            borderColor: 'transparent',
          },
        },
        outline: {
          background: 'transparent',
          backgroundColor: 'transparent',
          borderColor: 'font.highlight',
          color: 'font.highlight',
          transition: 'all 0.2s ease-in-out',
          borderRadius: 100,
          _hover: {
            backgroundColor: 'transparent',
            opacity: 0.8,
            transform: 'scale(0.98)',
          },
          _active: {
            backgroundColor: 'transparent',
            opacity: 0.9,
          },
          _focus: {
            backgroundColor: 'transparent',
          },
        },
        square: {
          background: 'interaction.inverse',
          backgroundColor: 'interaction.inverse',
          borderColor: 'font.highlight',
          color: 'font.highlight',
          transition: 'all 0.2s ease-in-out',
          borderRadius: 0,
          _hover: {
            backgroundColor: 'transparent',
            opacity: 0.8,
            transform: 'scale(0.98)',
          },
          _active: {
            backgroundColor: 'transparent',
            opacity: 0.9,
          },
          _focus: {
            backgroundColor: 'transparent',
          },
        },
        icon: {
          fontSize: "size.heading.5",
          fontWeight: "weight.l",
          lineHeight: "line.height.heading.4",
          bg: "background.tertiary",
          color: "font.inverse",
          transition: 'all 0.2s ease-in-out',
          borderRadius: 100,
          _hover: {
            bg: "background.tertiary",
            transform: "scale(0.98)",
            _disabled: { bg: "interaction.primary.disabled" },
          },
          _active: {
            bg: "background.tertiary",
            transform: "scale(0.96)",
          },
          _focus: {
            borderColor: 'transparent',
            boxShadow: 'none',
          },
          _disabled: {
            bg: "background.tertiary",
            cursor: "not-allowed",
          }
        },
      },
      sizes: {
        sm: { fontSize: '14px', px: '20px', py: '10px' },
        lg: { fontSize: '16px', px: '32px', py: '14px' },
      },
      defaultProps: {
        variant: 'primary',
        size: 'lg',
      },
      baseStyle: {
        _disabled: {
          pointerEvents: 'none',
        },
      },
    },
    Tooltip: {
      baseStyle: {
        borderRadius: '8px',
        fontSize: '12px',
      },
    },
    Alert: {
      variants: {
        BloctoBlue: {
          container: {
            background: 'BloctoBlue.Light01',
            color: 'white',
            borderRadius: '8px',
            px: '15px',
            py: '14px',
            boxShadow: '0px 0px 25px rgba(0, 0, 0, 0.08)',
          },
          title: {
            fontWeight: '500',
          },
        },
      },
    },
    Menu: {
      baseStyle: {
        list: {
          minWidth: '100px',
          border: 'none',
          py: '0',
          boxShadow: '0 0 20px rgba(0, 0, 0, 0.05)',
          borderRadius: '12px',
          overflow: 'hidden'
        },
        item: {
          px: '20px',
          minHeight: '62px',
          transition: 'all 0.2s ease-in-out',
          _hover: {
            transform: 'scale(0.98)',
            background: 'none',
          },
          _focus: {
            background: 'none',
          },
          WebkitTapHighlightColor: 'transparent',
        },
        groupTitle: {
          fontSize: '16px',
          m: '0',
          p: '20px 20px 12px',
        },
      },
      variants: {
        header: {
          item: {
            minHeight: '40px',
            fontSize: '12px',
          },
        },
      },
    },
  },
  breakpoints,
}))

export default theme
