import { FC, useState } from 'react'
import { useFormik } from 'formik'
import { Container } from 'components/Sidebar'
import {
  ProjectForm,
  ProjectFormType,
  validationSchema,
  initialFormValue,
} from 'components/ProjectForm'
import PageTitle from 'components/PageTitle'
import SubmitModal from 'components/SubmitModal'

const DAppPage: FC<unknown> = () => {
  const [openSubmitModal, setOpenSubmitModal] = useState(false)
  const formik = useFormik({
    initialValues: initialFormValue as FormDataProps,
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      setOpenSubmitModal(true)
    },
  })

  return (
    <Container>
      <PageTitle
        title="New Project"
        subtitle="You have to register to integrate with Blocto solutions."
      />
      <ProjectForm formik={formik} type={ProjectFormType.draft} />

      <SubmitModal
        isOpen={openSubmitModal}
        onClose={() => {
          setOpenSubmitModal(false)
        }}
        formik={formik}
      />
    </Container>
  )
}

export default DAppPage
