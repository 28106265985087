import { Flex, Box } from '@chakra-ui/react'
import { ReactComponent as EmptyImg } from 'assets/empty_img.svg'

interface EmptyProps {
  text?: string
}
const Empty = ({ text = 'project' }: EmptyProps) => {
  return (
    <Flex flexDirection="column" alignItems="center" color="gray01" mt="20vh">
      <EmptyImg />
      <Box textAlign="center">
        You don't have any {text} yet.
        <br /> Create a new {text} now!
      </Box>
    </Flex>
  )
}

export default Empty
