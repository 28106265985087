import { FC, useState } from 'react'
import {
  Button,
  Modal,
  Text,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Flex,
  Box,
  Collapse,
  Checkbox,
  Center,
} from '@chakra-ui/react'
import { FormSecTitle } from 'components/form'
import ContractForm from './ContractForm'
import { CollectionFormType } from './index'
import { ReactComponent as PassedIcon } from 'assets/icons/passedBlue.svg'
import { ReactComponent as NotPassedIcon } from 'assets/icons/notpassed.svg'
import { saveDraft } from 'utils'
interface ContractModalProps {
  isOpen: boolean
  onClose: () => void
  formik: any
  type: CollectionFormType
  collectionId?: string
  syncContractState?: () => void
}
const ContractModal: FC<ContractModalProps> = ({
  isOpen,
  onClose,
  formik,
  type,
  collectionId,
  syncContractState = () => {},
}) => {
  const [collapse, setCollapse] = useState(true)
  const [submitIsDisabled, setSubmitIsDisabled] = useState(true)

  const draftToStorage = async () => {
    await saveDraft([formik.values], collectionId, 'collection_contract')
    syncContractState()
    onClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          bg="background.tertiary"
          borderRadius="12px"
          color="font.primary"
          p="32px"
        >
          Contract info
          <Text color="font.primary" fontWeight={400} fontSize="14px">
            After adding a contract, it cannot be deleted.
          </Text>
        </ModalHeader>
        <ModalCloseButton top="32px" right="32px" />
        <ModalBody
          bg="background.tertiary"
          overflow="scroll"
          mt="0"
          pt="0"
          px="32px"
        >
          <FormSecTitle
            onClick={() => setCollapse((collapse) => !collapse)}
            isOpen={collapse}
          >
            <Flex alignItems="center">
              Contract
              {type !== CollectionFormType.readonly && (
                <Box ml="11px">
                  {formik.values?.base64logo ? (
                    <PassedIcon />
                  ) : (
                    <NotPassedIcon />
                  )}
                </Box>
              )}
            </Flex>
          </FormSecTitle>
          <Collapse in={collapse}>
            <Box
              bg="white"
              p="30px 40px"
              borderRadius="0px 0px 12px 12px"
              borderTop="1px solid #E1E4E8"
            >
              <ContractForm
                formik={formik}
                type={type}
                collectionId={collectionId}
                setSubmitIsDisabled={setSubmitIsDisabled}
              />
            </Box>
          </Collapse>
          <Center mt="32px" p="0 70px">
            <Checkbox
              name="confirm_contract"
              isChecked={formik.values?.confirm_contract}
              onChange={(event) =>
                formik.setFieldValue('confirm_contract', event.target.checked)
              }
              value={formik.values?.confirm_contract}
            >
              Please review the metadata details above and the NFT received from
              testing. If everything looks good, click "Submit" to add or update
              this contract to the collection.
            </Checkbox>
          </Center>
        </ModalBody>
        <ModalFooter
          bg="background.tertiary"
          borderRadius="12px"
          justifyContent="center"
          p="32px"
        >
          <Button variant="outline" onClick={draftToStorage} w="200px">
            Local drafts
          </Button>
          <Button
            w="200px"
            ml="24px"
            onClick={formik.handleSubmit}
            isDisabled={submitIsDisabled && !formik.values.confirm_contract}
          >
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ContractModal
