import { makeRequest } from '../request'

const SERVER_URL = process.env.REACT_APP_FAUCET_API
const STAG_AUTH_TOKEN = process.env.REACT_APP_STAG_AUTH_TOKEN
const accessToken = localStorage.getItem('ACCESS_TOKEN') || ''

export const getFaucet = async (payload: faucetParams): Promise<any> => {
  /**
   * In a formal environment, the API needs to specify the location to be sent to
   * whereas in the stag or dev environment, the path does not need to be entered.
   */
  const envPath: string =
    process.env.REACT_APP_FAUCET_API_ENV !== 'prod' ? '' : '/stag'
  return makeRequest(
    `${SERVER_URL}/faucet/bloctoPoint${envPath}`,
    {
      method: 'POST',
      body: JSON.stringify(payload),
    },
    false,
    {
      Authorization: STAG_AUTH_TOKEN || accessToken,
    }
  )
}
