import * as fcl from '@blocto/fcl'
import * as t from '@onflow/types'

// Catalog
export const get_nft_metadata_for_collection_identifier = `
 import NFTCatalog from 0xNFTCatalog
 pub fun main(collectionIdentifier: String): NFTCatalog.NFTCatalogMetadata? {
      return NFTCatalog.getCatalogEntry(collectionIdentifier: collectionIdentifier)
}
`
// Catalog
export async function getNFTMetadataForCollectionIdentifier(
  collectionIdentifier: string
): Promise<any> {
  try {
    const scriptResult = await fcl
      .send([
        fcl.script(get_nft_metadata_for_collection_identifier),
        fcl.args([fcl.arg(collectionIdentifier, t.String)]),
      ])
      .then(fcl.decode)
    return scriptResult
  } catch (e) {
    console.error(e)
    return null
  }
}

export async function handleSendTransactions(
  enableScript: string,
  args?: any[] | undefined
): Promise<any> {
  try {
    const block = await fcl.send([fcl.getBlock()]).then(fcl.decode)
    const params = [
      fcl.args(args),
      fcl.proposer(fcl.currentUser().authorization),
      fcl.payer(fcl.currentUser().authorization),
      fcl.ref(block.id),
      fcl.limit(9999),
    ]
    const authorizations = []
    authorizations.push(fcl.currentUser().authorization)
    params.push(fcl.authorizations(authorizations))
    return fcl
      .send([fcl.transaction(enableScript), ...params])
      .then(({ transactionId }: { transactionId: string }) => {
        const res = {
          transactionId,
          subscribeFunc: fcl.tx({ transactionId }).subscribe,
          isSealed: fcl.tx.isSealed,
        }
        return res
      })
  } catch (e) {
    console.error(e)
    return null
  }
}
