import { Flex, Button, Text } from '@chakra-ui/react'

const stepList = [
  {
    title: 'Collection info',
  },
  {
    title: 'Contract',
  },
  {
    title: 'Listing info',
  },
]

const StepButtonGroup = ({
  step,
  setStep,
  isDisabledIndex,
}: {
  step: number
  setStep: (step: number) => void
  isDisabledIndex: number
}) => {
  const buttonHandler = (idx: number) => {
    if (isDisabledIndex <= idx) return
    setStep(idx)
  }

  return (
    <Flex borderRadius={12} overflow="auto">
      {stepList.map((item, idx) => {
        return (
          <Button
            _active={{
              bgColor: 'interaction.inverse',
            }}
            _focus={{
              bgColor: 'interaction.inverse',
            }}
            w={100 / stepList.length + '%'}
            key={item.title}
            variant="square"
            onClick={() => buttonHandler(idx)}
            color={step === idx ? 'font.highlight' : 'font.tertiary'}
            transition="all 0.2s ease-in-out"
            _after={
              // if not last item, render a vertical line
              idx !== stepList.length - 1
                ? {
                    content: '""',
                    bg: '#E1E4E8',
                    w: '1px',
                    height: '100%',
                    position: 'absolute',
                    right: 0,
                  }
                : {}
            }
            _hover={{
              color: step === idx ? 'font.highlight' : 'font.tertiary',
              '.hover-scale': {
                transform: 'scale(0.98)',
              },
            }}
          >
            <Text
              fontSize="size.heading.5"
              fontWeight="weight.l"
              className="hover-scale"
            >
              {idx + 1}.{item.title}
            </Text>
          </Button>
        )
      })}
    </Flex>
  )
}

export default StepButtonGroup
