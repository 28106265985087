import { useContext, useEffect, useRef } from 'react'
import { Flex, Box, Heading } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import DashboardBg from 'assets/dashboard_bg.png'
import DashboardBg1440 from 'assets/dashboard_bg_1440.png'
import AuthContext from '../context/auth'
import { ReactComponent as IndexIcon1 } from 'assets/index_icon_1.svg'
import { ReactComponent as IndexIcon2 } from 'assets/index_icon_2.svg'
import { ReactComponent as IndexIcon3 } from 'assets/index_icon_3.svg'

const IndexPage = () => {
  const { user } = useContext(AuthContext)
  const navigate = useRef(useNavigate())

  useEffect(() => {
    if (user && user.loggedIn) {
      navigate.current('/dashboard')
    }
  }, [user])

  return (
    <Box
      bgImg={DashboardBg1440}
      backgroundPosition="bottom right"
      backgroundRepeat="no-repeat"
      backgroundSize="contain"
      width="100%"
      height="calc(100vh - 75px)"
      sx={{
        '@media screen and (max-width: 1300px)': {
          backgroundPosition: 'bottom 0 right -200px',
        },
        '@media screen and (max-width: 1125px)': {
          backgroundPosition: 'bottom 0 right -270px',
        },
        '@media screen and (min-width: 1920px)': {
          bgImg: DashboardBg,
          backgroundPosition: 'bottom right',
          backgroundSize: 'auto 100%',
        },
      }}
    >
      <Flex
        width="700px"
        height="100%"
        alignItems="center"
        background="linear-gradient(90deg, rgba(255,255,255,1) 60%,  rgba(255,255,255,0) 100%)"
      >
        <Box ml="105px" maxWidth="495px">
          <Heading variant="h1" mb="25px">
            Blocto for developers
          </Heading>
          <Box mb="45px">
            <Box as="span" color="BloctoBlue.Light02" mr="5px">
              Blocto SDK
            </Box>
            helps developers create wonderful dApps with minimal effort.
            <br />
            Connect your wallet and build your creative projects!
          </Box>
          <Box>
            <Flex gap="15px" my="25px" alignItems="center">
              <IndexIcon1 />
              Manage your projects with an intuitive and friendly dashboard.
            </Flex>
            <Flex gap="15px" my="25px" alignItems="center">
              <IndexIcon2 />
              Convert great ideas to attractive projects with Blocto solutions.
            </Flex>
            <Flex gap="15px" my="25px" alignItems="center">
              <IndexIcon3 />
              Welcome your users to the web3 world by the most convenient
              entrance - Blocto.
            </Flex>
          </Box>
        </Box>
      </Flex>
    </Box>
  )
}

export default IndexPage
