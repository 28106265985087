import { useEffect, useState, useContext, useMemo } from 'react'
import {
  Box,
  useToast,
  Flex,
  Heading,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'
import ProjectItem from 'components/ProjectItem'
import { Container } from 'components/Sidebar'
import AuthContext from '../context/auth'
import DropdownButton from 'components/common/DropdownButton'
import Empty from 'components/common/Empty'
import { ProjectItemSkeletonList } from 'components/ProjectItemSkeleton'
import DeleteDraftModal from 'components/DeleteDraftModal'

const SERVER_URL = process.env.REACT_APP_API_BASE

enum filterStates {
  all = 'All',
  active = 'Active',
  draft = 'Local drafts',
}

const ProjectPage = () => {
  const { user } = useContext(AuthContext)
  const [dapps, setDapps] = useState<ActiveProject[]>([])
  const [drafts, setDrafts] = useState<{ [uuid: string]: DraftProject }>(
    JSON.parse(localStorage.getItem('drafts') || '{}')
  )
  const [draftArray, setDraftArray] = useState<DraftProject[]>(
    Object.values(drafts)
  )
  const [loading, setLoading] = useState(true)
  const [filterState, setFilterState] = useState<filterStates>(filterStates.all)
  const toast = useToast()
  const updateDraft = (drafts: { [id: string]: DraftProject }) => {
    setDrafts(drafts)
    setDraftArray(Object.values(drafts))
  }
  const [modalData, setModalData] = useState({
    isOpen: false,
    deleteName: '',
    deleteId: '',
  })

  useEffect(() => {
    const accessToken = localStorage.getItem('ACCESS_TOKEN') || ''
    fetch(`${SERVER_URL}/account/dapps`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: accessToken,
      },
    })
      .then((response) => {
        if (response.ok) return response.json()
        throw new Error(response.status.toString())
      })
      .then((data) => {
        if (data.dapps) setDapps(data.dapps)
        setLoading(false)
      })
      .catch((e: Error) => {
        setLoading(false)
        toast({
          title: `Error: ${e.message}`,
          status: 'error',
          duration: 2000,
        })
      })
  }, [user, toast, setDapps])

  const deleteDraft = (uuid: string) => {
    const newDrafts = drafts
    delete newDrafts[uuid]
    localStorage.setItem('drafts', JSON.stringify(newDrafts))
    updateDraft(newDrafts)
  }

  const displayStatus = useMemo<{
    activeDisplay: boolean
    draftDisplay: boolean
    showEmpty: boolean
  }>(() => {
    switch (filterState) {
      default:
      case filterStates.all:
        return {
          activeDisplay: dapps.length > 0,
          draftDisplay: draftArray.length > 0,
          showEmpty: dapps.length === 0 && draftArray.length === 0,
        }
      case filterStates.active:
        return {
          activeDisplay: dapps.length > 0,
          draftDisplay: false,
          showEmpty: dapps.length === 0,
        }
      case filterStates.draft:
        return {
          activeDisplay: false,
          draftDisplay: draftArray.length > 0,
          showEmpty: draftArray.length === 0,
        }
    }
  }, [filterState, draftArray, dapps])

  return (
    <Container>
      <Box>
        <Flex justify="space-between" align="center" mb="9px">
          <Heading variant="h3">All Project</Heading>
          <Button as={RouterLink} variant="outline" to="/new">
            Add Project
          </Button>
        </Flex>
        <Box mb="40px">Add and manage your projects here.</Box>
        <Flex>
          <Menu offset={[0, 15]} autoSelect={false}>
            <MenuButton as={DropdownButton} variant="filter" minWidth="155px">
              {filterState}
            </MenuButton>
            <MenuList minWidth="155px">
              <MenuItem
                onClick={() => {
                  setFilterState(filterStates.all)
                }}
              >
                All
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setFilterState(filterStates.active)
                }}
              >
                Active
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setFilterState(filterStates.draft)
                }}
              >
                Local drafts
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Box>
      <Box mt="30px">
        {loading && <ProjectItemSkeletonList numbers={6} />}

        {displayStatus.activeDisplay && (
          <Flex flexDirection="column">
            {dapps.map((dapp) => (
              <ProjectItem
                key={dapp.id}
                appId={dapp.id}
                logo={dapp.logo}
                name={dapp.name}
                type={dapp.web?.type}
              />
            ))}
          </Flex>
        )}

        {displayStatus.draftDisplay && (
          <Flex flexDirection="column">
            {draftArray.map((dapp) => (
              <ProjectItem
                key={dapp.uuid}
                appId={dapp.uuid}
                logo={dapp.logo}
                name={dapp.name}
                type={dapp.web?.type}
                isDraft={true}
                onDeleteDraft={() => {
                  setModalData({
                    isOpen: true,
                    deleteId: dapp.uuid,
                    deleteName: dapp.name,
                  })
                }}
              />
            ))}
          </Flex>
        )}

        {displayStatus.showEmpty && <Empty text="project" />}
      </Box>

      <DeleteDraftModal
        modalData={modalData}
        onClose={() => {
          setModalData((prev) => ({ ...prev, isOpen: false }))
        }}
        deleteDraft={deleteDraft}
      />
    </Container>
  )
}
export default ProjectPage
