import { FC } from 'react'
import { Flex } from '@chakra-ui/react'
import { Container } from 'components/Sidebar'
import FaucetForm from 'components/FaucetForm'
import PageTitle from 'components/PageTitle'

const TitleEnv: { [key: string]: string } = {
  prod: 'Staging',
  staging: 'Dev',
  dev: 'Dev',
}
const TITLE_DISPLAY_NAME: string =
  TitleEnv[process.env.REACT_APP_FAUCET_API_ENV || 'dev']

const FaucetPage: FC = () => {
  return (
    <Container>
      <Flex>
        <PageTitle
          title={`[${TITLE_DISPLAY_NAME}] Blocto points faucet`}
          subtitle={`If you need Blocto points for testnet, please submit your ${TITLE_DISPLAY_NAME.toLowerCase()} email address in the following column.`}
        />
      </Flex>
      <FaucetForm envTitle={TITLE_DISPLAY_NAME} />
    </Container>
  )
}

export default FaucetPage
