import { Flex, Heading, Box } from '@chakra-ui/react'

const PageTitle = ({
  title,
  subtitle,
  buttons,
  icon,
}: {
  title: string
  subtitle: string
  buttons?: React.ReactNode
  icon?: React.ReactNode
}) => (
  <Box>
    <Flex justify="space-between" align="center" mb="9px">
      <Flex alignItems="center">
        {icon && <Box mr="10px">{icon}</Box>}
        <Heading variant="h3">{title}</Heading>
      </Flex>
      {buttons}
    </Flex>
    <Box mb="40px">{subtitle}</Box>
  </Box>
)

export default PageTitle
