import { Center, Heading, Text, Button } from '@chakra-ui/react'
import { ReactComponent as MobileImg } from '../assets/mobile.svg'

const Mobile = () => {
  return (
    <Center height="100vh" background="#F9F9F9">
      <Center width="325px" mx="10px" flexDirection="column">
        <MobileImg />
        <Heading
          variant="h1"
          color="BloctoBlue.Light02"
          textAlign="center"
          mt="34px"
        >
          Blocto’s developer dashboard
        </Heading>
        <Text color="gray01" textAlign="center" my="20px">
          Please login via PC/Laptop to view the full dashboard and access its
          functionalities.
        </Text>
        <Button
          as="a"
          href="https://portto.com"
          variant="primary"
          size="lg"
          mt="30px"
        >
          Back portto
        </Button>
      </Center>
    </Center>
  )
}

export default Mobile
