import { FC, useCallback, useState } from 'react'
import { useDropzone, FileRejection } from 'react-dropzone'
import { Center, Box, Image, Button, useDisclosure } from '@chakra-ui/react'
import CropModal from './CropModal'
import { isEmpty } from 'plugins/lodash'
import { dataURLtoFile, getBase64Image } from 'utils'
import { ReactComponent as ImageIcon } from 'assets/icons/Image_icon.svg'
import { ReactComponent as TextErrorIcon } from 'assets/icons/text_error.svg'
import DefaultIcon from 'assets/default_icon.jpg'

export interface PreviewFile extends File {
  preview: string
}

export enum FileType {
  CREATE = 'create',
  EDIT = 'edit',
  CROPPED = 'cropped',
}

interface FileUploadProps {
  onFileAccepted: (acceptedFile: string[]) => void
  logo: string
  isReadOnly?: boolean
  circularCrop?: boolean
  size?: string
  clearFile: () => void
}

const FileUpload: FC<FileUploadProps> = ({
  onFileAccepted,
  logo,
  isReadOnly,
  circularCrop = false,
  size = '512*512',
  clearFile,
}) => {
  const [files, setFiles] = useState<PreviewFile[]>([])
  const [error, setError] = useState<string>('')
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [fileType, setFileType] = useState<FileType>(FileType.CREATE)

  const onDrop = useCallback(
    (acceptedFiles: any, rejectedFiles: FileRejection[]) => {
      setFiles(
        acceptedFiles.map((file: File) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      )
      if (rejectedFiles[0]?.errors[0]?.code === 'file-invalid-type') {
        setError('Invalid file format.')
        return
      }
      if (rejectedFiles[0]?.errors[0]?.code === 'file-too-large') {
        setError('File size too large. Max size is 1MB.')
        return
      }
      onOpen()
      setError('')
    },
    [onOpen]
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/jpg': [],
      'image/jpeg': [],
      'image/png': [],
      'image/svg': [],
    },
    maxFiles: 1,
    maxSize: 1048576,
    multiple: false,
  })

  const deleteFile = (e: React.MouseEvent) => {
    e.stopPropagation()
    setFiles([])
    clearFile()
    setFileType(FileType.CREATE)
  }

  const editHandler = async (e: React.MouseEvent) => {
    e.stopPropagation()
    setFileType(FileType.EDIT)
    if (isEmpty(files) && !isEmpty(logo)) {
      const base64Logo = await getBase64Image(logo)
      const file = dataURLtoFile(base64Logo, 'logo.png')
      const fileObj = Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
      setFiles([fileObj])
    }

    onOpen()
  }

  const dropText = isDragActive
    ? 'Drop your project logo here, or click to upload files.'
    : 'Drag & drop your project logo here, or click to upload files.'

  return isReadOnly ? (
    <Center
      p="8px"
      minHeight="205px"
      bg="gray05"
      flexDirection="column"
      borderRadius={4}
    >
      <Box boxSize="188px" w="auto">
        <Image
          src={
            files.length > 0
              ? files[0].preview
              : Array.isArray(logo)
              ? logo[1]
              : logo
          }
          alt="logo"
          fallbackSrc={DefaultIcon}
          objectFit="contain"
          width="100%"
          height="100%"
        />
      </Box>
    </Center>
  ) : (
    <>
      <Center
        p="8px"
        minHeight="205px"
        cursor="pointer"
        bg="background.primary"
        flexDirection="column"
        _hover={{ bg: 'interaction.secondary.hovered' }}
        transition="background-color 0.2s ease"
        borderRadius={12}
        border="1px dashed"
        borderColor="gray02"
        position="relative"
        {...getRootProps()}
      >
        {files.length > 0 || !!logo ? (
          <>
            <Box h="182px">
              <Image
                src={Array.isArray(logo) && logo.length > 0 ? logo[1] : logo}
                alt="logo"
                objectFit="contain"
                width="100%"
                height="100%"
                borderRadius={circularCrop ? '50%' : ''}
                fallbackSrc={DefaultIcon}
              />
            </Box>
            <Box
              position="absolute"
              top={0}
              left={0}
              width="100%"
              height="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="row"
              background="rgba(35, 37, 40, 0.3)"
              borderRadius={12}
              opacity={0}
              _groupHover={{ opacity: 1 }}
              zIndex={1}
            >
              <Button
                mt="15px"
                size="sm"
                variant="secondary"
                mr="16px"
                color="font.primary"
                onClick={(e: React.MouseEvent) => editHandler(e)}
              >
                Edit
              </Button>
              <Button
                mt="15px"
                size="sm"
                variant="secondary"
                color="font.primary"
                onClick={(e: React.MouseEvent) => deleteFile(e)}
              >
                Delete
              </Button>
            </Box>
          </>
        ) : (
          <>
            <input {...getInputProps()} />
            <Box w="32px" h="32px">
              <ImageIcon />
            </Box>
            <Box mt="15px" fontSize="12px" color="gray02">
              {dropText}
            </Box>
            <Button mt="15px" size="sm" variant="secondary">
              Upload
            </Button>
          </>
        )}
      </Center>
      <CropModal
        isOpen={isOpen}
        onClose={onClose}
        imgSrc={files[0]?.preview}
        circularCrop={circularCrop}
        size={size}
        files={files}
        setFiles={setFiles}
        clearFile={clearFile}
        onFileAccepted={onFileAccepted}
        fileType={fileType}
        setFileType={setFileType}
      />
      {error && (
        <Box
          display="flex"
          alignItems="center"
          mt="15px"
          fontSize="14px"
          color="#FF5555"
          fontWeight="500"
        >
          <Box as="span" mx="9px">
            <TextErrorIcon />
          </Box>
          {error}
        </Box>
      )}
    </>
  )
}

export default FileUpload
