import { makeRequest } from '../request'

const SERVER_URL = process.env.REACT_APP_API_BASE
const GRAFFLE_API_KEY = process.env.REACT_APP_GRAFFLE_API_KEY

export const getCollection = async (): Promise<any> => {
  return makeRequest(`${SERVER_URL}/account/collections`, {
    method: 'GET',
  })
}

export const getCollectionById = async (id: string): Promise<any> => {
  return makeRequest(`${SERVER_URL}/account/collections/${id}`, {
    method: 'GET',
  })
}

export const setCollection = async (
  payload: Collection,
  type = 'update'
): Promise<any> => {
  const method = type === 'update' ? 'PUT' : 'POST'
  return makeRequest(`${SERVER_URL}/account/collections`, {
    method,
    body: JSON.stringify(payload),
  })
}

export const getGraffleData = async ({
  owner,
  collectionName,
}: {
  owner: string
  collectionName: string
}): Promise<any> => {
  if (!GRAFFLE_API_KEY) throw new Error('No Graffle API Key')
  // doc: https://portal.graffle.io/api-details#api=62e96fa47a8f769b3c152232&operation=62e96fac1a382847c547d328
  const graffleDomain = 'https://api.graffle.io/metadata/api'
  return makeRequest(
    `${graffleDomain}/GetNFTs?owner=${owner}&collectionName=${collectionName}&withMetadata=true`,
    {
      method: 'GET',
      headers: {
        'Graffle-API-Key': GRAFFLE_API_KEY,
      },
    },
    false
  )
}

export const getScript = async (
  collectionID: string,
  params: CollectonContractFormProps
): Promise<any> => {
  return makeRequest(
    `${SERVER_URL}/account/collections/${collectionID}/getScripts`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    }
  )
}

export const deleteCollection = async (collectionID: string): Promise<any> => {
  return makeRequest(`${SERVER_URL}/account/collections/${collectionID}`, {
    method: 'DELETE',
  })
}

export const updateCollection = async (
  collectionID: string,
  payload: Collection
) => {
  return makeRequest(`${SERVER_URL}/account/collections/${collectionID}`, {
    method: 'PUT',
    body: JSON.stringify(payload),
  })
}

export const submitCollection = async (
  collectionID: string,
  payload: Collection
) => {
  return makeRequest(
    `${SERVER_URL}/account/collections/${collectionID}/submit`,
    {
      method: 'POST',
      body: JSON.stringify(payload),
    }
  )
}

export const createContract = async (
  collectionID: string,
  payload: any
): Promise<any> => {
  return makeRequest(
    `${SERVER_URL}/account/collections/${collectionID}/contracts`,
    {
      method: 'POST',
      body: JSON.stringify(payload),
    }
  )
}

export const disableContractBySequence = async (
  collectionId: string,
  sequence: number
) => {
  return makeRequest(
    `${SERVER_URL}/account/collections/${collectionId}/contracts/${sequence}/disable`,
    {
      method: 'POST',
    }
  )
}

export const enableContractBySequence = async (
  collectionId: string,
  sequence: number
) => {
  return makeRequest(
    `${SERVER_URL}/account/collections/${collectionId}/contracts/${sequence}/disable`,
    {
      method: 'POST',
    }
  )
}
