import { Flex, Skeleton, SkeletonProps } from '@chakra-ui/react'

const StyledSkeleton = (props: SkeletonProps) => (
  <Skeleton
    height="25px"
    borderRadius="5px"
    startColor="gray03"
    endColor="gray04"
    {...props}
  />
)

export const ProjectItemSkeleton = () => (
  <Flex
    mb="20px"
    borderRadius="12px"
    backgroundColor="white"
    height="110px"
    justify="space-between"
  >
    <Flex>
      <StyledSkeleton width="110px" height="110px" borderRadius="8px" />
      <Flex ml="20px" flexDirection="column" justifyContent="center">
        <StyledSkeleton width="266px" />
        <StyledSkeleton width="120px" mt="5px" />
      </Flex>
    </Flex>
    <Flex justifyContent="center" alignItems="center" mr="46px">
      <StyledSkeleton width="111px" />
    </Flex>
  </Flex>
)

export const ProjectItemSkeletonList = ({ numbers }: { numbers: number }) => (
  <Flex flexDirection="column" display="absolute">
    {[...Array(numbers)].map((_, index) => (
      <ProjectItemSkeleton key={index} />
    ))}
  </Flex>
)
