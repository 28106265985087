import { Center, Box } from '@chakra-ui/react'
import { ReactComponent as MaintainIcon } from 'assets/Image_maintenance_Bay.svg'

const MaintainPage = () => (
  <Center width="100%" height="calc(100vh - 76px)" flexDirection="column">
    <MaintainIcon />
    <Box color="BloctoBlue.Light02" mt="16px" fontSize="28px" fontWeight="600">
      We’re under maintenance.
    </Box>
    <Box color="gray01" mt="25px" maxWidth="600px" textAlign="center">
      Our website is currently undergoing maintenance. We will be back soon.
      Thank you for your patience.
    </Box>
  </Center>
)
export default MaintainPage
