import { Flex, Box, Button } from '@chakra-ui/react'
import { Container } from 'components/Sidebar'

const Dashboard = () => {
  return (
    <Container>
      <Flex
        w="100%"
        minH="267px"
        p="30px"
        backgroundColor="white"
        borderRadius="12px"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Box>
          <Box fontSize="20px" fontWeight="600" lineHeight="26px">
            Welcome to the Blocto ecosystem!
          </Box>
          <Box mt="10px">
            Blocto’s cross-device SDK solutions and seamless UI/UX experiences
            are just too friendly for devs/projects! Here are some integration
            insights, resources and technical documentations which will guide
            you through a rosy web3 future! Let’s get it!
          </Box>
        </Box>

        <Button
          as="a"
          href="https://docs.blocto.app/"
          target="_blank"
          rel="noopener noreferrer"
          justifySelf="flex-end"
          mt="20px"
        >
          Learn more
        </Button>
      </Flex>
    </Container>
  )
}

export default Dashboard
