import { FC, useState, ReactNode, useRef } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  Text,
  Flex,
  ModalCloseButton,
  keyframes,
  Box,
} from '@chakra-ui/react'
import { isFunction } from 'plugins/lodash'
import { ReactComponent as LoadingIcon } from 'assets/modal_loading_new.svg'
import { ReactComponent as SuccessIcon } from 'assets/icons/Modal_success.svg'
import { ReactComponent as ErrorIcon } from 'assets/icons/Modal_Error.svg'

type Status = 'success' | 'loading' | 'error'

const spin = keyframes`
  from {transform: rotate(0deg);}
  to {transform: rotate(360deg)}`
const spinAnimation = `${spin} infinite 2s linear`

const useStatusModal = (): [
  (status: Status, message?: string, confirmCallback?: () => void) => void,
  FC,
  () => void
] => {
  const [isOpen, setIsOpen] = useState(false)
  const [status, setStatus] = useState<Status | undefined>()
  const [message, setMessage] = useState('')

  const onCloseRef = useRef<() => void>()
  // Define the callback function to be executed when the modal is closed
  const onClose = () => {
    if (isFunction(onCloseRef.current)) onCloseRef.current()
    setIsOpen(false)
    setStatus(undefined)
    setMessage('')
    onCloseRef.current = undefined
  }

  // message not required
  const openModal = (
    status: Status,
    message?: string,
    confirmCallback?: () => void
  ) => {
    setIsOpen(true)
    setStatus(status)
    setMessage(message ?? 'loading')
    onCloseRef.current = confirmCallback
  }

  let icon: ReactNode
  switch (status) {
    case 'success':
      icon = <SuccessIcon />
      break
    case 'loading':
      icon = (
        <Box animation={spinAnimation} w="56px" h="56px">
          <LoadingIcon />
        </Box>
      )
      break
    default:
    case 'error':
      icon = <ErrorIcon />
      break
  }

  const DisplayTitle: Record<Status, string> = {
    success: 'Success',
    loading: 'Loading...',
    error: 'Failed',
  }

  const DefaultMessage: Record<Status, string> = {
    success: 'Your collection has been created successfully.',
    loading: 'Please wait for a moment.',
    error: 'Something went wrong. Please try again.',
  }

  const ModalComponent = () => (
    <Modal isOpen={isOpen} onClose={() => {}} size="sm">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <ModalCloseButton
            onClick={status !== 'loading' ? onClose : () => {}}
          />
        </ModalHeader>
        <ModalBody>
          <Flex direction="column" align="center" justify="center">
            <Flex align="center" w="56px">
              {icon}
            </Flex>
            <Text fontSize="20px" fontWeight={500} m="20px 0">
              {status && DisplayTitle[status]}
            </Text>
            <Text
              fontSize="14px"
              fontWeight={400}
              mb={status === 'loading' ? '12px' : '0'}
              textAlign="center"
            >
              {!message ? DefaultMessage[status || 'loading'] : message}
            </Text>
          </Flex>
        </ModalBody>
        {status !== 'loading' && (
          <ModalFooter>
            <Button w="100%" borderRadius={12} onClick={onClose}>
              Check
            </Button>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  )
  return [openModal, ModalComponent, onClose]
}

export default useStatusModal
