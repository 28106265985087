import { FC } from 'react'
import {
  Box,
  Center,
  Image,
  Button,
  Flex,
  Modal,
  Text,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/react'
import { PreviewModalType } from './ProjectInfo'
import PreviewApp_Bg from 'assets/preview_app_02.png'
import PreviewApp_ItemBg from 'assets/preview_app_03.png'
import PreviewAppCard_Bg from 'assets/preview_app_01.png'
import PreviewWeb_SideBar1 from 'assets/preview_filter_01.png'
import PreviewWeb_SideBar2 from 'assets/preview_filter_02.png'
import PreviewWeb_ContentBg from 'assets/preview_web_01.jpg'
import PreviewWeb_ContentBg2 from 'assets/preview_web_02.jpg'
import { ReactComponent as FlowGrayIcon } from 'assets/icons/flow_token_gray.svg'
import { ReactComponent as SocialIcon } from 'assets/icons/social_group.svg'

interface PreviewModalProps {
  isOpen: boolean
  onClose: () => void
  previewModalType: PreviewModalType
  formik: any
}

const PreviewModal: FC<PreviewModalProps> = ({
  isOpen,
  onClose,
  previewModalType,
  formik,
}) => {
  const getImageSrc = (imageData: string | string[]) => {
    if (Array.isArray(imageData)) {
      return imageData[1]
    }
    return imageData
  }

  const squared_logo_src = getImageSrc(formik.values?.base64squared_logo || '')
  const banner_image_src = getImageSrc(formik.values?.base64banner_image || '')
  const store_banner_src = getImageSrc(formik.values?.base64store_banner || '')
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={previewModalType === PreviewModalType.APP ? '2xl' : '6xl'}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          bg="background.tertiary"
          borderRadius="12px"
          color="font.primary"
          p="32px 32px 20px 32px"
        >
          Preview {previewModalType}
        </ModalHeader>
        <ModalCloseButton top="32px" right="32px" />
        <ModalBody bg="background.tertiary" pt="26px" px="32px">
          {previewModalType === PreviewModalType.APP ? (
            <Flex justifyContent="space-between">
              <Flex position="relative">
                <Image src={PreviewApp_Bg} w="293px" />
                <Box
                  bgImg={banner_image_src}
                  w="268px"
                  position="absolute"
                  left="13px"
                  top="11px"
                  h="253px"
                  borderRadius="35px 35px 0 0"
                  backgroundSize="cover"
                  backgroundRepeat="no-repeat"
                  filter="blur(2px)"
                  backgroundPosition="center"
                />
                <Flex
                  justifyContent="center"
                  position="absolute"
                  left="13px"
                  top="67px"
                  w="268px"
                  flexDirection="column"
                  alignItems="center"
                >
                  <Flex w="78px" wrap="wrap">
                    <Image borderRadius="50%" src={squared_logo_src} w="100%" />
                    <Center w="100%" mt="15px" mb="4px">
                      <Text
                        mr="6px"
                        fontWeight={600}
                        lineHeight="22px"
                        color="font.inverse"
                      >
                        {formik.values?.name}
                      </Text>
                      <FlowGrayIcon />
                    </Center>
                  </Flex>
                  <Flex
                    fontWeight={400}
                    lineHeight="15px"
                    color="background.primary"
                  >
                    {formik.values?.description}
                  </Flex>
                </Flex>
                <Image
                  src={PreviewApp_ItemBg}
                  w="268px"
                  position="absolute"
                  top="0"
                  left="13px"
                />
              </Flex>
              <Flex position="relative">
                <Image src={PreviewAppCard_Bg} w="293px" />
                <Box
                  bgImage={banner_image_src}
                  bgSize="100%"
                  bgRepeat="no-repeat"
                  w="238px"
                  h="101px"
                  position="absolute"
                  top="227px"
                  left="27px"
                  borderRadius="10px"
                  textAlign="center"
                  right="0"
                >
                  <Box
                    w="31px"
                    borderRadius="50px"
                    color="font.inverse"
                    border="0.8px solid #FFFFFF"
                    bg=" rgba(119, 122, 125, 0.5)"
                    position="absolute"
                    top="7px"
                    right="10px"
                  >
                    10
                  </Box>
                  <Flex
                    alignItems="center"
                    position="absolute"
                    bottom="0px"
                    w="100%"
                  >
                    <Text
                      as="span"
                      m="0 10px 5px 10px"
                      fontSize="14px"
                      fontWeight={500}
                      lineHeight="22px"
                      color="font.inverse"
                    >
                      {formik.values?.name}
                    </Text>
                    <FlowGrayIcon />
                  </Flex>
                </Box>
              </Flex>
            </Flex>
          ) : (
            <Flex justifyContent="center">
              <Flex w="260px" flexWrap="wrap">
                <Image src={PreviewWeb_SideBar1} w="100%" h="156px" />
                <Flex
                  p="16px 24px"
                  bg="background.primary"
                  w="100%"
                  alignItems="center"
                >
                  <Box
                    w="20px"
                    h="20px"
                    borderRadius="50%"
                    borderColor="border.primary"
                    border="1px solid"
                  />
                  <Image
                    m="0 10px"
                    borderRadius="50%"
                    src={squared_logo_src}
                    w="20px"
                    h="20px"
                  />
                  <Text
                    mr="6px"
                    fontWeight={400}
                    fontSize="16px"
                    color="font.primary"
                    maxW="135px"
                  >
                    {formik.values?.name}
                  </Text>
                </Flex>
                <Image src={PreviewWeb_SideBar2} w="100%" h="260px" />
              </Flex>
              <Flex
                w="776px"
                minH="463px"
                ml="10px"
                position="relative"
                flexWrap="wrap"
              >
                <Image src={PreviewWeb_ContentBg} w="100%" />
                <Box
                  m="0 10px"
                  bgImage={store_banner_src}
                  w="776px"
                  h="215px"
                  position="absolute"
                  top="41px"
                  left="-10px"
                  bgRepeat="no-repeat"
                  bgSize="100%"
                />
                <Box
                  w="776px"
                  h="215px"
                  position="absolute"
                  top="65px"
                  background="linear-gradient(rgba(255, 255, 255, 0) 20%, rgb(255 255 255 / 90% ) 60%, rgb(255, 255, 255) 100%)"
                />
                <Flex
                  flexDirection="column"
                  justifyContent="center"
                  zIndex="1"
                  m="0 auto"
                  position="absolute"
                  top="165px"
                  left="50%"
                  transform="translateX(-50%)"
                  textAlign="center"
                >
                  <Flex justifyContent="center">
                    <Image
                      borderRadius="50%"
                      src={squared_logo_src}
                      w="60px"
                      h="60px"
                    />
                  </Flex>
                  <Text
                    fontWeight={600}
                    fontSize="24px"
                    color="font.primary"
                    textAlign="center"
                  >
                    {formik.values?.name}
                  </Text>
                  <Text
                    fontWeight={400}
                    fontSize="12px"
                    color="font.primary"
                    textAlign="center"
                    mb="12px"
                  >
                    {formik.values?.store_description}
                  </Text>
                  <Center>
                    <SocialIcon />
                  </Center>
                </Flex>
                <Image src={PreviewWeb_ContentBg2} w="100%" />
              </Flex>
            </Flex>
          )}
        </ModalBody>

        <ModalFooter
          bg="background.tertiary"
          borderRadius="12px"
          justifyContent="center"
          pt="20px"
          pb="32px"
        >
          <Button onClick={onClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default PreviewModal
