export const makeRequest = async (
  url: string,
  options: RequestInit,
  needAuth = true,
  customHeaders: HeadersInit = {}
): Promise<any> => {
  const accessToken = localStorage.getItem('ACCESS_TOKEN') || ''
  const headers: HeadersInit = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: needAuth ? accessToken : '',
    ...customHeaders,
  }

  const response = await fetch(url, {
    headers,
    ...options,
  })

  // delete success return 204 status code
  if (response.status === 204) {
    return { success: true }
  }
  if (response.status === 429) {
    const retryAfter = response.headers.get('Retry-After')
    if (retryAfter) {
      return {
        retryAfter,
      }
    }
  }
  if (!response.ok) {
    const { message } = await response.json()
    throw new Error(message ?? 'Network response was not ok')
  }

  return response.json()
}
