import ShortUniqueId from 'short-unique-id'

export const getBase64 = (file: Blob | File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = (error) => reject(error)
  })

export const convertFormToProject = (formData: FormDataProps): dappProject => {
  const payload: dappProject = {
    id: formData.id,
    name: formData.name,
    blockchains: formData.blockchains,
    logo: formData.logoURL,
    app_universal_links: formData.app_universal_links,
    app_package_name: formData.app_package_name,
    web: {
      web_domain: formData.web_domain,
      type: formData.type,
      links: {
        website: formData.website,
        twitter: formData.twitter,
        discord: formData.discord,
        telegram: formData.telegram,
      },
      contact: {
        name: formData.contact_name,
        email: formData.email,
        job_title: formData.job_title,
      },
    },
  }
  return payload
}

export const getBase64Image = (imgUrl: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    let img = new Image()
    img.crossOrigin = 'Anonymous'

    img.onload = () => {
      let canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      if (ctx) {
        canvas.height = img.naturalHeight
        canvas.width = img.naturalWidth
        ctx.drawImage(img, 0, 0)
        const dataURL = canvas.toDataURL()
        resolve(dataURL)
      } else {
        reject(new Error('Failed to get canvas context'))
      }
    }
    img.onerror = reject
    img.src = imgUrl
  })
}

export const dataURLtoFile = (dataurl: string, filename = 'filename') => {
  const arr = dataurl.split(',')
  const mimeMatch = arr[0].match(/:(.*?);/)
  if (!mimeMatch) {
    throw new Error('Invalid MIME type')
  }
  const mime = mimeMatch[1]
  const binStr = atob(arr[1])
  let len = binStr.length
  let binArr = new Uint8Array(len)

  for (let i = 0; i < len; i++) {
    binArr[i] = binStr.charCodeAt(i)
  }

  return new File([binArr.buffer], filename, { type: mime })
}

export const convertProjectToForm = async (
  project: dappProject
): Promise<FormDataProps> => {
  // const logoBase64 = await getBase64Image(project.logo)
  const formData: FormDataProps = {
    uuid: project?.uuid,
    id: project.id,
    name: project.name,
    blockchains: project.blockchains,
    logoURL: project.logo,
    base64logo: project.logo,
    app_universal_links: project.app_universal_links,
    app_package_name: project.app_package_name,
    web_domain: project.web.web_domain,
    type: project.web.type,
    website: project.web.links.website,
    twitter: project.web.links.twitter,
    discord: project.web.links.discord,
    telegram: project.web.links.telegram,
    contact_name: project.web.contact.name,
    email: project.web.contact.email,
    job_title: project.web.contact.job_title,
  }
  return formData
}

export const convertFormToCollection = (
  formData: CollectionFormProps
): Collection => {
  const payload: Collection = {
    id: formData.id,
    name: formData.name,
    description: formData.description,
    social_links: {
      website: formData.social_links.website,
      twitter: formData.social_links.twitter,
      discord: formData.social_links.discord,
      telegram: formData.social_links.telegram,
    },
    squared_logo: formData.squared_logo,
    banner_image: formData.banner_image,
    store_banner: formData.store_banner,
    store_description: formData.store_description,
    app_listing_date: formData.app_listing_date,
    blocto_bay_listing_date: formData.blocto_bay_listing_date,
    blocto_bay_royalty_ratio: formData.blocto_bay_royalty_ratio,
    blocto_bay_royalty_receiver: formData.blocto_bay_royalty_receiver,
  }
  return payload
}

export const convertCollectionToForm = (
  collection: Collection
): CollectionFormProps => {
  const formData: CollectionFormProps = {
    uuid: collection?.uuid,
    id: collection.id,
    name: collection.name,
    description: collection.description,
    social_links: {
      website: collection.social_links.website,
      twitter: collection.social_links.twitter,
      discord: collection.social_links.discord,
      telegram: collection.social_links.telegram,
    },
    squared_logo: collection.squared_logo || '',
    banner_image: collection.banner_image || '',
    store_banner: collection.store_banner || '',
    store_description: collection.store_description,
    app_listing_date: collection.app_listing_date,
    blocto_bay_listing_date: collection.blocto_bay_listing_date,
    blocto_bay_royalty_ratio: collection.blocto_bay_royalty_ratio,
    blocto_bay_royalty_receiver: collection.blocto_bay_royalty_receiver,
  }
  return formData
}

export const convertImageToBase64 = async (imageUrl: string) => {
  if (!imageUrl.startsWith('https://')) {
    throw new Error("Invalid image URL: URL prefix should be 'https://'")
  }
  try {
    const response = await fetch(imageUrl)
    const blob = await response.blob()
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => {
        resolve(reader.result)
      }
      reader.onerror = reject
      reader.readAsDataURL(blob)
    })
  } catch (error) {
    console.error('Error converting image to Base64:', error)
    throw error
  }
}

export const getContentType = async (url: string): Promise<string | null> => {
  try {
    const response = await fetch(url)
    if (response.ok) {
      const contentTypeHeader = response.headers.get('Content-Type')
      if (contentTypeHeader) {
        const contentType = contentTypeHeader.split(';')[0].trim()
        return contentType
      }
    }
    throw new Error('Unable to determine content type.')
  } catch (error) {
    console.error('Error:', error)
    return null
  }
}

export const saveDraft = async (
  payload: dappProject | Collection[],
  uuid?: string,
  draftName = 'project'
) => {
  let draftID = uuid || new ShortUniqueId().randomUUID(6)
  const storageName =
    draftName === 'project' ? 'drafts' : 'collection_contract_drafts'
  let storageDrafts = JSON.parse(localStorage.getItem(storageName) || '{}')
  storageDrafts[draftID] = payload
  storageDrafts[draftID].uuid = draftID
  localStorage.setItem(storageName, JSON.stringify(storageDrafts))
  return draftID
}

export const deleteDraft = (uuid: string, deleteName = 'project') => {
  const storageName =
    deleteName === 'project' ? 'drafts' : 'collection_contract_drafts'
  const newDrafts = JSON.parse(localStorage.getItem(storageName) || '{}')
  delete newDrafts[uuid]
  localStorage.setItem(storageName, JSON.stringify(newDrafts))
}
