import { makeRequest } from '../request'
import { convertFormToProject } from 'utils'
import { uploadImage } from './google'
const SERVER_URL = process.env.REACT_APP_API_BASE

export const getProject = async (id: string) => {
  return makeRequest(`${SERVER_URL}/dapps/${id}`, {
    method: 'GET',
  })
}

export const uploadProject = async (formData: FormDataProps) => {
  try {
    let payload = formData
    if (formData.logoURL !== formData.base64logo) {
      payload.logoURL = await uploadImage(formData.base64logo[1])
    }
    return makeRequest(`${SERVER_URL}/account/dapps`, {
      method: 'POST',
      body: JSON.stringify(convertFormToProject(payload)),
    })
  } catch (error) {
    return error
  }
}
