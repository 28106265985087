import { FC, useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { Box, IconButton } from '@chakra-ui/react'
import { Container } from 'components/Sidebar'
import {
  ProjectForm,
  ProjectFormType,
  validationSchema,
} from 'components/ProjectForm'
import PageTitle from 'components/PageTitle'
import { useParams } from 'react-router-dom'
import { convertProjectToForm } from 'utils'
import DeleteDraftModal from 'components/DeleteDraftModal'
import SubmitModal from 'components/SubmitModal'
import { ReactComponent as EditIcon } from 'assets/icons/Edit_NoBg.svg'
import { ReactComponent as DeleteIcon } from 'assets/icons/Delete_NoBg.svg'

const deleteDraft = (uuid: string) => {
  const newDrafts = JSON.parse(localStorage.getItem('drafts') || '{}')
  delete newDrafts[uuid]
  localStorage.setItem('drafts', JSON.stringify(newDrafts))
  window.location.href = '/projects'
}

const DraftPage: FC<unknown> = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const [editMode, setEditMode] = useState(urlParams.get('type') === 'edit')
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [openSubmitModal, setOpenSubmitModal] = useState(false)
  const params = useParams()
  const { id } = params

  const formik = useFormik({
    initialValues: {} as FormDataProps,
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      setOpenSubmitModal(true)
    },
  })

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        const storageDrafts = JSON.parse(localStorage.getItem('drafts') || '{}')
        const formValues = await convertProjectToForm(storageDrafts[id])
        formik.setValues(formValues)
      }
    }

    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <Container>
      <PageTitle
        title={formik.values.name}
        subtitle="You have to register to integrate with Blocto solutions."
        buttons={
          <Box display="flex">
            {!editMode && (
              <IconButton
                variant="icon"
                size="sm"
                icon={<EditIcon />}
                aria-label="editMode"
                onClick={() => {
                  setEditMode(true)
                }}
              />
            )}
            <IconButton
              aria-label="delete"
              variant="icon"
              size="sm"
              ml="10px"
              icon={<DeleteIcon />}
              onClick={() => {
                setDeleteModalOpen(true)
              }}
            />
          </Box>
        }
      />
      <ProjectForm
        formik={formik}
        type={editMode ? ProjectFormType.draft : ProjectFormType.readonly}
        uuid={id}
      />

      <DeleteDraftModal
        modalData={{
          isOpen: deleteModalOpen,
          deleteName: formik.values.name,
          deleteId: id || '',
        }}
        onClose={() => {
          setDeleteModalOpen(false)
        }}
        deleteDraft={deleteDraft}
      />

      <SubmitModal
        isOpen={openSubmitModal}
        onClose={() => {
          setOpenSubmitModal(false)
        }}
        formik={formik}
      />
    </Container>
  )
}

export default DraftPage
