import { Box, Flex } from '@chakra-ui/react'
import { Link, useLocation } from 'react-router-dom'
import { ReactComponent as DashboardIcon } from 'assets/icons/Dashboard.svg'
import { ReactComponent as ProjectIcon } from 'assets/icons/Projects.svg'
import { ReactComponent as DocsIcon } from 'assets/icons/Docs.svg'
import { ReactComponent as SupportIcon } from 'assets/icons/Support.svg'
import { ReactComponent as BloctoIcon } from 'assets/icons/Blocto.svg'
import { ReactComponent as BookIcon } from 'assets/icons/Book.svg'

const SIDEBAR_WIDTH = '290px'

const SideButton = ({ children, Icon, active, ...props }: any) => (
  <Flex
    m="30px"
    color={active ? 'BloctoBlue.Light02' : 'gray01'}
    transition="all .2s"
    _hover={{ color: 'BloctoBlue.Light03' }}
    cursor="pointer"
    align="center"
    fontWeight={active ? '600' : '500'}
    {...props}
  >
    <Box mr="12px">
      <Icon />
    </Box>
    {children}
  </Flex>
)

export const Sidebar = () => {
  let location = useLocation()
  const sideBarItems = [
    {
      name: 'Home',
      path: '/dashboard',
      icon: DashboardIcon,
    },
    {
      name: 'dApps',
      path: '/projects',
      icon: ProjectIcon,
    },
    {
      name: 'NFT Collection',
      path: '/collection',
      icon: BookIcon,
    },
    {
      name: 'Blocto points faucet',
      path: '/faucet',
      icon: BloctoIcon,
    },
  ]

  return (
    <Flex
      bg="white"
      height="calc(100vh - 76px)"
      width={SIDEBAR_WIDTH}
      position="fixed"
      top="76px"
      left="0"
      zIndex="banner"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Box height="calc(100% - 120px)">
        {sideBarItems.map((item, index) => {
          return (
            <SideButton
              as={Link}
              to={item.path}
              Icon={item.icon}
              active={location.pathname.includes(item.path)}
              key={index}
            >
              {item.name}
            </SideButton>
          )
        })}
      </Box>
      <Box>
        <SideButton
          as="a"
          href="https://docs.blocto.app/"
          Icon={DocsIcon}
          target="_blank"
          rel="noopener noreferrer"
        >
          Docs / Demos
        </SideButton>
        <SideButton
          as="a"
          href="https://discord.com/channels/720454370650619984/720455142805078047"
          Icon={SupportIcon}
          target="_blank"
          rel="noopener noreferrer"
        >
          Support
        </SideButton>
      </Box>
    </Flex>
  )
}

export const Container = ({ children }: React.ComponentProps<typeof Box>) => (
  <Box
    background="gray05"
    width="100%"
    pl={SIDEBAR_WIDTH}
    minHeight="calc(100vh - 76px)"
  >
    <Sidebar />
    <Box p="60px">{children}</Box>
  </Box>
)

export default Container
