import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Button, Flex } from '@chakra-ui/react'
import * as yup from 'yup'
import { useNavigate } from 'react-router-dom'
import ProjectInfo from './ProjectInfo'
import Contract from './Contract'
import ListingInfo from './ListingInfo'
import { uploadImage } from 'api/endpoints/google'
import { setCollection, updateCollection } from 'api/endpoints/collection'
import useStatusModal from 'hooks/useStatusModal'

export enum CollectionFormType {
  draft,
  published,
  readonly,
}

export const initialFormValue = {
  id: '',
  name: '',
  description: '',
  social_links: {
    website: '',
    twitter: '',
    discord: '',
    telegram: '',
  },
  squared_logo: '',
  banner_image: '',
  store_banner: '',
  store_description: '',
  app_listing_date: 0,
  blocto_bay_listing_date: 0,
  blocto_bay_royalty_ratio: '0',
  blocto_bay_royalty_receiver: '',
  base64squared_logo: '',
  base64banner_image: '',
  base64store_banner: '',
  join_discord: false,
  // collection
  contract_state: true,
  contracts: [],
}

const customImageTest = (value: string | [string]) => {
  if (Array.isArray(value)) {
    return value.length > 0
  }
  return typeof value === 'string' && value !== ''
}

export const validationSchema = yup.object({
  name: yup.string().required('This field is required.'),
  base64squared_logo: yup
    .mixed()
    .test('is-valid-input', 'Invalid input', customImageTest)
    .required('This field is required.'),
  base64banner_image: yup
    .mixed()
    .test('is-valid-input', 'Invalid input', customImageTest)
    .required('This field is required.'),

  base64store_banner: yup
    .mixed()
    .test('is-valid-input', 'Invalid input', customImageTest)
    .required('This field is required.'),
  social_links: yup.object().shape({
    website: yup.string().url(),
    twitter: yup.string().url(),
    discord: yup.string().url(),
    telegram: yup.string().url(),
  }),
  app_listing_date: yup.number().required('This field is required.'),
  blocto_bay_listing_date: yup.number().required('This field is required.'),
  blocto_bay_royalty_receiver: yup
    .string()
    .required('This field is required.')
    .matches(/^0x/, 'Incorrect format'),
  blocto_bay_royalty_ratio: yup
    .number()
    .min(0, 'Must be between 0 and 10')
    .max(10, 'Must be between 0 and 10')
    .test('is-decimal', 'Decimal up to 1 place allowed only', (value) =>
      /^(\d+(\.\d{1})?|\d{1,2})$/.test(String(value))
    )
    .nullable()
    .required('This field is required.'),
  join_discord: yup
    .boolean()
    .oneOf([true], 'This field is required')
    .required(),
})

export const validateGroup: {
  [key: number]: string[]
} = {
  1: [
    'name',
    'description',
    'base64squared_logo',
    'base64banner_image',
    'base64store_banner',
    'store_description',
  ],
  2: [
    'contract_address',
    'contract_name',
    'storage_path',
    'public_path',
    'collection_public',
    'graffle_collection_name',
  ],
  3: [
    'app_listing_date',
    'blocto_bay_listing_date',
    'blocto_bay_royalty_receiver',
    'blocto_bay_royalty_ratio',
    'join_discord',
  ],
}

export const CollectionForm = ({
  formik,
  type,
  step,
  setStep,
}: {
  formik: any
  type: CollectionFormType
  step: number
  setStep: (step: number) => void
}) => {
  const navigate = useNavigate()
  const { id: paramsCollectionId } = useParams()
  const { contracts } = formik.values
  const [openSec1, setOpenSec1] = useState<boolean>(true)
  const openSec2Default =
    contracts?.length === 0
      ? [true]
      : contracts.map((contract: ContractProp) => !contract.is_hidden)
  const [openSec2, setOpenSec2] = useState<boolean[]>(openSec2Default)
  const [openSec3, setOpenSec3] = useState<boolean>(true)
  const [collectionId, setCollectionId] = useState<string>('')
  const [openModal, ModalComponent, onCloseModal] = useStatusModal()

  useEffect(() => {
    const el = document.querySelector('[aria-invalid="true"]')
    ;(el?.parentElement ?? el)?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    })
  }, [formik.isSubmitting])

  useEffect(() => {
    window.onbeforeunload = (e) => {
      const dialogText = 'Sure you want to leave?'
      e.returnValue = dialogText
      return dialogText
    }
    return () => {
      window.onbeforeunload = null
    }
  }, [])

  const createCollection = async () => {
    const formData = { ...formik.values }

    const imageFields = [
      {
        fieldName: 'squared_logo',
        base64Field: 'base64squared_logo',
        imageField: 'squared_logo',
      },
      {
        fieldName: 'banner_image',
        base64Field: 'base64banner_image',
        imageField: 'banner_image',
      },
      {
        fieldName: 'store_banner',
        base64Field: 'base64store_banner',
        imageField: 'store_banner',
      },
    ]
    // image array [original_base64,cropImage_base64] -> upload google storage -> get url -> set formik value
    await imageFields.reduce(
      async (previousPromise, { fieldName, base64Field, imageField }) => {
        await previousPromise
        if (!formData[imageField] && Array.isArray(formData[base64Field])) {
          const uploadedImage = await uploadImage(formData[base64Field][1])
          formData[fieldName] = uploadedImage
          formik.setFieldValue(fieldName, uploadedImage)
        }
      },
      Promise.resolve()
    )
    if (!formData.id) {
      delete formData.id
    }
    delete formData.base64squared_logo
    delete formData.base64banner_image
    delete formData.base64store_banner
    delete formData.join_discord
    delete formData.contract_state
    delete formData.contracts

    try {
      if (collectionId) {
        const updateCollectionRes = await updateCollection(
          collectionId,
          formData
        )
        return updateCollectionRes
      }
      const createCollectionRes = await setCollection(formData, 'create')
      return createCollectionRes
    } catch (error: any) {
      throw new Error(error)
    }
  }

  const checkFormikError = (): boolean => {
    const values = formik.values
    const error = Object.keys(values).some(
      (key) => validateGroup[step + 1].includes(key) && !values[key]
    )
    return error
  }

  // drafts to backend, but Collection name is required
  const localdrafts = async () => {
    if (!formik.values.name) return
    try {
      openModal('loading')
      const { id } = await createCollection()
      if (id) {
        navigate('/collection')
        onCloseModal()
      }
    } catch (error) {
      openModal('error', String(error))
    }
  }

  const nextHandler = async () => {
    try {
      if (step === 0 && !collectionId) {
        openModal('loading')
        const { id } = await createCollection()
        if (id) {
          setCollectionId(id)
          formik.setFieldValue('id', id)
          onCloseModal()
          setStep(step + 1)
        } else {
          openModal('error', 'Create collection failed.')
        }
      } else if (step === 1 && !checkFormikError()) {
        setStep(step + 1)
      } else {
        setStep(step + 1)
      }
    } catch (error) {
      openModal('error', String(error))
    }
  }

  useEffect(() => {
    if (paramsCollectionId) {
      setCollectionId(paramsCollectionId)
    }
  }, [paramsCollectionId])

  return (
    <form onSubmit={formik.handleSubmit}>
      {step === 0 && (
        <ProjectInfo
          formik={formik}
          type={type}
          setOpenSec1={() => setOpenSec1((prev) => !prev)}
          openSec1={openSec1}
        />
      )}

      {step === 1 ? (
        formik.values?.contracts?.length > 0 ? (
          formik.values.contracts.map((_: any, idx: number) => (
            <Contract
              key={idx}
              formik={formik}
              type={type}
              setOpenSec2={() => {
                setOpenSec2((prev) => {
                  const newOpenSec2 = [...prev]
                  newOpenSec2[idx] = !newOpenSec2[idx]
                  return newOpenSec2
                })
              }}
              openSec2={openSec2}
              tabTitle={`Contract ${idx + 1}`}
              collectionId={collectionId || paramsCollectionId}
              index={idx}
            />
          ))
        ) : (
          <Contract
            formik={formik}
            type={type}
            setOpenSec2={() => {
              setOpenSec2((prev) => [...prev, !prev[0]])
            }}
            openSec2={openSec2}
            tabTitle="Contract 1"
            collectionId={collectionId || paramsCollectionId}
            index={0}
          />
        )
      ) : null}

      {step === 2 && (
        <ListingInfo
          formik={formik}
          type={type}
          setOpenSec3={() => setOpenSec3((prev) => !prev)}
          openSec3={openSec3}
        />
      )}

      {type !== CollectionFormType.readonly && (
        <Flex justify="flex-end" mt="80px">
          <Button
            minWidth="200px"
            onClick={() => localdrafts()}
            mr="24px"
            variant="outline"
            isDisabled={!formik.values.name}
          >
            Local drafts
          </Button>
          {step === 2 ? (
            <Button type="submit" minWidth="200px">
              Submit
            </Button>
          ) : (
            <Button
              onClick={() => nextHandler()}
              minWidth="200px"
              isDisabled={checkFormikError()}
            >
              Next
            </Button>
          )}
        </Flex>
      )}
      <ModalComponent />
    </form>
  )
}

export default CollectionForm
