import { useContext, useEffect, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Flex, Box, Text, Center } from '@chakra-ui/react'
import WalletConnect from './WalletConnect'
import { ReactComponent as BloctoLogo } from 'assets/blocto.svg'
import AuthContext from '../../context/auth'

const HEADER_HEIGHT = 76

const Header = () => {
  const { user } = useContext(AuthContext)
  const navigate = useRef(useNavigate())

  useEffect(() => {
    if (user && !user.loggedIn && window.location.pathname !== '/maintain') {
      navigate.current('/')
    }
  }, [user])

  return (
    <Flex
      justify={{ base: 'flex-start', desktop: 'space-between' }}
      align="center"
      height={HEADER_HEIGHT}
      px={{ base: '40px', desktop: '35px' }}
      pos="fixed"
      bg="transparent"
      top={0}
      left={0}
      right={0}
      zIndex="banner"
      borderBottom="1px solid #f9f9f9"
      _before={{
        pos: 'absolute',
        width: '100%',
        height: '100%',
        content: '""',
        left: 0,
        bg: 'rgba(255, 255, 255, 0.9)',
        backdropFilter: 'blur(20px)',
        zIndex: -1,
      }}
    >
      <Center>
        <Link to="/">
          <Flex
            align="center"
            transition="all .2s"
            _hover={{
              opacity: 0.8,
              transform: 'scale(0.98)',
            }}
          >
            <BloctoLogo height="40px" />
            <Box width="1px" height={25} bg="#D8D8D8" mx="15px" />
            <Text fontWeight="500">Developers</Text>
          </Flex>
        </Link>
      </Center>

      <WalletConnect />
    </Flex>
  )
}
Header.HEIGHT = HEADER_HEIGHT

export default Header
