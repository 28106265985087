import { Center, Box } from '@chakra-ui/react'
import { ReactComponent as NotfoundIcon } from 'assets/notfound.svg'

const NotfoundPage = () => (
  <Center width="100%" height="calc(100vh - 76px)" flexDirection="column">
    <NotfoundIcon />
    <Box color="gray01" mt="25px">
      Sorry...Page Not Found.
    </Box>
  </Center>
)
export default NotfoundPage
