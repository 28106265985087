import { FC, useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { IconButton } from '@chakra-ui/react'
import { Container } from 'components/Sidebar'
import {
  ProjectForm,
  ProjectFormType,
  validationSchema,
  initialFormValue,
} from 'components/ProjectForm'
import AppIdBox from 'components/AppIdBox'
import PageTitle from 'components/PageTitle'
import { useParams } from 'react-router-dom'
import { convertProjectToForm } from 'utils'
import { getProject } from 'api/endpoints/project'
import { ReactComponent as EditIcon } from 'assets/icons/Edit_NoBg.svg'
import SubmitModal from 'components/SubmitModal'

const DraftPage: FC<unknown> = () => {
  const params = useParams()
  const { id } = params
  const urlParams = new URLSearchParams(window.location.search)
  const [editMode, setEditMode] = useState(urlParams.get('type') === 'edit')
  const [openSubmitModal, setOpenSubmitModal] = useState(false)

  const formik = useFormik({
    initialValues: initialFormValue as FormDataProps,
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      setOpenSubmitModal(true)
    },
  })

  useEffect(() => {
    const fetchProjectData = async () => {
      if (id) {
        const projectData = await getProject(id)
        const formValues = await convertProjectToForm(projectData)
        formik.setValues(formValues)
      }
    }
    fetchProjectData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <Container>
      <PageTitle
        title={formik.values.name}
        subtitle="You have to register to integrate with Blocto solutions."
        buttons={
          !editMode && (
            <IconButton
              variant="icon"
              size="sm"
              icon={<EditIcon />}
              aria-label="editMode"
              onClick={() => {
                setEditMode(true)
              }}
            />
          )
        }
      />
      {!editMode && <AppIdBox id={id} />}
      <ProjectForm
        formik={formik}
        type={editMode ? ProjectFormType.draft : ProjectFormType.readonly}
        uuid={id}
      />

      <SubmitModal
        isOpen={openSubmitModal}
        onClose={() => {
          setOpenSubmitModal(false)
        }}
        formik={formik}
      />
    </Container>
  )
}

export default DraftPage
