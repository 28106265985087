import { FC } from 'react'
import {
  Center,
  Box,
  Flex,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'
import { ReactComponent as EditIcon } from 'assets/icons/Edit_NoBg.svg'
import { ReactComponent as DetailIcon } from 'assets/icons/Detail.svg'
import { ReactComponent as DeleteIcon } from 'assets/icons/Delete_NoBg.svg'
import DefaultIcon from 'assets/default_icon.jpg'

interface ProjectItemProps {
  logo: string
  name: string
  type: string
  appId: string
  isDraft?: boolean
  onDeleteDraft?: () => void
}

const ProjectItem: FC<ProjectItemProps> = ({
  logo,
  name,
  type,
  appId,
  isDraft,
  onDeleteDraft,
}) => (
  <Flex
    mb="20px"
    borderRadius="12px"
    backgroundColor="white"
    height="110px"
    justify="space-between"
  >
    <Link
      as={RouterLink}
      to={isDraft ? `/draft/${appId}` : `/dapp/${appId}`}
      cursor="pointer"
      _hover={{ textDecoration: 'none' }}
      display="block"
      flex="1"
    >
      <Flex>
        <Center boxSize="110px">
          <Image
            src={logo}
            boxSize="100%"
            borderRadius="8px"
            objectFit="cover"
            fallbackSrc={DefaultIcon}
          />
        </Center>

        <Flex ml="20px" flexDirection="column" justifyContent="center">
          <Box fontSize="16px" fontWeight="600">
            {name}
          </Box>
          <Box fontSize="14px" color="gray01">
            {type}
          </Box>
        </Flex>
      </Flex>
    </Link>

    <Flex alignItems="center" mr="34px">
      <Box
        px="20px"
        py="3px"
        fontWeight="600"
        borderRadius="100px"
        color={isDraft ? 'BloctoBlue.Light02' : 'white'}
        backgroundColor={isDraft ? 'BloctoBlue.Light06' : 'BloctoBlue.Light02'}
      >
        {isDraft ? 'Local drafts' : 'Active'}
      </Box>

      <Menu placement="bottom-end" offset={[0, 15]} autoSelect={false}>
        <MenuButton ml="20px">
          <DetailIcon />
        </MenuButton>
        <MenuList>
          <Link
            as={RouterLink}
            to={
              isDraft ? `/draft/${appId}?type=edit` : `/dapp/${appId}?type=edit`
            }
            _hover={{ textDecoration: 'none' }}
          >
            <MenuItem>
              <Box mr="12px">
                <EditIcon />
              </Box>
              Edit
            </MenuItem>
          </Link>

          {isDraft && (
            <MenuItem onClick={onDeleteDraft}>
              <Box mr="12px">
                <DeleteIcon />
              </Box>
              Delete
            </MenuItem>
          )}
        </MenuList>
      </Menu>
    </Flex>
  </Flex>
)

export default ProjectItem
