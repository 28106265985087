import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import * as fcl from '@blocto/fcl'
import reportWebVitals from './reportWebVitals'

const isMainnet = process.env.REACT_APP_NETWORK === 'mainnet'
const WALLET_DOMAIN = `https://wallet-v2${isMainnet ? '' : '-dev'}.blocto.app`
const WALLET_URL = `${WALLET_DOMAIN}/${process.env.REACT_APP_DAPP_ID}/flow/authn`
const NODE_URL = `https://rest-${isMainnet ? 'mainnet' : 'testnet'}.onflow.org`

fcl
  .config()
  .put('accessNode.api', NODE_URL)
  .put('discovery.wallet', WALLET_URL)
  .put('flow.network', process.env.REACT_APP_NETWORK || 'testnet')

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
