import { FC } from 'react'
import {
  Box,
  Flex,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'
import { ReactComponent as DetailIcon } from 'assets/icons/Detail.svg'
import { ReactComponent as EditIcon } from 'assets/icons/Edit_NoBg.svg'
import { ReactComponent as DeleteIcon } from 'assets/icons/Delete_NoBg.svg'
import DefaultIcon from 'assets/default_icon.jpg'

interface CollectionItemProps {
  logo: string
  name: string
  type: string
  appId: string
  isDraft?: boolean
  onDeleteDraft?: () => void
}

const CollectionItem: FC<CollectionItemProps> = ({
  logo,
  name,
  type,
  appId,
  isDraft,
  onDeleteDraft,
}) => (
  <Flex
    mb="20px"
    w="240px"
    borderRadius="12px"
    backgroundColor="white"
    justify="space-between"
    flexWrap="wrap"
  >
    <Link
      as={RouterLink}
      to={`/collection/draft/${appId}`}
      cursor="pointer"
      _hover={{ textDecoration: 'none' }}
      display="block"
      flex="1"
      boxSize="240px"
      w="100%"
    >
      <Flex w="100%" boxSize="240px" position="relative">
        <Image
          src={logo}
          boxSize="100%"
          borderRadius="8px"
          objectFit="cover"
          fallbackSrc={DefaultIcon}
        />
        <Box
          position="absolute"
          px="20px"
          py="6px"
          top="16px"
          left="16px"
          fontWeight="600"
          fontSize="14px"
          borderRadius="20px"
          color="font.inverse"
          backgroundColor="background.badge"
        >
          {type === 'draft'
            ? 'Local drafts'
            : type === 'listing'
            ? 'Listing'
            : 'Processing'}
        </Box>
      </Flex>
    </Link>

    <Box p="16px" w="100%">
      <Flex flexDirection="column" justifyContent="center" mb="16px">
        <Box fontSize="16px" fontWeight="500" color="font.primary">
          {name}
        </Box>
      </Flex>

      <Flex w="100%" alignItems="center" justifyContent="flex-end">
        <Menu placement="bottom-end" offset={[0, 15]} autoSelect={false}>
          <MenuButton>
            <Box transform="rotate(90deg)">
              <DetailIcon />
            </Box>
          </MenuButton>
          <MenuList>
            <Link
              as={RouterLink}
              to={`/collection/draft/${appId}?type=edit`}
              _hover={{ textDecoration: 'none' }}
            >
              <MenuItem>
                <Box mr="12px">
                  <EditIcon />
                </Box>
                Edit
              </MenuItem>
            </Link>

            {isDraft && (
              <MenuItem onClick={onDeleteDraft}>
                <Box mr="12px">
                  <DeleteIcon />
                </Box>
                Delete
              </MenuItem>
            )}
          </MenuList>
        </Menu>
      </Flex>
    </Box>
  </Flex>
)

export default CollectionItem
