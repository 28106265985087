import { FC, useState, useRef } from 'react'
import {
  Box,
  Image,
  Button,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
} from '@chakra-ui/react'
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { PreviewFile, FileType } from './index'
import { getBase64 } from 'utils'
import { useDebounceEffect } from 'hooks/useDebounceEffect'
import ImageIcon from 'assets/icons/Image_icon.svg'

interface CropModalProps {
  isOpen: boolean
  onClose: () => void
  imgSrc: any
  circularCrop: boolean
  size?: string
  files: PreviewFile[]
  setFiles: (files: PreviewFile[]) => void
  clearFile: () => void
  onFileAccepted: (acceptedFile: string[]) => void
  fileType: FileType
  setFileType: (type: FileType) => void
}

const CropModal: FC<CropModalProps> = ({
  isOpen,
  onClose,
  imgSrc,
  circularCrop,
  size,
  files,
  setFiles,
  clearFile,
  onFileAccepted,
  fileType,
  setFileType,
}) => {
  const imgRef = useRef<HTMLImageElement>(null)
  const [crop, setCrop] = useState<Crop>()
  const [originalWidth, originalHeight] = size?.split('*').map(Number) || [
    512, 512,
  ]
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
  const [corpImage, setCorpImage] = useState<string>('')
  const [scale, setScale] = useState<number>(1)
  function centerAspectCrop(
    mediaWidth: number,
    mediaHeight: number,
    aspect: number
  ) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: 'px',
          width: originalWidth,
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    )
  }

  const getCroppedBase64Img = (
    image: HTMLImageElement,
    crop: PixelCrop
  ): Promise<string> => {
    const canvas = document.createElement('canvas')
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    const cropX = crop.x * scaleX
    const cropY = crop.y * scaleY
    const pixelRatio = window.devicePixelRatio
    canvas.width = crop.width * scaleX
    canvas.height = crop.height * scaleY
    canvas.width = Math.floor(crop.width * scaleX * pixelRatio)
    canvas.height = Math.floor(crop.height * scaleY * pixelRatio)

    const centerX = image.naturalWidth / 2
    const centerY = image.naturalHeight / 2

    const ctx = canvas.getContext('2d')

    if (ctx) {
      ctx.scale(pixelRatio, pixelRatio)
      ctx.imageSmoothingQuality = 'high'
      ctx.save()

      // 5) Move the crop origin to the canvas origin (0,0)
      ctx.translate(-cropX, -cropY)
      // 4) Move the origin to the center of the original position
      ctx.translate(centerX, centerY)
      // // 3) Rotate around the origin
      // ctx.rotate(rotateRads)
      // 2) Scale the image
      ctx.scale(scale, scale)
      // 1) Move the center of the image to the origin (0,0)
      ctx.translate(-centerX, -centerY)
      ctx.drawImage(
        image,
        0,
        0,
        image.naturalWidth,
        image.naturalHeight,
        0,
        0,
        image.naturalWidth,
        image.naturalHeight
      )

      ctx.restore()
    }
    return new Promise((resolve, reject) => {
      if (ctx) {
        canvas.toBlob(async (blob: any) => {
          const base64Url = await getBase64(blob)
          resolve(base64Url)
        }, 'image/png')
      } else {
        reject(new Error('Canvas context is null'))
      }
    })
  }

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const { width, height } = e.currentTarget
    setCrop(centerAspectCrop(width, height, originalWidth / originalHeight))
  }

  useDebounceEffect(
    async () => {
      if (completedCrop?.width && completedCrop?.height && imgRef.current) {
        const base64 = await getCroppedBase64Img(imgRef.current, completedCrop)
        setCorpImage(base64)
      }
    },
    100,
    [completedCrop, crop, scale]
  )

  const cropHandler = async () => {
    const fileToBase64 = await getBase64(files[0])
    onFileAccepted([fileToBase64, corpImage])
    setFileType(FileType.CROPPED)
    onClose()
  }

  const onCloseHandler = () => {
    if (fileType === FileType.CREATE) {
      setFiles([])
      clearFile()
      onClose()
    } else {
      onClose()
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onCloseHandler} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          p="32px 32px 24px 32px"
          bg="background.tertiary"
          borderRadius="12px"
        >
          Edit cover image
        </ModalHeader>
        <ModalCloseButton top="32px" right="32px" />
        <ModalBody px="32px" bg="background.tertiary">
          <Flex justifyContent="center" bg="background.badge">
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(c) => setCompletedCrop(c)}
              circularCrop={circularCrop}
              aspect={originalWidth / originalHeight}
            >
              <Image
                ref={imgRef}
                alt="Crop me"
                src={imgSrc}
                onLoad={onImageLoad}
                transform={`scale(${scale})`}
              />
            </ReactCrop>
          </Flex>
          <Flex p="10px 16px" bg="background.primary">
            <Box w="18px">
              <Image src={ImageIcon} />
            </Box>
            <Box p="0 15px" minW="93%">
              <Slider
                value={scale}
                onChange={(value) => setScale(value)}
                min={0.1}
                max={2}
                step={0.1}
              >
                <SliderTrack bg="border.tertiary">
                  <SliderFilledTrack bg="border.primary" />
                </SliderTrack>
                <SliderThumb />
              </Slider>
            </Box>
            <Box w="18px">
              <Image src={ImageIcon} />
            </Box>
          </Flex>
        </ModalBody>

        <ModalFooter
          bg="background.tertiary"
          borderRadius="12px"
          px="32px"
          pt="24px"
          pb="32px"
        >
          <Button
            colorScheme="blue"
            mr={3}
            onClick={onCloseHandler}
            variant="outline"
          >
            Cancel
          </Button>
          <Button onClick={cropHandler}>Crop</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default CropModal
